import Vue from 'vue';
import Component from 'vue-class-component';
import { AxiosError } from 'axios';
import apiClient from '@/apiClient';
import UserCredentials from '@/Interfaces/UserCredentials';
import { namespace } from 'vuex-class';
import FormRules from '@/partials/FormRules';
import { RawLocation } from 'vue-router';
import PasswordField from '@/components/PasswordField/PasswordField.vue';

@Component({
    components: { PasswordField },
})
export default class LoginForm extends Vue {
    public valid = false as boolean;
    public credentials = { email: '', password: '' } as UserCredentials;
    public forgotPassword = false as boolean;
    private loading = false as boolean;
    private wrongCredentials = false as boolean;
    private sendQRCode: boolean = false;
    public formRules = FormRules;
    public showMFAField = false;
    public showQRCode = false;
    public showInvalidMFACode = false;
    public QRCodeBase64 = '';
    public readonly resetLink = `/forgot-password/${this.credentials.email}`;

    $refs !: {
        mfa_component: any;
    }

    @namespace('authentication').Action passLoginCheck: any;
    @namespace('me').Action fetch: any;

    private resetAndFocusOTPElement(): void {
        setTimeout(() => {
            Vue.set(this.credentials, 'mfa_code', '');
            this.$refs.mfa_component.$refs.input[0].focus();
        }, 100);
    }

    private sendQrCodeToUser(): void {
        apiClient.post('/mfa/mail-qr', this.credentials).then((response) => {
            this.sendQRCode = true;
        });
    }

    submit(): void {
        this.loading = true;

        apiClient.post('/login', this.credentials).then((response) => {
            this.passLoginCheck();
            this.$router.push((this.$route.query.target ?? '/') as RawLocation);
        }).catch((response: AxiosError) => {
            if ( response.response?.status === 401 ) {
                if ( response.response.data.mfa_required && !!response.response.data.qr_code ) {
                    this.showMFAField = true;
                    this.showQRCode = true;
                    this.QRCodeBase64 = response.response.data.qr_code;
                    this.resetAndFocusOTPElement();
                } else if ( response.response.data.mfa_required ) {
                    this.showMFAField = true;
                    this.resetAndFocusOTPElement();
                } else {
                    this.wrongCredentials = true;
                }
            }
            else if ( response.response?.data.errors) {
                this.wrongCredentials = true;
            }
            else if ( response.response?.status === 403) {
                this.showInvalidMFACode = true;
                this.resetAndFocusOTPElement();
            }
        }).finally(() => {
            this.loading = false;
        });
    }
}
