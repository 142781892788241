import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import TreatmentReportPopup from '@/components/TreatmentReport/Components/Popup/TreatmentReportPopup';

@Component
export default class Appointments extends Vue {
    @Prop({ default: () => [] }) public appointments!: Array<any>;
    @Prop() public header!: string;
    @Prop({ default: () => [] }) public headers!: Array<any>;
    @Prop({ default: false }) public loading!: boolean;
    @Prop() public icon!: string;
    @Prop() public treatmentReportPopup!: TreatmentReportPopup;

    public items = this.appointments;

    public dateFormat: object = { weekday: 'short', day: 'numeric', month: 'long', year: 'numeric' };
    public timeFormat: string = 'HH:mm';

    @Watch('appointments')
    private onAppointmentsChange(): void {
        this.items = this.appointments;
    }

    public getDate(datetime: string): string {
        const date = new Date(datetime).toLocaleString('nl-NL', {
            weekday: 'short',
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        });

        return [ date.slice(0, 1).toUpperCase(), date.slice(1) ].join('');
    }

    public getTime(datetimeFrom: string, datetimeTill: string): string {
        return [
            this.$dayjs(datetimeFrom).format('HH:mm'),
            this.$dayjs(datetimeTill).format('HH:mm'),
        ].join('-');
    }
}
