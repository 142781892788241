import Component from 'vue-class-component';
import OverviewTable from '@/components/OverviewTable/OverviewTable.vue';
import IndexView from '@/partials/IndexView';
import CountryForm from '@/views/DatabaseModels/Components/Countries/Form/CountryForm.vue';

@Component({
    components: {
        OverviewTable,
        CountryForm,
    },
})
export default class CountryIndex extends IndexView<any> {
    protected endpoint = 'countries/dto';
}