import Component from 'vue-class-component';
import Vue from 'vue';
import dayjs from 'dayjs';
import { Prop } from 'vue-property-decorator';

@Component({
    name: 'PaymentDatePicker',
})
export default class EditableDatePicker extends Vue {
    private date: string = dayjs(Date.now()).format('YYYY-MM-DD');
    private menu: boolean = false;

    @Prop({ default: ''}) public maxDate!: string;
    @Prop({ default: ''}) public minDate!: string;

    // Public methods
    public get currentDayNotation(): string {
        return dayjs(this.date).format('DD-MM-YYYY');
    }

    public updateValue(newValue: any): void {
        this.$emit('input', newValue);
    }

    mounted() {
        this.$emit('input', this.date);
    }

}
