import Vue, { VNode } from 'vue';
import { DirectiveBinding } from 'vue/types/options';
import Popup from '../components/popup/popup';

// TODO: Replace directive with different solution, this is bugged when eventlistener is added multiple times
Vue.directive('open-popup', {
	bind(el: HTMLElement, binding: DirectiveBinding, vnode: VNode) {
		el.addEventListener('click', () => {
			const component = vnode.context?.$refs[binding.value] as Popup;
			component.toggle();
		});
	}
});
