import AppointmentForm from '@/views/CalendarIndex/Forms/AppointmentForm.vue';
import AppointmentRaw from '@/views/AppointmentIndex/Forms/AppointmentForm.vue';
import Calendar from '@/views/CalendarIndex/Components/Calendar/Calendar.vue';
import ColorLegend from '@/views/CalendarIndex/Components/ColorLegend/ColorLegend.vue';
import Component from 'vue-class-component';
import LocationPicker from '@/components/LocationPicker/LocationPicker.vue';
import MenuButton from '@/components/MenuButton/MenuButton.vue';
import dayjs from 'dayjs';
import i18n from '@/i18n';
import CalendarPlanning from '@/views/CalendarIndex/Components/CalendarPlanning/CalendarPlanning.vue';
import LocationCalendarParent from '@/views/CalendarIndex/Components/LocationCalendarParent';

@Component({
    name: 'CalendarDayView',
    components: {
        AppointmentForm,
        AppointmentRaw,
        LocationPicker,
        Calendar,
        ColorLegend,
        MenuButton,
        CalendarPlanning,
    },
})

export default class CalendarDayView extends LocationCalendarParent {
    // Public variables
    public weekdays = [] as Array<any>;

    // Getters & Setters
    get weekNumber(): number {
        return dayjs().add(this.weekTravel, 'week').isoWeek();
    }

    // Public methods
    public get currentDayNotation(): string {
        return this.date.toDate().toLocaleDateString(i18n.locale, {
            weekday: 'long',
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        });
    }

    public getCalendarType(): string {
        const calendarType = this.$route.meta?.type;

        return calendarType == 'location' ? 'location' : 'user';
    }

    public changeCalendarView(calendarView: 'day' | 'week' | 'switch'): void {
        this.$emit('setCalendarView', calendarView);

        this.$emit('fetch');
    }

    public onChange(amount: number): void {
        this.$emit('change', amount);
    }

    public onRaw(event: any): void {
        this.$emit('raw', event);
    }

    public onRequest(date: any): void {
        this.$emit('request', date);
    }
}
