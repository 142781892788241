import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import User from '@/Interfaces/User';
import Autocomplete from '@/components/Autocompletes/Autocomplete';

@Component({
    name: 'LocationPractitionersAutocomplete',
})
export default class LocationPractitionersAutocomplete extends Autocomplete<User> {
    @Prop({ default: 0 }) locationId!: number;
    @Prop({ default: 0 }) practitionerId!: number;

    get endpoint(): string {
        return `list/location/${this.locationId}/practitioners`;
    }

    @Watch('locationId')
    onLocationIdChange() {
        this.fetch();
    }

    @Watch('practitionerId')
    onPractitionerIdChange() {
        this.$emit('onPractitionerIdChange', this.practitionerId);
    }
}
