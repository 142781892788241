import Vue from 'vue';
import Component from 'vue-class-component';
import Popup from '@/components/popup/popup.vue';
import { Prop, Ref } from 'vue-property-decorator';
import { default as PopupComponent } from '@/components/popup/popup';
import { namespace } from 'vuex-class';
import { ReportHistoryState } from '@/store/modules/reporthistory';
import apiClient from '@/apiClient';

@Component({
    name: 'TreatmentReportPhotoComparisonSendPopup',
    components: {
        Popup,
    },
})
export default class TreatmentReportPhotoComparisonSendPopup extends Vue {
    @Ref() treatmentSendPhotosPopup!: PopupComponent;
    @Prop() isSendPopupOpen!: boolean;

    @namespace('reporthistory').Getter('state') public reportHistoryState!: ReportHistoryState;
    @namespace('reporthistory').Mutation('setLeftCompareImage') public setLeftCompareImage!: any;
    @namespace('reporthistory').Mutation('setRightCompareImage') public setRightCompareImage!: any;

    private loading: boolean = false;

    public closePhotoSendPopup(): void {
        this.$emit('emitToggleSendPhotoPopup');
    }

    public sendCompareEmail(): void {
        this.loading = true;

        const compareImage = {
            'leftImage': this.reportHistoryState.compareImageLeft,
            'rightImage': this.reportHistoryState.compareImageRight,
            'appointment': this.reportHistoryState.comparingClientAppointment,
        };

        apiClient.post('/treatment-report/photos/send-compare-photo', compareImage).then(() => {
            this.$store.dispatch('application/showNotification', [ 'PhotoCompareSendSuccess', { email: this.reportHistoryState.comparingClientEmail } ]);
        }).finally(() => {
            this.treatmentSendPhotosPopup.close();

            this.closePhotoSendPopup();
            this.setLeftCompareImage({ data: null });
            this.setRightCompareImage({ data: null });
            this.loading = false;
        });
    }
}
