import Component from 'vue-class-component';
import OverviewTable from '@/components/OverviewTable/OverviewTable.vue';
import LocationForm from '@/views/LocationIndex/Forms/LocationForm.vue';
import IndexView from '@/partials/IndexView';
import Location from '@/Interfaces/Location';

@Component({
	components: {
		OverviewTable,
		LocationForm
	}
})
export default class LocationIndex extends IndexView<Location> {
	protected endpoint = 'locations';

}
