import Component from 'vue-class-component';
import OverviewTable from '@/components/OverviewTable/OverviewTable.vue';
import IndexView from '@/partials/IndexView';
import SupportedCountryForm from '@/views/DatabaseModels/Components/SupportedCountries/Form/SupportedCountryForm.vue';

@Component({
    components: {
        OverviewTable,
        SupportedCountryForm,
    },
})
export default class SupportedCountryIndex extends IndexView<any> {
    protected endpoint = 'supported-countries/dto';
}