import popup from '@/components/popup/popup.vue';
import PopupForm from '@/partials/PopupForm';
import Component from 'vue-class-component';
import ProductFormData from '@/Interfaces/ProductFormData';
import PriceField from '@/components/PriceField/PriceField.vue';

@Component({
    components: {
        popup,
        PriceField,
    },
})

export default class CountryForm extends PopupForm<ProductFormData> {
    protected modelName = 'Country';
    protected endpoint = 'countries';
}
