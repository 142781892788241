import Component from 'vue-class-component';
import OverviewTable from '@/components/OverviewTable/OverviewTable.vue';
import {TranslationMap} from '@/Interfaces/TranslationMap';
import IndexView from '@/partials/IndexView';
import TransactionDaySummary from '@/Interfaces/TransactionDaySummary';
import {Watch} from 'vue-property-decorator';
import Location from '@/Interfaces/Location';
import apiClient from '@/apiClient';
import dayjs from 'dayjs';
import i18n from '@/i18n';
import LocationAutocomplete from '@/components/Autocompletes/LocationAutocomplete/LocationAutocomplete.vue';
import MenuButton from '@/components/MenuButton/MenuButton.vue';
import TableTotals from '@/components/TableTotals/TableTotals.vue';
import {Total} from '@/components/OverviewTable/OverviewTable';
import {namespace} from 'vuex-class';
import {BadgesAmountData} from '@/Interfaces/BadgeAmountData';

@Component({
    components: {
        OverviewTable,
        TableTotals,
        LocationAutocomplete,
        MenuButton,
    },
})
export default class DaySummaryIndex extends IndexView<TransactionDaySummary> {
    @namespace('mru').State('daySummaryLocationId') mruDaySummaryLocation!: any;
    @namespace('mru').Mutation('set') setMru!: any;
    @namespace('me').State permissions: any;

    protected endpoint = '';
    public selectedLocationId = null as null | number;
    public locations = [] as Array<Location>;
    public day = dayjs().toISOString().substring(0, 10);
    public filteredTotalData: Array<Total> = [];

    public translationMap = [
        {
            key: 'product_types',
            path: 'Api.DaySummary.ProductTypes',
            multiple: true,
        },
    ] as Array<TranslationMap>;

    @Watch('selectedLocationId')
    onSelectedLocationIdChanged() {
        this.setDay(this.day.substring(0, 10));
        this.setMru({key: 'daySummaryLocationId', value: this.selectedLocationId});
    }

    mounted(): void {
        if (this.mruDaySummaryLocation) {
            this.selectedLocationId = this.mruDaySummaryLocation;
        }

        this.setDay(this.day);
    }

    filters(data: BadgesAmountData) {

        const reducer = (key: string): string => {
            const input = data as any;
            return input.reduce((accumulator: number, data: any) => (accumulator + data[key] || 0), 0)
                .toString()
        };


        this.filteredTotalData = [
            {
                key: 'amount_pin',
                type: 'currency',
                amount: reducer('amount_pin')
            },
            {
                key: 'amount_cash',
                type: 'currency',
                amount: reducer('amount_cash')
            },
            {
                key: 'amount_voucher',
                type: 'currency',
                amount: reducer('amount_voucher')
            },
            {
                key: 'amount_credit',
                type: 'currency',
                amount: reducer('amount_credit')
            },
            {
                key: 'amount_prepaid',
                type: 'currency',
                amount: reducer('amount_prepaid')
            },
            {
                key: 'amount_spread',
                type: 'currency',
                amount: reducer('amount_spread')
            },
            {
                key: 'amount_total',
                type: 'currency',
                amount: reducer('amount_total')
            },
            {
                key: 'intakes',
                type: 'number',
                amount: reducer('intake')
            },
            {
                key: 'intakes_with_treatment',
                type: 'number',
                amount: reducer('intake_with_treatment')
            },
        ];
    }

    setDay(day: string): void {
        this.day = day;
        this.endpoint = `day-summary?filters[day]=${day}`;
        if (this.selectedLocationId) this.endpoint = this.endpoint + `&location=${this.selectedLocationId}`;
        this.$refs.overviewTable.refresh();

        apiClient.get('/locations/owners').then((response) => this.locations = response.data);
    }

    getDayReference(): string {

        return new Date(this.day).toLocaleDateString(i18n.locale, {
            weekday: 'short',
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        });
    }

    afterDataTableFetch(data: Record<string, any>) {
        this.filteredTotalData = data.totals;
    }

    travelDay(amount: number): void {
        this.setDay(dayjs(new Date(this.day)).add(amount, 'day').format('YYYY-MM-DD'));
    }

    resetToToday(): void {
        this.setDay(dayjs().format('YYYY-MM-DD'));
    }

    get calendarDate() {
        const newDate = dayjs.utc(this.day);

        return newDate.toISOString().substring(0, 10);
    }

    set calendarDate(date: string) {
        this.day = dayjs.utc(date).toISOString();
        this.setDay(this.day.substring(0, 10));
    }
}
