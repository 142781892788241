import popup from '@/components/popup/popup.vue';
import PopupForm from '@/partials/PopupForm';
import Component from 'vue-class-component';
import DeactivateToggle from '@/components/DeactivateToggle/DeactivateToggle.vue';
import TreatmentPicker from '@/components/TreatmentPicker/TreatmentPicker.vue';
import PriceField from '@/components/PriceField/PriceField.vue';
import Model from '@/Interfaces/Model';
import SupportedCountries from '@/components/Autocompletes/SupportedCountries/SupportedCountries.vue';
import PriceLevelPriceInput from "@/components/PriceLevelPicker/PriceLevelPriceInput.vue";

declare interface PackageFormData extends Model {
    treatment_id: number;
    price: number;
    terms: number;
    treatments: number;
    term: boolean;
}

declare interface Record {
    id: number;
    name: string;
}

@Component({
    components: {PriceLevelPriceInput, popup, DeactivateToggle, TreatmentPicker, PriceField, SupportedCountries },
})
export default class PackageForm extends PopupForm<PackageFormData> {
    protected modelName = 'Package';
    protected endpoint = 'packages';
    private dataLoading = {
        treatments: false,
    };

    afterToggle(): void {
        if ( !this.formData.id ) {
            Object.assign(this.formData, {
                term: false,
                treatments: 6,
                terms: 1,
            });
        }
    }

    get packageType(): string {
        return this.formData.term ? 'term' : 'package';
    }

    set packageType(type: string) {
        this.formData.term = (type === 'term');
        this.formData.terms = this.formData.term ? 4 : 1;

        this.$forceUpdate();
    }
}
