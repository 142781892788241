import Component from 'vue-class-component';
import LocationForm from '@/views/LocationIndex/Forms/LocationForm.vue';
import Vue from 'vue';
import { findEachWordInOneOf } from '@/utils/utils';
import apiClient from '@/apiClient';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import axios from 'axios';
import Location from '@/Interfaces/Location';

@Component({
    components: { LocationForm },
})

export default class LocationPicker extends Vue {
    @Prop({ default: null }) public googlePlace!: any;
    @Prop({ default: null }) public filterByCountry!: boolean;
    @Prop({ default: () => [] }) public models!: Array<number>;

    @Ref() public autocomplete: any;

    private numberFormatter: any = new Intl.NumberFormat('nl-NL', { maximumFractionDigits: 1 });

    public findEachWordInOneOf = findEachWordInOneOf;
    public loading: boolean = false;

    public locations: Array<any> = [];

    private requestCancelToken: any;

    @Watch('googlePlace')
    private onGooglePlaceChange(): void { this.sort(); }

    @Watch('models')
    private onModelsChange(): void { this.fetch(); }

    public mounted(): void {
        this.fetch();
    }

    public getSubtitle(location: any): string {
        const distance = location.distance ? `(${this.numberFormatter.format(location.distance)}km)` : null;
        return [ location.place, distance ].filter(v => !!v).join(' ');
    }

    public get availableLocations(): Array<Location>{
        const countryId = this.$store.state.mru.supportedCountries.filter((country: Location) => country.id === this.$store.state.mru.primarySupportedCountryId)[0]?.country_id;

        return this.filterByCountry ? this.locations.filter(location => location.country_id === countryId) : this.locations;
    }

    private fetch(): void {
        try {
            this.requestCancelToken.cancel();
        } catch ( e ) {
            // dont do anything
        }

        this.requestCancelToken = axios.CancelToken.source();
        const params = { models: this.models };

        apiClient.get('list/location/create-event', { cancelToken: this.requestCancelToken.token, params })
            .then(response => {
                this.locations = response.data;

                this.sort();
                this.loading = false;
            }).catch(error => {
            if ( !axios.isCancel(error) ) this.loading = false;
        });

        this.loading = true;
    }

    private sort(): void {
        !this.googlePlace?.geometry ? this.setSortId() : this.setSortDistance(this.googlePlace);
    }

    private getDistance(latitudeA: number, longitudeA: number, latitudeB: number, longitudeB: number): number {
        const radial = (input: number) => input * Math.PI / 180;

        const dLatitude = radial(latitudeB - latitudeA);
        const dLongitude = radial(longitudeB - longitudeA);

        const rLatitudeA = radial(latitudeA);
        const rLatitudeB = radial(latitudeB);

        const a = Math.sin(dLatitude / 2) * Math.sin(dLatitude / 2) +
            Math.cos(rLatitudeA) * Math.cos(rLatitudeB) *
            Math.sin(dLongitude / 2) * Math.sin(dLongitude / 2);

        return 6371 * 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    }

    private setSortDistance(googlePlace: any): void {
        const latitude = googlePlace.geometry.location.lat();
        const longitude = googlePlace.geometry.location.lng();

        this.locations.forEach((location: any) => {
            const distance = this.getDistance(latitude, longitude, location.latitude, location.longitude);
            Object.assign(location, { distance });
        });

        this.locations.sort((locationA: any, locationB: any) => {
            return locationA.distance - locationB.distance;
        });

        this.autocomplete.setValue(this.locations[ 0 ].id);
    }

    private setSortId(): void {
        this.locations.forEach((location: any) => {
            Object.assign(location, { distance: null });
        });

        this.locations.sort((locationA: any, locationB: any) => {
            return locationA.id - locationB.id;
        });
    }
}
