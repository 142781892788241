import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import TreatmentReportInterface from '@/Interfaces/TreatmentReportInterface';
import i18n from '@/i18n';
import StatusIcon from '@/components/StatusIcon/StatusIcon.vue';
import TreatmentReportTreatment from '@/components/TreatmentReport/Components/TreatmentReportTreatment/TreatmentReportTreatment.vue';
import Wysiwyg from '@/components/Wysiwyg/Wysiwyg.vue';

@Component({
    name: 'TreatmentReportBody',
    components: {
        StatusIcon,
        TreatmentReportTreatment,
        Wysiwyg,
    },
})
export default class TreatmentReportBody extends Vue {
    @Prop() data!: TreatmentReportInterface;

    get treatmentDate(): string {
        return new Date(this.data.appointment.datetime_from).toLocaleDateString(i18n.locale, {
            weekday: 'short',
            day: '2-digit',
            month: 'long',
            year: 'numeric',
        });
    }

    get treatmentTimeRange(): string {
        const dateStart = new Date(this.data.appointment.datetime_from);
        const dateEnd = new Date(this.data.appointment.datetime_till);

        return [
            dateStart.toLocaleTimeString(i18n.locale, { hour: '2-digit', minute: '2-digit' }),
            dateEnd.toLocaleTimeString(i18n.locale, { hour: '2-digit', minute: '2-digit' }),
        ].join(' - ');
    }
}
