import Vue from 'vue';
import Component from 'vue-class-component';
import FormRules from '@/partials/FormRules';
import { Prop, Watch } from 'vue-property-decorator';

declare interface Room {
    id?: number;
    name: string;
}

@Component
export default class Rooms extends Vue {
    @Prop({ default: () => [ { name: '' } ] }) value!: Array<Room>;

    public formRules = FormRules;

    @Watch('rooms', { immediate: true })
    onRoomsChange(): void {
        this.$emit('input', this.value);
    }

    addRoom() {
        this.value.push({ name: '' });
    }

    deleteRoom(room: Room) {
        const index = this.value.findIndex(i => i === room);
        if ( index > 0 ) this.value.splice(index, 1);
    }
}
