import Vue from 'vue';
import Component from 'vue-class-component';
import apiClient from '@/apiClient';
import axios, {AxiosError, AxiosResponse} from 'axios';
import {DataTableHeader} from 'vuetify';
import TableActions from '@/components/TableActions/TableActions.vue';
import TableFilters from '@/components/TableFilters/TableFilters.vue';
import TableTotals from '@/components/TableTotals/TableTotals.vue';
import {Prop, Watch} from 'vue-property-decorator';
import {TranslationMap} from '@/Interfaces/TranslationMap';
import presenters from '@/components/OverviewTable/presenters';
import Model from '@/Interfaces/Model';

Vue.config.ignoredElements.push('vue-overview-table');

@Component({
    components: {
        TableActions,
        TableFilters,
        TableTotals,
    },
})
export default class OverviewTable extends Vue {
    public presenters = presenters;

    public unfilteredData: OverviewBodies = [];
    public filteredData: OverviewBodies = [];
    public tableHeader: OverviewHeaders = [];
    public tableMeta: OverviewMeta = {total: 0};
    public totals: Array<Total> = [];
    public filteredTotals: Array<Total> = [];

    public loading: boolean = true;
    public options: Record<string, any> = {};
    public search: string = '';
    public showFilters: boolean = false;
    public filterAmount: number = 0;

    @Prop({type: Boolean, default: false}) dontCancelRequests!: boolean;
    @Prop() endpoint!: string | null;
    @Prop() titleText!: string;
    @Prop() translationPath!: string;
    @Prop({default: () => []}) translationMap!: Array<TranslationMap>;
    @Prop() sortField!: string;
    @Prop({default: (items: any) => items}) customSort!: Function;
    @Prop({default: true}) showSearch!: boolean;
    @Prop() lazyFilters!: any;
    @Prop({default: () => ''}) itemClass!: any;

    @Prop({type: Boolean, default: false}) useActions!: boolean;

    public footerProps = {
        'items-per-page-options': [20, 40, 100, -1],
    };

    private getVatTranslatableName(item: any): string|void {
        if (item.taxRate == null) return;
        const index = item.taxRate.lastIndexOf('.');

        let string = item.taxRate;

        if (index != -1) {
            string = item.taxRate.substring(0, index) + "_" + item.taxRate.substring(index + 1)
        }

        return string;
    }

    /**
     * Body getter
     */
    get body(): Array<object> {
        const filteredData = JSON.parse(JSON.stringify(this.filteredData));
        return filteredData.map((record: object) => {
            Object.entries(record).forEach(([key, value]) => {
                const header = this.tableHeader.find(i => i.key === key);
                const response = header ? presenters.get(key, value, header.type) : value;

                if (this.tableMeta.actions && this.tableMeta.actions.length > 0) {
                    Object.assign(record, {actions: this.tableMeta.actions})
                }
                if (header) Object.assign(record, {[key]: this.getTranslation(key, response)});
            });

            return record;
        });
    }

    mounted(): void {
        this.fetchData();
    }

    @Watch('endpoint')
    onEndpointChange() {
        this.filteredData = [];
        this.fetchData();
    }

    @Watch('filteredData')
    onBodyChanged(value: Array<unknown>) {
        this.$emit('input', value);
    }

    @Watch('lazyFilters', {deep: true})
    private onLazyFiltersChange(): void {
        this.fetchData();
    }

    refresh(): void {
        this.unfilteredData = [];
        this.filteredData = [];
        this.filterAmount = 0;
        this.fetchData();
    }

    private fetchData(): void {
        this.loading = true;

        if (this.endpoint) {
            if (!this.dontCancelRequests) {
                try {
                    this.$store.state.mru.overviewTableRequestCancelToken?.cancel();
                } catch (e) {
                    // dont do anything
                }
            }

            this.$store.commit('mru/set', {
                key: 'overviewTableRequestCancelToken',
                value: axios.CancelToken.source(),
            });

            apiClient.get(this.endpoint, {
                cancelToken: this.$store.state.mru.overviewTableRequestCancelToken.token,
                params: this.lazyFilters,
            }).then((response: AxiosResponse) => {
                this.filteredData = this.unfilteredData = response.data.body;
                this.tableHeader = response.data.header;
                this.tableMeta = response.data.metadata;
                this.totals = this.filteredTotals = response.data.totals;

                this.options.sortBy[0] = this.sortField || response.data.metadata?.sortField;
                this.options.sortDesc[0] = response.data.metadata?.sortDirection === 'desc';

                this.$emit('fetched', response.data);
            }).catch((error: AxiosError) => {
                if (axios.isCancel(error)) {
                    // console.error('Request canceled', error.message);
                }
            }).finally(() => {
                this.loading = false;
            });
        }
    }

    /**
     * Headers getter
     */
    get header(): Array<DataTableHeader> {
        const header = this.tableHeader.map(item => ({
            text: this.$t([this.translationPath, 'TableHeader', item.key].join('.')),
            value: item.key,
            sortable: item.options.includes('sortable'),
            filterable: item.options.includes('searchable'),
            align: item.align ?? null,
            type: item.type,
        } as DataTableHeader));

        if (this.tableMeta.actions && this.tableMeta.actions.length > 0) {
            header.push({
                value: 'actions',
                text: this.$t('Global.FormLabel.Actions').toString(),
                sortable: false,
            });
        }

        return header;
    }

    /**
     * Filters getter
     */
    get filters(): Array<string> {
        return (this.tableMeta.filters || []);
    }

    /**
     * Updates the filters.
     */
    public setFilters(data: Array<any>): void {
        this.filteredData = data;

        this.$emit('applyFilters', this.filteredData, this.filteredTotals);
    }

    /**
     * Returns a translation if available in the map.
     */
    private getTranslation(key: string, value: string): string {
        const translationMap = this.translationMap.find((i: any) => i.key === key) as TranslationMap;

        if (translationMap && translationMap.multiple) {
            return value.split(', ').map((piece: string) => this.$t(`${translationMap.path}.${piece}`)).join(', ');
        }

        return translationMap ? this.$t(`${translationMap.path}.${value}`).toString() : value;
    }

    private rowClicked(item: Model) {
        this.$emit('rowClicked', item);
    }
}

interface OverviewBody extends Object {
    id: number;
}

interface OverviewBodies extends Array<OverviewBody> {
    [key: number]: OverviewBody;
}

interface OverviewHeader extends Object {
    key: string;
    options: Array<string>;
    align: string;
    type?: string;
}

interface OverviewHeaders extends Array<OverviewHeader> {
    [key: number]: OverviewHeader;
}

interface OverviewMeta extends Object {
    actions?: Array<string>;
    filters?: Array<string>;
    sortField?: string;
    sortDirection?: string;
    total: number;
}

export interface Total extends Object {
    key: string;
    amount: string;
    type: string;
}
