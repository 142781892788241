import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import PaymentItemInterface from '@/components/PaymentScreen/interfaces/PaymentItemInterface';
import Model from '@/Interfaces/Model';
import apiClient from '@/apiClient';

@Component({
    name: 'PaymentItemInsurance',
})
export default class PaymentItemInsurance extends Vue {
    @Prop({ default: () => [] }) value!: Array<Model>;
    @Prop() item!: PaymentItemInterface;

    private insurances = this.value;

    private items = [];

    get selected(): number {
        return this.insurances[ 0 ].id ?? 1;
    }

    set selected(value: number) {
        Vue.set(this, 'insurances', [
            {
                id: value,
            },
        ]);
    }

    @Watch('value', { immediate: true })
    onValueChange() {
        this.insurances = this.value;
    }

    @Watch('insurances', { deep: true })
    onInsurancesChange() {
        this.$emit('input', this.insurances);
    }

    remove() {
        this.insurances = [] as Array<Model>;
        this.$emit('input', this.insurances);
    }

    setInsuranceData(): void {
        const data = sessionStorage.getItem('zv-insurance-list');
        if ( data ) {
            this.items = JSON.parse(data);

            return;
        }

        this.refreshInsuranceData();
    }

    refreshInsuranceData(): void {
        apiClient.get('list/zv-insurance').then((response) => {
            sessionStorage.setItem('zv-insurance-list', JSON.stringify(response.data));

            this.items = response.data;
        });
    }

    mounted() {
        this.setInsuranceData();
    }
}
