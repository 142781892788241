import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
    name: 'StatusIcon',
})
export default class StatusIcon extends Vue {
    @Prop({ default: false }) approved!: boolean | string;
    @Prop({ default: '#00000' }) color!: string;

    private colors = {
        approved: '#38da76',
        denied: '#f00000',
    };
    private statusIcons = {
        approved: 'mdi-checkbox-marked-circle',
        denied: 'mdi-close-circle',
    };

    get status(): string {
        return this.approved ? 'approved' : 'denied';
    }
}
