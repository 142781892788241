import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import dayjs from 'dayjs';
import TreatmentReportInterface from '@/Interfaces/TreatmentReportInterface';
import { namespace } from 'vuex-class';

export interface CompareImage {
    url: string;
    treatment_info: string;
    date: string;
}

@Component({
    name: 'TreatmentReportPhotoComparison',
})
export default class TreatmentReportPhotoComparison extends Vue {
    @Prop({required: true}) history!: Array<TreatmentReportInterface>;
    @Prop() setImageId!: number | undefined;

    @namespace('reporthistory').Mutation('setLeftCompareImage') public setLeftCompareImage!: any;
    @namespace('reporthistory').Mutation('setRightCompareImage') public setRightCompareImage!: any;
    @namespace('reporthistory').Getter('state') public reportHistoryState: any;

    private preLoadPhoto: any = null;

    private leftImage: CompareImage|null = null;
    private rightImage: CompareImage|null = null;

    mounted() {
        this.clearVuexStorage();

        if (this.setImageId !== undefined) {
            this.preloadPhoto(this.setImageId, 'left');
        }
        if (this.reportHistoryState.compareImageLeft !== null) {
            this.preloadPhoto(this.reportHistoryState.compareImageLeft, 'left');
        }
        if (this.reportHistoryState.compareImageRight !== null) {
            this.preloadPhoto(this.reportHistoryState.compareImageRight, 'right');
        }
    }

    public clearVuexStorage(): void {
        this.setLeftCompareImage({ data: null  });
        this.setRightCompareImage({ data: null });
    }

    private resetImages(): void {
        this.leftImage = null;
        this.rightImage = null;

        this.clearVuexStorage();
    }

    private getImageUrl(id: number): string {
        return [ window.__env.API_URL, 'treatment-report', 'photos', id ].join('/');
    }

    private getImageThumbnailDate(date: Date): string {
        return dayjs(date).format('D MMM YYYY');
    }

    private preloadPhoto(photoId: number, position: 'left'|'right'): void {
        this.preLoadPhoto = this.history.find(history => {
            return history.photos?.find(photo => {
                return photo.id === photoId;
            });
        });

        if ( this.preLoadPhoto ) {
            this.loadImage(photoId,
                position,
                this.getImageUrl(photoId),
                this.preLoadPhoto?.appointment.datetime_from,
                this.preLoadPhoto?.practitioner.name,
                this.preLoadPhoto?.treatment.categoryName);
        } else {
            this.resetImages();
        }
    }

    private loadImage(photoId: number, position: 'left'|'right', url: string, date: string, practitioner: string, treatment_category: string) {
        const treatmentDetails = practitioner + ', ' + treatment_category;
        const displayDate = dayjs(date).format('D MMM YYYY');

        if (position === 'left') {
            this.leftImage = { url: url, date: displayDate, treatment_info: treatmentDetails }

            this.setLeftCompareImage({ data: photoId });
        }
        else if (position === 'right') {
            this.rightImage = { url: url, date: displayDate, treatment_info: treatmentDetails }

            this.setRightCompareImage({ data: photoId });
        }
    }
}
