import currency, { Options } from 'currency.js';
import dayjs from 'dayjs';

/**
 * Convert parameters object to string
 */
export function objectToQueryString(obj: Record<any, any>, prefix: string | null = null): string {
    const str = [];
    let p;

    for ( p in obj ) {
        if ( p in obj ) {
            const k = prefix ? `${prefix}[${p}]` : p;
            const v = obj[ p ];

            str.push((v !== null && typeof v === 'object') ?
                objectToQueryString(v, k) :
                encodeURIComponent(k) + '=' + encodeURIComponent(v),
            );
        }
    }

    return str.join('&');
}

export function findEachWordInOneOf(propertyNames: Array<string>) {
    // v-autocomplete filter callback to find each word typed by the user in at least one of the searchable fields
    return (client: any, query: string) => {
        if ( client.header ) return true; // Always match group name rows

        return query?.split(' ').every(word =>
            propertyNames.some(searchField => {
                    if ( searchField === 'birthdate' ) {
                        return true;
                    } else {
                        return client[ searchField ]?.toLowerCase().includes(word.toLowerCase());
                    }
                },
            ),
        );
    };
}

export function dynamicSort(property: string) {
    let sortOrder = 1;

    if ( property[ 0 ] === '-' ) {
        sortOrder = -1;
        property = property.substr(1);
    }

    return function(a: Record<string, any>, b: Record<string, any>) {
        const result = (a[ property ] < b[ property ]) ? -1 : (a[ property ] > b[ property ]) ? 1 : 0;

        return result * sortOrder;
    };
}

export function valueConvertedToCurrency(value: number): string {
    const currencyOptions = { symbol: '', decimal: ',', separator: '.', precision: 2 } as Options;

    return currency(value, Object.assign({ fromCents: true }, currencyOptions)).format();
}

export function formatTimeRange(from: string, till: string): string {
    return [ dayjs(from).format('HH:mm'), dayjs(till).format('HH:mm') ].join(' - ');
}

export function StrRandom(): string {
    return Math.random().toString(36).substring(7);
}
