import popup from '@/components/popup/popup.vue';
import PopupForm from '@/partials/PopupForm';
import Component from 'vue-class-component';
import User from '@/Interfaces/User';
import Location from '@/Interfaces/Location';
import Model from '@/Interfaces/Model';
import TimeSlot from '@/components/TimeSlot/TimeSlot.vue';
import { Prop, Watch } from 'vue-property-decorator';
import WeekdayPicker from '@/components/WeekdayPicker/WeekdayPicker.vue';
import apiClient from '@/apiClient';

interface EmployeeScheduleFormData extends Model {
    date_from: string;
    time_from?: string | any;
    time_till?: string | any;
    interval?: number;
    weekdays: Array<number>;
    recurrence_id?: number;
    model_id: number | unknown;
}

@Component({
    components: { popup, TimeSlot, WeekdayPicker },
})
export default class EmployeeScheduleForm extends PopupForm<EmployeeScheduleFormData> {
    @Prop({ default: null }) editType!: string | null;

    public isSeries = false;

    protected modelName = 'EmployeeSchedule';

    get endpoint(): string {
        return this.formData.recurrence_id ? `recurrences/${this.formData.recurrence_id}/user`
            : `recurrences/user`;
    }

    public initialDate: null | string = null;
    public multiple: boolean = false;

    public user: User = {
        first_name: null,
        last_name: null,
        birthdate: null,
        email: null,
        phone_number: null,
        id: 0,
    };

    public location: Location = {
        name: '',
        street: '',
        street_number: '',
        street_suffix: '',
        postcode: '',
        place: '',
        country: 0,
        id: 0,
    };

    private intervals = [
        {
            key: this.$t('Global.Intervals.0'),
            value: 0,
        },
        {
            key: this.$t('Global.Intervals.7'),
            value: 7,
        },
        {
            key: this.$t('Global.Intervals.14'),
            value: 14,
        },
        {
            key: this.$t('Global.Intervals.42'),
            value: 42,
        },
    ];

    @Watch('formData.date_from')
    onDateFromChanged() {
        if ( this.formData.weekdays?.length === 0 ) {
            const weekday = new Date(this.formData.date_from).getDay();
            Object.assign(this.formData.weekdays, [ (weekday !== 0 ? weekday : 7) ]);
        }
    }

    async update(): Promise<void> {
        return apiClient.put(`employees/${this.formData.model_id}/recurrence/${this.formData.recurrence_id}`, this.formData).then((response) => {
            this.showNotification(`${this.modelName}SuccessfullyModified`);
            this.handleSuccess('on-update', response.data, true);
        });
    }

    create(): Promise<void> {
        return apiClient.post(`employees/${this.formData.model_id}/recurrence`, this.formData).then((response) => {
            this.showNotification(`${this.modelName}SuccessfullyAdded`);
            this.handleSuccess('on-create', response.data, true);
        });
    }

    protected afterToggle() {
        this.formData.interval = 0;
        this.formData.date_from = new Date().toISOString().substr(0, 10);
    }

    get daysOff(): string {
        const dayOfWeek = {
            'ma': 0,
            'di': 1,
            'wo': 2,
            'do': 3,
            'vr': 4,
            'za': 5,
            'zo': 6,
        } as Record<string, number>;

        return this.user.free_days?.map((weekday: string) => {
            return this.$dayjs().locale(this.$i18n.locale).day(dayOfWeek[ weekday ]).format('dd');
        }).join(', ') || this.$t('Global.FormLabel.None').toString();
    }

    get minSelectableDate(): string {
        return new Date().toISOString();
    }

    private onWorkingDaysChanged(value: Array<number>): void {
        if ( value.length > 1 && this.formData.interval === 0 ) {
            this.formData.interval = 7;
            this.$forceUpdate();
        }
    }
}
