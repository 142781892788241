import Component from 'vue-class-component';
import Location from '@/Interfaces/Location';
import Autocomplete from '@/components/Autocompletes/Autocomplete';

@Component({
    name: 'LocationAutocomplete',
})
export default class TreatmentCategorieAutocomplete extends Autocomplete<Location> {
    endpoint = '/treatments/categories';

    mounted() {
        this.fetch();
    }
}
