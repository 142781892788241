import popup from '@/components/popup/popup.vue';
import PopupForm from '@/partials/PopupForm';
import Component from 'vue-class-component';
import ProductFormData from '@/Interfaces/ProductFormData';
import PriceField from '@/components/PriceField/PriceField.vue';
import apiClient from '@/apiClient';

@Component({
    components: {
        popup,
        PriceField,
    },
})

export default class SupportedCountryForm extends PopupForm<ProductFormData> {
    protected modelName = 'SupportedCountry';
    protected endpoint = 'supported-countries';

    public locales = [];
    public dependencyLoading = {
        locales: true,
    };

    public afterToggle(): void {
        this.getLocales();
    }

    public getLocales() {
        this.dependencyLoading.locales = true;
        apiClient.get('locales').then((response) => {
            this.locales = response.data;
        }).finally(() => this.dependencyLoading.locales = false);
    }
}
