import ClientFormData from '@/Interfaces/ClientFormData';
import CreditMutationObject from '@/Interfaces/CreditMutationObject';
import { valueConvertedToCurrency } from '@/utils/utils';
import dayjs from 'dayjs';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

@Component({
    name: 'CreditMutations',
})
export default class CreditMutations extends Vue {
    @namespace('mutationtypes').Getter getMutationById!: CallableFunction;

    @Prop() client!: ClientFormData;
    @Prop() value!: boolean;

    public dialog: boolean = false;

    @Watch('value', { immediate: true })
    onDialogChange() {
        this.dialog = this.value;
    }


    get creditMutations(): Array<CreditMutationObject> {
        if ( !this.client ) return [];
        let amount = 0;

        return this.client.credits?.map(mutation => {
            amount = mutation.amount + amount;

            return {
                amount: valueConvertedToCurrency(mutation.amount),
                balance: valueConvertedToCurrency(amount),
                created_at: CreditMutations.setDate(mutation.created_at),
                id: mutation.id,
                mutation_type: this.setMutationType(mutation.mutation_type_id),
            } as CreditMutationObject;
        }).reverse() ?? [];
    }

    public closeDialog(): void {
        this.$emit('input', false);
    }

    private setMutationType(typeId: number): string {
        return this.getMutationById(typeId).slug;
    }

    private static setDate(date: string): string {
        return dayjs(date).format('DD-MM-YYYY');
    }
}
