import Component from 'vue-class-component';
import Location from '@/Interfaces/Location';
import { Prop, Watch } from 'vue-property-decorator';
import Autocomplete from '@/components/Autocompletes/Autocomplete';

@Component({
    name: 'LocationAutocomplete',
})
export default class LocationAutocomplete extends Autocomplete<Location> {
    @Prop({ default: '/list/locations' }) endpoint !: string;

    @Watch('data')
    onDataChange() {
        if ( this.data.length === 1 ) {
            this.selected = this.data[ 0 ].id || null;
        }
    }

    mounted() {
        this.fetch();
    }
}
