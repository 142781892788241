// Vue
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Prop, Watch } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
// Vuetify
import { VDatePicker } from 'vuetify/lib';
// Vendor
import axios, { AxiosResponse } from 'axios';
// Project
import apiClient from '@/apiClient';
import LocationFormData from '@/Interfaces/LocationFormData';
import i18n from '@/i18n';
import dayjs from 'dayjs';

@Component({})

export default class DatePicker extends VDatePicker {
    @namespace('locations').State locations!: Array<LocationFormData>;

    @Prop() private readonly appointmentId!: number;
    @Prop() private readonly clientId!: number;
    @Prop({ default: () => [] }) private readonly models!: Array<number>;
    @Prop() private readonly locationId!: number;
    @Prop() private readonly treatmentId!: number;
    @Prop() private readonly userId!: number;
    @Prop() private readonly value!: string;
    @Prop() private startDate!: string;
    @Prop() private readonly treatmentDuration!: number;

    private dates: Array<any> = [];
    private month: string = dayjs(this.value).set('hours', 12).format('YYYY-MM');

    public dayjs = dayjs;
    public date: string = dayjs(this.value).set('hours', 12).format('YYYY-MM-DD');
    public datetime: string | null = this.value || null;
    public loading = false;

    private availabilityRequestCancelToken: any;

    @Watch('datetime')
    private onDatetimeChange(): void { this.$emit('input', this.datetime); }

    @Watch('clientId')
    private onClientIdChange(): void { this.fetch(); }

    @Watch('models')
    private onModelsChange(): void { this.fetch(); }

    @Watch('month')
    private onMonthChange(): void { this.fetch(); }

    @Watch('locationId')
    private onLocationIdChange(): void { this.fetch(); }

    @Watch('treatmentId')
    private onTreatmentIdChange(): void { this.fetch(); }

    @Watch('userId')
    private onUserIdChange(): void { this.fetch(); }

    @Watch('treatmentDuration')
    private onTreatmentDurationChange(): void { this.fetch(); }

    public get availability(): object {
        const response = {};

        this.dates.forEach((date: any) => {
            if ( dayjs(date.date).isBefore(new Date, 'day') ) return;
            if ( date.time.length === 0 ) return Object.assign(response, { [ date.date ]: '#f7fbfc' });

            const available = date.time.filter((time: any) => time.available).length;
            const percentage = available / date.time.length;

            if ( percentage >= 0.3 ) Object.assign(response, { [ date.date ]: 'rgba(56, 218, 118)' });
            if ( percentage < 0.3 ) Object.assign(response, { [ date.date ]: 'rgba(255, 172, 69)' });
            if ( percentage === 0 ) Object.assign(response, { [ date.date ]: 'rgba(220, 0, 0)' });
        });

        return response;
    }

    public get dateFrom(): string {
        return dayjs(this.month).startOf('month').format('YYYY-MM-DD');
    }

    public get dateTill(): string {
        return dayjs(this.month).endOf('month').format('YYYY-MM-DD');
    }

    public get location(): LocationFormData | undefined {
        return this.locations.find(location => location.id === this.locationId);
    }

    public get missing(): Array<TranslateResult> {
        return [ 'clientId', 'treatmentId', 'locationId' ]
            .filter(property => !this.$props[ property ])
            .map(property => i18n.t(`Global.Fields.Date.Props.${property}`));
    }

    public get times(): Array<any> {
        const date = dayjs(this.date);

        return (this.dates.find(i => i.date === this.date)?.time || []).map((time: any) => {
            return Object.assign(time, {
                time_from: dayjs(time.datetime_from).set('year', date.year()).set('month', date.month()).set('date', date.date()).toISOString(),
                time_till: dayjs(time.datetime_till).set('year', date.year()).set('month', date.month()).set('date', date.date()).toISOString(),
            });
        });
    }

    public beforeMount() {
        if ( !this.value && this.startDate) {
            this.$emit('input', this.startDate);

            this.month = dayjs(this.startDate).set('hours', 12).format('YYYY-MM');
            this.date = dayjs(this.startDate).set('hours', 12).format('YYYY-MM-DD');
            this.datetime = this.startDate || null;

            this.$forceUpdate();
        }
    }

    public mounted(): void {
        this.fetch();
    }

    private fetch(): void {
        if ( this.missing.length !== 0 ) return;

        try {
            this.availabilityRequestCancelToken.cancel();
        } catch ( e ) {
            //  don't do anything
        }

        this.availabilityRequestCancelToken = axios.CancelToken.source();

        const params = {
            appointment_id: this.appointmentId,
            client_id: this.clientId,
            datetime: null,
            date_from: this.dateFrom,
            date_till: this.dateTill,
            location_id: this.locationId,
            models: this.models,
            treatment_id: this.treatmentId,
            treatment_duration: this.treatmentDuration,
            user_id: this.userId,
        };

        this.loading = true;

        apiClient.get('event/availability', { cancelToken: this.availabilityRequestCancelToken.token, params })
            .then((response) => {
                this.setResponse(response);
                this.loading = false;
            }).catch((e) => {
            if ( !axios.isCancel(e) ) this.loading = false;
        });
    }

    private setResponse(response: AxiosResponse): void {
        this.dates = response.data.map((date: any) => {
            const time = date.time.map((time: any) => {
                const available = time.devices.length === this.models.length && time.room_id && time.user_id;
                const label = [ dayjs(time.datetime_from).format('HH:mm'), dayjs(time.datetime_till).format('HH:mm') ].join(' - ');

                return Object.assign(time, { available, label });
            });

            return Object.assign(date, { time });
        });
    }
}
