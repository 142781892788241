import Component from 'vue-class-component';
import OverviewTable from '@/components/OverviewTable/OverviewTable.vue';
import { TranslationMap } from '@/Interfaces/TranslationMap';
import IndexView from '@/partials/IndexView';
import Device from '@/Interfaces/Device';
import DeviceForm from '@/views/DeviceIndex/Forms/DeviceForm.vue';

@Component({
	components: {
		OverviewTable,
		DeviceForm
	}
})
export default class DeviceIndex extends IndexView<Device> {
	protected endpoint = 'devices';
	public translationMap = [
		{
			key: 'type',
			path: 'Api.DeviceTypes'
		}
	] as Array<TranslationMap>
}
