import Vue from 'vue';
import Component from 'vue-class-component';
import ClientImportantNotesForm from '@/views/ClientIndex/Forms/ClientImportantNotesForm/ClientImportantNotesForm.vue';
import { Prop, Ref } from 'vue-property-decorator';
import ClientFormData from '@/Interfaces/ClientFormData';
import { namespace } from 'vuex-class';

@Component({
    components: { ClientImportantNotesForm },
})
export default class ClientImportantNotes extends Vue {
    @Prop() value!: ClientFormData;
    @Ref() clientImportantNotesForm!: any;
    @namespace('me').State permissions: any;

    edit() {
        // Deep clone this.value to prevent form values being updated "realtime" in parent component before saving.
        this.clientImportantNotesForm.toggle({ ...this.value });
    }

    onUpdate(client: ClientFormData) {
        this.$emit('input', client);
    }
}