import Component from 'vue-class-component';
import PopupForm from '@/partials/PopupForm';
import popup from '@/components/popup/popup.vue';
import Model from '@/Interfaces/Model';
import { Prop, Watch } from 'vue-property-decorator';
import Location from '@/Interfaces/Location';
import ScheduleInterface from '@/Interfaces/ScheduleInterface';
import i18n from '@/i18n';
import TimeSlot from '@/components/TimeSlot/TimeSlot.vue';
import WeekdayPicker from '@/components/WeekdayPicker/WeekdayPicker.vue';
import apiClient from '@/apiClient';
import User from '@/Interfaces/User';
import dayjs from 'dayjs';

declare interface RoomScheduleFormData extends Model {
    date_from: string;
    location_id?: number;
    name?: string;
    model_id: number;
    time_from?: string;
    time_till?: string;
    weekdays: Array<number>;
    interval: number;
    recurrence_id: number;
}

@Component({
    name: 'RoomScheduleForm',
    components: { popup, TimeSlot, WeekdayPicker },
})
export default class RoomScheduleForm extends PopupForm<RoomScheduleFormData> {
    @Prop() locationModel!: Location;
    @Prop() scheduleModel!: Array<ScheduleInterface>;
    public isSeries = false;

    public initialDate: null | string = null;

    public user: User = {
        first_name: null,
        last_name: null,
        birthdate: null,
        email: null,
        phone_number: null,
        id: 0,
    };

    public location: Location = {
        name: '',
        street: '',
        street_number: '',
        street_suffix: '',
        postcode: '',
        place: '',
        country: 0,
        id: 0,
    };

    protected modelName = 'RoomSchedule';
    protected endpoint = 'recurrences/room';

    private radioSelected = 'custom';

    private intervals = [
        {
            key: this.$t('Global.Intervals.0'),
            value: 0,
        },
        {
            key: this.$t('Global.Intervals.7'),
            value: 7,
        },
        {
            key: this.$t('Global.Intervals.14'),
            value: 14,
        },
        {
            key: this.$t('Global.Intervals.42'),
            value: 42,
        },
    ];

    @Watch('formData.date_from')
    private onDateFromChanged() {
        if ( this.formData.weekdays?.length === 0 ) {
            const weekday = new Date(this.formData.date_from).getDay();
            Object.assign(this.formData.weekdays, [ (weekday !== 0 ? weekday : 7) ]);
        }
    }

    @Watch('radioSelected')
    private onRadioSelectedChanged() {
        Object.assign(this.formData, {
            time_from: new Date(this.schedule.time_from),
            time_till: new Date(this.schedule.time_till),
        });
    }

    public get schedule(): any {
        return this.scheduleModel.find(schedule => dayjs(this.formData.date_from).weekday() + 1 === schedule.weekday);
    }

    public get locationOpeningHours(): string {
        return this.schedule.active ? [
            dayjs(this.schedule.time_from).format('HH:mm'),
            dayjs(this.schedule.time_till).format('HH:mm'),
        ].join(' - ') : this.$t('Global.FormLabel.Closed').toString();
    }

    async update(): Promise<void> {
        return apiClient.put(`rooms/${this.formData.model_id}/recurrence/${this.formData.recurrence_id}`, this.formData).then((response) => {
            this.showNotification(`${this.modelName}SuccessfullyModified`);
            this.handleSuccess('on-update', response.data, true);
        });
    }

    create(): Promise<void> {
        return apiClient.post(`rooms/${this.formData.model_id}/recurrence`, this.formData).then((response) => {
            this.showNotification(`${this.modelName}SuccessfullyAdded`);
            this.handleSuccess('on-create', response.data, true);
        });
    }

    protected afterToggle() {
        this.formData.interval = 0;

        this.scheduleModel.forEach(schedule => {
            Object.assign(schedule, {
                time_from: new Date(schedule.time_from),
                time_till: new Date(schedule.time_till),
            });
        });

        this.setRadioSelected();
        this.$forceUpdate();
    }

    get formattedDate(): string {
        return new Date(this.formData.date_from).toLocaleDateString(i18n.locale, {
            day: 'numeric',
            month: '2-digit',
            year: 'numeric',
        });
    }

    private onWorkingDaysChanged(value: Array<number>): void {
        if ( value.length > 1 && this.formData.interval === 0 ) {
            this.formData.interval = 7;
            this.$forceUpdate();
        }
    }

    private setRadioSelected() {
        let selected = 'custom';

        if ( this.schedule.active ) {
            const isFrom = dayjs(this.formData.time_from).format('HH:mm') === dayjs(this.schedule.time_from).format('HH:mm');
            const isTill = dayjs(this.formData.time_till).format('HH:mm') === dayjs(this.schedule.time_till).format('HH:mm');

            selected = isFrom && isTill ? 'location' : selected;
        }

        this.radioSelected = selected;
    }
}
