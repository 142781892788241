// Vue
import Component from 'vue-class-component';
import { Ref, Watch } from 'vue-property-decorator';
// Project
import AppointmentFormData from '@/Interfaces/AppointmentFormData';
import ClientForm from '@/views/ClientIndex/Forms/ClientForm.vue';
import ClientPicker from '@/components/ClientPicker/ClientPicker.vue';
import GooglePlaces from '@/components/GooglePlaces/GooglePlaces.vue';
import DatePicker from '@/views/CalendarIndex/Forms/Components/DatePicker/DatePicker.vue';
import LocationPicker from '@/views/CalendarIndex/Forms/Components/LocationPicker/LocationPicker.vue';
import ModelPicker from '@/views/CalendarIndex/Forms/Components/ModelPicker/ModelPicker.vue';
import Popup from '@/components/popup/popup.vue';
import PopupForm from '@/partials/PopupForm';
import TreatmentPicker from '@/components/TreatmentPicker/TreatmentPicker.vue';
import UserPicker from '@/views/CalendarIndex/Forms/Components/UserPicker/UserPicker.vue';
import Vue from 'vue';
import TreatmentDurationField from '@/components/TreatmentDurationField/TreatmentDurationField.vue';
import Treatment from '@/Interfaces/Treatment';
import SupportedCountries from '@/components/Autocompletes/SupportedCountries/SupportedCountries.vue';

@Component({
    components: {
        ClientForm,
        ClientPicker,
        DatePicker,
        GooglePlaces,
        LocationPicker,
        ModelPicker,
        Popup,
        TreatmentPicker,
        UserPicker,
        TreatmentDurationField,
        SupportedCountries,
    },
})

export default class AppointmentForm extends PopupForm<AppointmentFormData> {
    protected modelName: string = 'Appointment';
    protected endpoint: string = 'event';

    @Ref() public clientPicker: any;

    public googlePlace: any = null;

    private treatments: Array<Treatment> = [];

    public onClientCreate(client: any): void {
        this.clientPicker.clients.push(client);
        Vue.set(this.formData, 'client_id', client.id);
    }

    public onRaw(): void {
        this.$emit('raw', this.formData);
    }

    private setTreatments(treatments: Array<Treatment>): void {
        Object.values(treatments).map(values => this.treatments = this.treatments.concat(values));
    }

    protected afterToggle() {
        this.treatments = [];
    }

    @Watch('formData.treatment_id')
    onTreatmentIdChange(value: any) {
        if ( this.treatments.length > 0 ) {
            const duration = this.treatments.find(treatment => treatment.id === value)?.duration;

            if ( duration ) {
                Vue.set(this.formData, 'treatment_duration', this.treatments.find(treatment => treatment.id === value)?.duration);
            }
        }
    }
}

