import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Component
export default class DeactivateToggle extends Vue {
    @Prop({ default: false }) value!: boolean;
    public toggle = this.value as boolean;

    @Watch('toggle')
    onToggleChange() {
        this.$emit('input', this.toggle);
    }
}
