import Component from 'vue-class-component';
import OverviewTable from '@/components/OverviewTable/OverviewTable.vue';
import TreatmentForm from '@/views/TreatmentIndex/Forms/TreatmentForm.vue';
import IndexView from '@/partials/IndexView';
import { Watch } from 'vue-property-decorator';
import SupportedCountries from '@/components/Autocompletes/SupportedCountries/SupportedCountries.vue';

@Component({
    components: {
        OverviewTable,
        TreatmentForm,
        SupportedCountries,
    },
})
export default class TreatmentIndex extends IndexView<any> {
    protected endpoint: string | null = null;
    private selectedSupportedCountryId: number | null = null;

    protected endpointEdit = 'treatments';

    @Watch('selectedSupportedCountryId')
    onSelectedSupportedCountryIdChange() {
        if ( this.selectedSupportedCountryId ) {
            this.endpoint = `treatments/country/${this.selectedSupportedCountryId}`;
        }
    }
}
