import Component from 'vue-class-component';
import Vue from 'vue';
import { Portal } from 'portal-vue';
import { Prop, Watch } from 'vue-property-decorator';
import dayjs from 'dayjs';
import apiClient from '@/apiClient';
import { AxiosResponse } from 'axios';
import { namespace } from 'vuex-class';
import EmployeeFormData from '@/Interfaces/EmployeeFormData';
import Avatar from '@/components/Avatar/Avatar.vue';

@Component({
    name: 'CalendarPlanning',
    components: {
        Portal,
        Avatar,
    },
})

export default class CalendarPlanning extends Vue {
    @Prop({ required: true }) readonly dateFrom!: dayjs.Dayjs;
    @Prop({ required: true }) readonly dateTill!: dayjs.Dayjs;
    @Prop({ default: [] }) public users!: Array<EmployeeFormData>;
    @Prop({ default: false, type: Boolean }) public showCollapseAll!: boolean;
    @Prop({ default: null }) public currentRoomId!: number | null;

    @namespace('mru').State('location') mruLocation!: any;
    @namespace('mru').State('user') selectedUser!: any;

    private loading = true;
    private data = [];
    private panelStatus = [] as Array<number>;

    @Watch('dateFrom')
    @Watch('mruLocation')
    @Watch('selectedUser')
    public dateChanged() {
        this.fetch();
    }

    public getCalendarType(): string {
        const calendarType = this.$route.meta?.type;

        return calendarType == 'location' ? 'location' : 'user';
    }

    private mounted() {
        if ( this.$store.getters[ 'calendarplanning/locationId' ] !== this.mruLocation ||
            this.$store.getters[ 'calendarplanning/date_from' ] !== this.dateFrom ) {
            this.fetch();
        } else {
            this.data = this.$store.getters[ 'calendarplanning/data' ];
            this.setPanelsToOpen();
            this.loading = false;
        }
    }

    private destroyed() {
        this.$store.commit('calendarplanning/setData', this.data);
        this.$store.commit('calendarplanning/setLocationId', this.mruLocation);
        //this.$store.commit('calendarplanning/setRoomId', data);
        this.$store.commit('calendarplanning/setDateFrom', this.dateFrom);
    }

    private setPanelsToOpen() {
        const status = [] as Array<number>;
        for ( let i = 0; i <= Object.keys(this.data).length; i++ ) {
            status.push(i);
        }

        this.panelStatus = status;
    }

    private fetch() {
        this.loading = true;

        const params = {
            date_from: this.dateFrom.format('YYYY-MM-DD'),
            date_till: this.dateTill.format('YYYY-MM-DD'),
        };

        const endpoint = this.getCalendarType() === 'location' ? `locations/${this.mruLocation}/calendar-planning`
            : `employees/${this.selectedUser.id}/calendar-planning`;

        apiClient.get(endpoint, { params }).then((response: AxiosResponse) => {
            this.data = response.data;
            this.setPanelsToOpen();
        }).finally(() => {
            this.loading = false;
        });
    }

    private formatDayHeader(date: string): string {
        return new Date(date).toLocaleDateString(this.$i18n.locale, {
            weekday: 'long',
            day: '2-digit',
            month: '2-digit',
        });
    }

    onClickClose() {
        this.$emit('close');
    }

    private getAvatarPropertiesForUserWithId(id: number): Record<string, string> {
        const user = this.users.find((user) => user.id === id);

        return {
            background: user?.color.background ?? '#f4f8f9',
            text: user?.color.text,
        };
    }

    private getMaxHeight(): number {
        const parent = document.getElementsByClassName('main-content-inner-container-content')[ 0 ];
        const child = parent.firstChild as HTMLElement;

        const calulatedHeight = (child.clientHeight - 75);
        return calulatedHeight < window.innerHeight ? window.innerHeight : calulatedHeight;
    }

    private collapseAll(): void {
        this.panelStatus = [];
    }

    getMutatedDevices(data: any): Array<any> {
        return this.currentRoomId ? data.devices.filter((device: any) => device.room.id === this.currentRoomId || device.room.id === null) : data.devices;
    }

    private getTimeFromTillFormat(record: { datetime_from: string; datetime_till: string }): string {
        return dayjs(record.datetime_from).format('HH:mm') + ' - ' + dayjs(record.datetime_till).format('HH:mm');
    }
}
