import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ScheduleInterface from '@/Interfaces/ScheduleInterface';
import MenuButton from '@/components/MenuButton/MenuButton.vue';
import User from '@/Interfaces/User';
import Device from '@/Interfaces/Device';
import dayjs, { Dayjs } from 'dayjs';
import { namespace } from 'vuex-class';

@Component({
    name: 'ScheduleCell',
    components: {
        MenuButton,
    },
})
export default class ScheduleCell extends Vue {
    @Prop() data!: ScheduleInterface;
    @Prop() record!: Record<string, any>;
    @Prop() weekday!: number;
    @Prop() startDate!: Dayjs;
    @Prop() closeMenuOnClickOutside!: boolean;

    @namespace('me').State permissions: any;

    public menuVisible: boolean = false;
    public localeDateOptions = {
        day: 'numeric',
        weekday: 'short',
        month: 'long',
        year: 'numeric',
    };

    public rowClicked(): void {
        this.menuVisible = true;
    }

    get isSeries(): boolean {
        return this.data.interval > 0;
    }

    get year(): number {
        const date = this.startDate;
        const year = date.isoWeeksInYear() === 52 ? this.startDate.year() : this.startDate.day(this.weekday).year();

        return year;
    }


    get daysInRange(): string {
        const daysString = this.data.recurrence?.weekdays.map((day: number) => {
            return dayjs().isoWeekday(day).locale(this.$i18n.locale).format('dd');
        }).join(', ');

        return [ this.$t(`Global.Intervals.${this.data.interval}`), daysString ].join(': ');
    }

    get timeRange(): string {
        return [
            dayjs(this.data.time_from).format('HH:mm'),
            dayjs(this.data.time_till).format('HH:mm'),
        ].join(' - ');
    }

    get dateRange(): string {
        const dates = [ new Date(this.data.date).toLocaleString(this.$i18n.locale, this.localeDateOptions) ];

        if ( !this.isSeries || !this.data.date_till ) return dates.join(' - ');
        dates.push(new Date(this.data.date_till).toLocaleString(this.$i18n.locale, this.localeDateOptions));

        return dates.join(' - ');
    }

    showDeleteConfirmationSeries(): void {
        this.$emit('showDeleteConfirmationSeries', {
            recurrence_id: this.data.recurrence_id,
            name: this.record.name,
            date: this.data.date,
        });
    }

    showDeleteConfirmationOccurence(): void {
        this.$emit('showDeleteConfirmationOccurrence', {
            recurrence_id: this.data.recurrence_id,
            name: this.record.name,
            id: this.data.timetable_id,
            date: this.data.date,
        });
    }

    addButtonClicked(record: User | Device, weekday: number): void {
        this.$emit('addButtonClicked', { record, weekday, year: this.year});
    }

    editCell(type: string) {
        this.$emit('editCell', { record: this.record, data: this.data, type });
    }


}
