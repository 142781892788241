import Vue from 'vue';
import Component from 'vue-class-component';
import {Ref} from "vue-property-decorator";

@Component({
    name: 'SignatureParent'
})
export default class SignatureParent extends Vue {
    @Ref() signaturePad: any;

    public undo() {
        (this.$refs.signaturePad as any).undoSignature();
    }

    public makeEmpty() {
        (this.$refs.signaturePad as any).clearSignature();
    }

    public save() {
        (this.$refs.signaturePad as any).saveSignature();
    }
}
