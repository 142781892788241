import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import i18n from '@/i18n';
import apiClient from '@/apiClient';
import Appointment from '@/Interfaces/Appointment';
import dayjs from 'dayjs';
import Popup from '@/components/popup/popup.vue';
import { default as PopupComponent } from '@/components/popup/popup';
import { namespace } from 'vuex-class';
import store from '@/store';
import ClientFormData from '@/Interfaces/ClientFormData';
import StatusIcon from '@/components/StatusIcon/StatusIcon.vue';
import { default as PaymentScreenComponent } from '@/components/PaymentScreen/PaymentScreen';
import PaymentScreen from '@/components/PaymentScreen/PaymentScreen.vue';
import TreatmentReportPopup from '@/components/TreatmentReport/Components/Popup/TreatmentReportPopup.vue';
import { default as TreatmentReportPopupComponent } from '@/components/TreatmentReport/Components/Popup/TreatmentReportPopup';
import { default as TreatmentReportComponent } from '@/components/TreatmentReport/TreatmentReport';
import TreatmentReport from '@/components/TreatmentReport/TreatmentReport.vue';

@Component({
    name: 'ClientScheduledAppointments',
    components: {
        Popup,
        StatusIcon,
        PaymentScreen,
        TreatmentReport,
        TreatmentReportPopup,
    },
})
export default class ClientScheduledAppointments extends Vue {
    @namespace('application').Action showNotification: any;

    @Prop() value!: Array<Appointment>;
    @Prop({ default: false }) loading!: boolean;
    @Prop() client!: ClientFormData;
    @Ref() newAppointmentPopup!: PopupComponent;
    @Ref() editAppointmentPopup!: PopupComponent;
    @Ref() sendEmailPopup!: PopupComponent;
    @Ref() paymentPopup!: PopupComponent;
    @Ref() paymentScreen!: PaymentScreenComponent;
    @Ref() treatmentReportPopup!: TreatmentReportPopupComponent;
    @Ref() treatmentReport!: TreatmentReportComponent;

    public appointments: Array<Appointment> = [];

    @Watch('value', { immediate: true })
    onValueChange() {
        this.appointments = this.value;
    }

    @Watch('appointments', { immediate: true })
    onAppointmentsChange() {
        this.$emit('input', this.appointments);
    }

    public items: any;

    getAppointmentDate(appointment: Appointment) {
        const start = new Date(appointment.datetime_from);
        const end = new Date(appointment.datetime_till);

        const datetime = start.toLocaleDateString(i18n.locale, {
            weekday: 'short',
            day: '2-digit',
            month: 'long',
            year: 'numeric',
        }) + ' • ' + start.toLocaleTimeString(i18n.locale, {
            hour: '2-digit',
            minute: '2-digit',
        }) + ' - ' + end.toLocaleTimeString(i18n.locale, {
            hour: '2-digit',
            minute: '2-digit',
        });

        return datetime.charAt(0).toUpperCase() + datetime.slice(1);
    }

    getDuration(startDate: string, endDate: string): string {
        const duration = dayjs.duration(dayjs(endDate).diff(dayjs(startDate)));

        const hours = duration.format('H');
        const minutes = duration.format('m');

        return (hours !== '0' ? hours + ' ' + this.$t('Global.Times.Hour') : '') + ' ' + (minutes ? minutes + ' ' + this.$t('Global.Times.Minutes') : '') + ' ';
    }

    newAppointment() {
        this.$router.push(`/calendar/new/${this.client.id}`);
    }

    editAppointment(appointmentId: number): void {
        this.$router.push(`/calendar/edit/${appointmentId}`);
    }

    followUpAppointment(appointmentId: number): void {
        this.$router.push(`/calendar/repeat/${appointmentId}`);
    }

    showEmailPopup(appointmentId: number) {
        this.sendEmailPopup.toggle(appointmentId);
    }

    emailReminder(appointmentId: number): void {
        apiClient.post(`appointments/${appointmentId}/remind`, {}).then(() => {
            this.sendEmailPopup.close();
        }).catch(() => store.dispatch('application/showNotification', 'SomethingWentWrong'))
            .finally(() => store.dispatch('application/showNotification', 'AppointmentReminderEmailSend'));
    }

    noShow(appointmentId: number): void {
        apiClient.patch(`event/${appointmentId}/no-show`)
            .then(() => {
                store.dispatch('application/showNotification', 'AppointmentNoShowed');
            }).catch(() => store.dispatch('application/showNotification', 'SomethingWentWrong'))
            .finally(() => this.$emit('reload'));
    }

    cancelAppointment(appointmentId: number): void {
        apiClient.delete(`event/${appointmentId}`)
            .then(() => {
                store.dispatch('application/showNotification', 'AppointmentCancelled');
            }).catch(() => store.dispatch('application/showNotification', 'SomethingWentWrong'))
            .finally(() => this.$emit('reload'));
    }

    isNoShowOrCancelled(appointment: Appointment): boolean {
        return !!appointment.no_show_at || !!appointment.deleted_at;
    }

    private openPaymentPopup(appointment: Appointment) {
        if ( appointment.is_paid ) {
            this.$emit('setTab', 2);
            this.$router.push(`/clients/${appointment.client_id}/2`);
        } else {
            this.paymentPopup.toggle(appointment.id);
        }
    }

    private closePaymentPopup() {
        this.paymentPopup.close();
        this.$emit('reload');
    }

    private paymentMade(eventId: number) {
        const appointment = this.appointments.find((appointment: Appointment) => appointment.id === eventId);
        Object.assign(appointment, {
            is_paid: true,
        });
        this.$emit('reload');
    }

    private openTreatmentReport(appointment: Appointment) {
        this.treatmentReportPopup.toggle({
            id: appointment.id,
        });
    }

    private savedTreatmentReport() {
        this.$emit('reload');
    }
}