import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Ref } from 'vue-property-decorator';
import ClientFormData from '@/Interfaces/ClientFormData';
import presenters from '@/components/OverviewTable/presenters';
import ClientForm from '@/views/ClientIndex/Forms/ClientForm.vue';
import Contract from '@/Interfaces/Contract';
import StatusIcon from '@/components/StatusIcon/StatusIcon.vue';
import FitText from '@/components/FitText/FitText.vue';
import { namespace } from 'vuex-class';
import SignAgreementModal from '@/components/SignAgreementModal/SignAgreementModal.vue';

@Component({
    components: { SignAgreementModal, ClientForm, StatusIcon, FitText },
})
export default class ClientDetails extends Vue {
    @Prop() value!: ClientFormData;
    @Ref() clientForm!: any;
    public presenters = presenters;
    private contracts: Array<Contract> = [];

    @namespace('me').State permissions: any;

    private loading = {
        contracts: true,
    };

    get hasMissingDetails(): boolean {
        return [ 'first_name', 'last_name', 'email', 'phone_number', 'birthdate',
            'street', 'street_number', 'postcode', 'place' ].some(property => {
            return !this.value[ property as keyof ClientFormData ];
        });
    }

    edit() {
        // Deep clone this.value to prevent form values being updated "realtime" in parent component before saving.
        this.clientForm.toggle({ ...this.value });
    }

    onUpdate(client: ClientFormData) {
        this.$emit('input', client);
    }
}

