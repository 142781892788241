import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

@Component({})

export default class GooglePlaces extends Vue {
    private googleMaps: any = (window as any).google.maps;

    private debounce: any;
    private geocoder: any = new this.googleMaps.Geocoder();

    public loading: boolean = false;
    public places: Array<any> = [];
    public query: string = '';

    @Watch('query')
    private onQueryChange(): void {
        if ( this.debounce ) clearTimeout(this.debounce);

        if ( !this.query ) return;

        this.debounce = setTimeout(() => {
            const options = { address: this.query, region: 'nl' };

            this.geocoder.geocode(options)
                .then((response: any) => this.places = response.results)
                .catch(() => this.places = [])
                .finally(() => this.loading = false);

            this.loading = true;
        }, 300);
    }
}
