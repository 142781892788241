import Vue from 'vue';
import PopupForm from '@/partials/PopupForm';
import {default as OverviewTableRef} from '@/components/OverviewTable/OverviewTable';
import Component from 'vue-class-component';
import {default as PopupRef} from '@/components/popup/popup';
import apiClient from '@/apiClient';
import Model from '@/Interfaces/Model';
import {namespace} from 'vuex-class';

@Component
export default class IndexView<T extends Model> extends Vue {
    public deleteInProgress = false;
    protected endpoint!: string | null;
    protected endpointEdit!: string | null;

    @namespace('me').State permissions: any;

    $refs!: {
        form: PopupForm<T>;
        overviewTable: OverviewTableRef;
        deletePopup: PopupRef;
    };

    /**
     * Delete action clicked
     */
    deleteActionClick(data: T): void {
        this.$refs.deletePopup.toggle(data);
    }

    edit(data: T): void {
        document.body.classList.add('waiting');
        apiClient.get(`${this.endpointEdit || this.endpoint}/${data.id}`).then((response) => {
            this.$refs.form.toggle(response.data);

            this.$refs.form.isDirty = false;
        }).finally(() => {
            document.body.classList.remove('waiting');
        });
    }

    onUpdate(data: T) {
        const index = this.$refs.overviewTable.unfilteredData.findIndex((item) => item.id === data.id);
        this.$refs.overviewTable.unfilteredData.splice(index, 1, data as any);
        const filteredIndex = this.$refs.overviewTable.filteredData.findIndex((item) => item.id === data.id);
        this.$refs.overviewTable.filteredData.splice(filteredIndex, 1, data as any);
    }

    onCreate(data: T) {
        this.$refs.overviewTable.unfilteredData.push(data as any);
    }

    deactivate(data: T): Promise<void> {
        this.deleteInProgress = true;

        return apiClient.delete(`${this.endpointEdit || this.endpoint}/${data.id}`).then(() => {
            const index = this.$refs.overviewTable.unfilteredData.findIndex((item: Model) => item.id === data.id);
            this.$refs.overviewTable.unfilteredData.splice(index, 1);
        }).finally(() => {
            this.deleteInProgress = false;
            this.$refs.deletePopup.toggle();
        });
    }
}
