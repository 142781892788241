var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('MazPhoneNumberInput',{class:{invalid: !_vm.isValid},attrs:{"default-phone-number":_vm.defaultNumber,"noValidation":"","translations":_vm.translations ? _vm.translations : {},"default-country-code":_vm.countryCode,"preferred-countries":[
              'BE',
              'DE',
              'FR',
              'GB',
              'LU',
              'NL'
              ]},on:{"update":_vm.updatePhoneNumber,"blur":_vm.fieldOnBlur},model:{value:(_vm.mutatableVariable),callback:function ($$v) {_vm.mutatableVariable=$$v},expression:"mutatableVariable"}}),_c('span',{staticClass:"phone-number-error",style:({visibility: _vm.isValid ? 'hidden' : 'visible'})},[_vm._v(" "+_vm._s(_vm.$t('Global.FormRule.PhoneNumberRequired'))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t('Global.FormRule.ValidPhoneNumber'))+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }