import Component from 'vue-class-component';
import AppointmentForm from '@/views/CalendarIndex/Forms/AppointmentForm.vue';
import AppointmentRaw from '@/views/AppointmentIndex/Forms/AppointmentForm.vue';
import LocationPicker from '@/components/LocationPicker/LocationPicker.vue';
import ColorLegend from '@/views/CalendarIndex/Components/ColorLegend/ColorLegend.vue';
import Calendar from '@/views/CalendarIndex/Components/Calendar/Calendar.vue';
import MenuButton from '@/components/MenuButton/MenuButton.vue';
import CalendarDayView from '@/views/CalendarIndex/Components/Calendar/DayView/CalendarDayView.vue';
import CalendarWeekView from '@/views/CalendarIndex/Components/Calendar/WeekView/CalendarWeekView.vue';
import CalendarIndexParent from '@/views/CalendarIndex/CalendarIndexParent';
import Location from '@/Interfaces/Location';
import { AxiosResponse } from 'axios';
import CalendarSwitchView from '@/views/CalendarIndex/Components/Calendar/SwitchView/CalendarSwitchView.vue';
import dayjs from 'dayjs';
import colors from '@/views/CalendarIndex/colors';
import Vue from 'vue';
import { Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

@Component({
    components: {
        AppointmentForm,
        AppointmentRaw,
        Calendar,
        CalendarDayView,
        CalendarWeekView,
        CalendarSwitchView,
        ColorLegend,
        LocationPicker,
        MenuButton
    },
})

export default class CalendarLocationIndex extends CalendarIndexParent {
    @namespace('mru').State('calendarView') mruCalendarView!: any;
    private locations = [] as Array<Location>;
    private switchViewDays = [] as Array<any>;

    @Watch('location')
    public onLocationChange() {
        if (this.calendarView === 'switch') {
            this.calendarView = 'day';
        }
        this.fetch();
    }

    mounted() {
        if (this.mruCalendarView !== undefined) {
            this.calendarView = this.mruCalendarView;
        }
        else {
            this.calendarView = 'day';
        }
    }

    setResponseFromParent(response: AxiosResponse) {
        if (this.calendarView !== 'switch') {
            this.locations = response.data.locations;
        }
        else {
            response.data.switchCalendarDays.forEach((switchViewDay: any) => {
                switchViewDay.date = dayjs(switchViewDay.date);
                switchViewDay.users.map((user: any, index: number) => {
                    return Object.assign(user, { color: colors[ index % colors.length ] });
                });

                Vue.set(this, 'appointments', switchViewDay.appointments.map((appointment: any) => {
                    return Object.assign(appointment, { user: this.users.find(i => i.id === appointment.user_id) });
                }));
            });

            this.switchViewDays = response.data.switchCalendarDays;
        }
    }

    protected endpoint() {
        if (this.calendarView === 'day') {
            return [ 'locations', this.mruLocation, 'calendar', this.date.format('YYYY-M-D') ].join('/');
        }
        else if (this.calendarView === 'week') {
            return [ 'locations', this.mruLocation, 'calendar/week', this.startWeekDate.format('YYYY-M-D'), this.endWeekDate.format('YYYY-M-D')].join('/');
        }
        else if (this.calendarView === 'switch') {
            return [ 'locations', this.mruLocation, 'calendar/switch', this.date.format('YYYY-M-D') ].join('/');
        }
    }
}
