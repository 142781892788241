import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import PaymentItemInterface from '@/components/PaymentScreen/interfaces/PaymentItemInterface';
import PriceField from '@/components/PriceField/PriceField.vue';

declare interface DiscountInterface {
    amount: number | null;
    percentage: number | null;
}

@Component({
    name: 'PaymentItemDiscount',
    components: {
        PriceField,
    },
})
export default class PaymentItemDiscount extends Vue {
    @Prop({ default: () => [] }) value!: Array<DiscountInterface>;
    @Prop() item!: PaymentItemInterface;

    private discounts = [] as Array<DiscountInterface>;

    @Watch('value', { immediate: true })
    onValueChange() {
        this.discounts = this.value;
    }

    @Watch('item.amount')
    onItemAmountChanged() {
        if ( this.discounts.length > 0 ) {
            this.onDiscountPercentageChange();
        }
    }

    @Watch('item.type')
    onItemTypeChanged() {
        if ( this.discounts.length > 0 ) {
            this.onDiscountPercentageChange();
        }
    }


    private onDiscountPercentageChange() {
        const price = (this.item.price ?? 0);

        this.discounts[ 0 ].amount = ((price / 100) * (this.discounts[ 0 ].percentage ?? 0));

        this.$emit('input', this.discounts);
    }

    private onDiscountAmountChange() {
        const price = (this.item.price || 0);

        this.discounts[ 0 ].percentage = Math.round(((this.discounts[ 0 ].amount ?? 0) / price) * 100);

        this.$emit('input', this.discounts);
    }

    private removeDiscount() {
        this.discounts = [] as Array<DiscountInterface>;

        this.$emit('input', this.discounts);
    }
}
