import Component from 'vue-class-component';
import Vue from 'vue';
import { namespace } from 'vuex-class';
import { findEachWordInOneOf } from '@/utils/utils';
import { Prop } from 'vue-property-decorator';
import axios, { AxiosResponse } from 'axios';
import User from '@/Interfaces/User';
import apiClient from '@/apiClient';

@Component({
})

export default class EmployeePicker extends Vue {
    // Props
    @Prop() public returnObject!: boolean;

    // Vuex
    @namespace('locations').State public locations: any;
    @namespace('mru').State('user') employee!: any;

    // Variables
    public findEachWordInOneOf = findEachWordInOneOf;
    public loading: boolean = true;
    public employees: Array<User> = [];

    protected endpoint = 'list/employees/calendar-list';
    private requestCancelToken: any;

    public mounted(): void {
        this.fetch();
        if (this.employee) {
            this.employees.unshift(this.employee);
            this.$emit('input', this.employee);
        }
    }

    private fetch(): void {

        try {
            this.requestCancelToken.cancel();
        } catch ( e ) {
            // Doe maar niks
        }
        this.requestCancelToken = axios.CancelToken.source();

        this.loading = true;

        apiClient.get(this.endpoint, { cancelToken: this.requestCancelToken.token })
            .then(this.setResponse.bind(this)).then(() => this.loading = false );
    }

    private setResponse(response: AxiosResponse): void {
        this.loading = false;

        this.employees = response.data.body;
    }
}
