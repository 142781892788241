import { Prop, Watch } from 'vue-property-decorator';
import i18n from '@/i18n';
import Component from 'vue-class-component';
import Vue from 'vue';

@Component
export default class TreatmentDurationField extends Vue {
    @Prop() rules!: Array<any>;
    @Prop() value!: number | null;

    public duration = this.value || null;

    public durationOptions = [ 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95,
        100, 105, 110, 115, 120, 125, 130, 135, 140, 145, 150, 155, 160, 165, 170, 175, 180 ].map(minutes =>
        ({ minutes, text: i18n.t('Global.Fields.Minutes', { minutes }) }),
    );

    @Watch('value')
    onValueChange() {
        this.duration = this.value;
    }

    @Watch('duration')
    private onInputChange(): void {
        this.$emit('input', this.duration);
    }
}
