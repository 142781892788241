import Component from 'vue-class-component';
import OverviewTable from '@/components/OverviewTable/OverviewTable.vue';
import { TranslationMap } from '@/Interfaces/TranslationMap';
import IndexView from '@/partials/IndexView';
import Package from '@/Interfaces/Package';
import PackageForm from '@/views/PackageIndex/Forms/PackageForm.vue';
import SupportedCountries from '@/components/Autocompletes/SupportedCountries/SupportedCountries.vue';
import { Watch } from 'vue-property-decorator';

@Component({
    components: {
        OverviewTable,
        PackageForm,
        SupportedCountries,
    },
})
export default class PackageIndex extends IndexView<Package> {
    private selectedSupportedCountryId: number | null = null;

    protected endpointEdit = 'packages';

    @Watch('selectedSupportedCountryId')
    onselectedSupportedCountryIdChange() {
        if ( this.selectedSupportedCountryId ) {
            this.endpoint = `packages/country/${this.selectedSupportedCountryId}`;
        }
    }

    public translationMap = [
        {
            key: 'type',
            path: 'Api.PackageTypes',
        },
    ] as Array<TranslationMap>;
}
