import Component from 'vue-class-component';
import OverviewTable from '@/components/OverviewTable/OverviewTable.vue';
import IndexView from '@/partials/IndexView';
import TreatmentCategoriesForm from '@/views/DatabaseModels/Components/TreatmentCategories/Form/TreatmentCategoriesForm.vue';

@Component({
    components: {
        OverviewTable,
        TreatmentCategoriesForm,
    },
})
export default class TreatmentCategoriesIndex extends IndexView<any> {
    protected endpoint = 'treatment-categories/dto';
}
