import Vue from 'vue';
import Component from 'vue-class-component';
import Avatar from '@/components/Avatar/Avatar.vue';
import Location from '@/Interfaces/Location';
import apiClient from '@/apiClient';
import EmployeeSchedule from '@/views/ScheduleIndex/Employee/EmployeeSchedule.vue';
import RoomSchedule from '@/views/ScheduleIndex/Room/RoomSchedule.vue';
import { Watch } from 'vue-property-decorator';
import MenuButton from '@/components/MenuButton/MenuButton.vue';
import { namespace } from 'vuex-class';

@Component({
    components: {
        Avatar,
        EmployeeSchedule,
        MenuButton,
        RoomSchedule,
    },
})

export default class ScheduleIndex extends Vue {
    @namespace('mru').State('scheduleLocationId') mruScheduleLocation!: any;
    @namespace('mru').State('scheduleWeek') mruScheduleWeek!: any;
    @namespace('mru').State('scheduleTab') mruScheduleTab!: any;
    @namespace('mru').State('scheduleDate') mruScheduleDate!: any;
    @namespace('mru').Mutation('set') setMru!: any;
    @namespace('me').State permissions: any;

    public calendarDate = new Date().toISOString().substr(0, 10) as string;
    public weekTravel = 0 as number;
    public weekdays = [] as Array<any>;
    public locations = [] as Array<Location>;
    public selectedLocationId = null as null | number;
    public selectedLocationSchedule = [];

    public tab: number = 0;

    @Watch('calendarDate')
    onCalendarDateChanged() {
        const currentDate = this.$dayjs();
        const selectedDate = this.$dayjs(this.calendarDate).startOf('week');
        this.setMru({ key: 'scheduleDate', value: this.$dayjs(this.calendarDate) });
        const currentDateStartOfWeek = currentDate.clone().startOf('week');

        let weekDiff = selectedDate.diff(currentDate, 'week');
        const dayDiff = selectedDate.diff(currentDateStartOfWeek, 'day');

        if ( dayDiff > 5 ) {
            weekDiff++;
        }
        this.weekTravel = weekDiff;
        this.setMru({ key: 'scheduleWeek', value: this.weekTravel });

        this.setWeekDays();
    }

    @Watch('selectedLocationId')
    onSelectedLocationIdChanged() {
        this.setMru({ key: 'scheduleLocationId', value: this.selectedLocationId });
        apiClient.get(`/locations/${this.selectedLocationId}`).then((response) =>
            this.selectedLocationSchedule = response.data
                .timetables);
    }

    @Watch('tab')
    onTabChanged() {
        this.setMru({ key: 'scheduleTab', value: this.tab });
    }

    mounted(): void {
        apiClient.get('/locations/raw').then((response) => this.locations = response.data);

        if (this.mruScheduleLocation !== undefined) {
            this.selectedLocationId = this.mruScheduleLocation;
        }

        if (this.mruScheduleTab !== undefined) {
            this.tab = this.mruScheduleTab;
        }

        if (this.mruScheduleDate !== undefined && this.mruScheduleDate !== null) {
            this.calendarDate = this.mruScheduleDate.toISOString().substr(0, 10);
        }

        if (this.mruScheduleWeek !== undefined) {
            this.weekTravel = this.mruScheduleWeek;
        }

        this.setWeekDays();
    }

    get currentRangeNotation(): string {
        const firstInRange = this.weekdays[ 0 ];
        const lastInRange = this.weekdays.slice(-1)[ 0 ];

        let start = [];

        start.push(firstInRange.format('D'));
        firstInRange.month() !== lastInRange.month() && start.push(firstInRange.format('MMM'));
        start = [ start.join(' ') ];
        firstInRange.year() !== lastInRange.year() && start.push(firstInRange.format('YYYY'));

        let end = [];
        end.push(lastInRange.format('D'));
        end.push(lastInRange.format('MMM'));
        end = [ end.join(' ') ];
        end.push(lastInRange.format('YYYY'));

        return [ start.join(', '), end.join(', ') ].join(' - ');
    }

    get weekNumber(): number {
        return this.$dayjs().add(this.weekTravel, 'week').isoWeek();
    }

    setWeekDays(): void {
        this.weekdays = [];

        for ( let i = 0; i <= 6; i++ ) {
            const date = this.$dayjs().startOf('week').add(i, 'day').add(this.weekTravel, 'week');

            this.weekdays.push(date);
        }
    }

    travelWeek(amount: number): void {
        this.weekTravel = this.weekTravel + amount;
        this.setMru({ key: 'scheduleWeek', value: this.weekTravel });
        this.setWeekDays();
    }

    resetToToday(): void {
        this.weekTravel = 0;
        this.setWeekDays();
    }

    get selectedLocation(): Location | undefined {
        return this.locations.find((model) => model.id === this.selectedLocationId);
    }
}
