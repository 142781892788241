// Vue
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
// Project
import apiClient from '@/apiClient';
import { objectToQueryString } from '@/utils/utils';

@Component({})

export default class ModelPicker extends Vue {
    @Prop() readonly type!: string;

    public loading: boolean = false;
    public models: Array<any> = [];

    public mounted(): void {
        this.fetch();
    }

    private fetch(): void {
        const params = objectToQueryString({ device_type: this.type });
        const endpoint = [ 'event/models', params ].join('?');

        apiClient.get(endpoint)
            .then(response => this.models = response.data)
            .finally(() => this.loading = false);

        this.loading = true;
    }
}
