import { render, staticRenderFns } from "./ClientScheduledAppointments.vue?vue&type=template&id=55b4d13d&scoped=true&"
import script from "./ClientScheduledAppointments.ts?vue&type=script&lang=ts&"
export * from "./ClientScheduledAppointments.ts?vue&type=script&lang=ts&"
import style0 from "./ClientScheduledAppointments.scss?vue&type=style&index=0&id=55b4d13d&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55b4d13d",
  null
  
)

export default component.exports