import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
    name: 'NumberField',
})
export default class NumberField extends Vue {
    @Prop({ default: null }) min!: number | null;
    @Prop({ default: null }) max!: number | null;

    check(event: FocusEvent) {
        const target = event.target as HTMLInputElement;
        const value = parseInt(target.value);

        if ( this.min !== null ) {
            if ( value < this.min ) {
                this.$emit('input', this.min);
            }
        }

        if ( this.max !== null ) {
            if ( value > this.max ) {
                this.$emit('input', this.max);
            }
        }
    }
}
