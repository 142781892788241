import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class Loader extends Vue {
    @Prop({ type: Boolean, default: false }) readonly primary !: boolean;
    @Prop({ type: String }) readonly colorOverwrite !: string;

    get color(): string {
        if ( this.colorOverwrite ) return this.colorOverwrite;

        const primaryColor = this.$vuetify.theme.themes.light.primary?.toString() || '#ffffff';

        return this.primary ? primaryColor : '#ffffff';
    }
}
