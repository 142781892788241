import Component from 'vue-class-component';
import LedgerNumber from '@/Interfaces/LedgerNumber';
import { Prop, Watch } from 'vue-property-decorator';
import Autocomplete from '@/components/Autocompletes/Autocomplete';

@Component({
    name: 'LedgerNumberAutocomplete',
})
export default class LedgerNumberAutocomplete extends Autocomplete<LedgerNumber> {
    endpoint = '/ledgers';
    @Prop({ default: 1 }) protected country!: number;

    @Watch('country')
    onPropertyChanged(oldValue: number, newValue: number) {
        this.endpoint = '/ledgers/' + this.country;

        if ( newValue && oldValue !== newValue ) {
            this.fetch();
        }
    }

    @Watch('data')
    onDataChange() {
        if ( this.data.length === 1 ) {
            this.selected = this.data[ 0 ].id || null;
        }
    }

    getText(ledger: any): string {
        return [ ledger.Code, 'CT', ledger.Description ].join(' - ');
    }

    mounted() {
        this.endpoint = `${this.endpoint}/${this.country}`;

        this.fetch();
    }
}
