import popup from '@/components/popup/popup.vue';
import PopupForm from '@/partials/PopupForm';
import Component from 'vue-class-component';
import ProductFormData from '@/Interfaces/ProductFormData';
import { namespace } from 'vuex-class';
import PriceField from '@/components/PriceField/PriceField.vue';
import DeactivateToggle from '@/components/DeactivateToggle/DeactivateToggle.vue';
import LedgerNumberAutocomplete from '@/components/Autocompletes/LedgerNumberAutocomplete/LedgerNumberAutocomplete.vue';
import SupportedCountries from '@/components/Autocompletes/SupportedCountries/SupportedCountries.vue';
import VatSelect from '@/components/VatSelect/VatSelect.vue';
import {Prop} from 'vue-property-decorator';
import PriceLevelPriceInput from "@/components/PriceLevelPicker/PriceLevelPriceInput.vue";

@Component({
    components: {
        PriceLevelPriceInput,
        popup,
        PriceField,
        DeactivateToggle,
        LedgerNumberAutocomplete,
        SupportedCountries,
        VatSelect,
    },
})
export default class ProductForm extends PopupForm<ProductFormData> {
    @Prop({ default: 0 }) supportedCountryId!: number;
    @namespace('me').State id: any;
    protected modelName = 'Product';
    protected endpoint = 'products';

    protected defaultFormData = { peeling: false, voucher: false };
}
