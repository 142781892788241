// copied from https://app.zeplin.io/project/5fe1b4bffdd98a3a79934a3e/screen/603fbc060fa56114a5fef581
export default [
    { background: '#3affab', text: '#2b2f39' },
    { background: '#43e1ca', text: '#2b2f39' },
    { background: '#768efe', text: '#ffffff' },
    { background: '#5c32ff', text: '#ffffff' },
    { background: '#894be6', text: '#ffffff' },
    { background: '#a893fe', text: '#ffffff' },
    { background: '#b965ff', text: '#ffffff' },
    { background: '#68cdec', text: '#2b2f39' },
    { background: '#ef6581', text: '#ffffff' },
    { background: '#fec768', text: '#2b2f39' },
    { background: '#1db994', text: '#ffffff' },
    { background: '#00a8a1', text: '#ffffff' },
    { background: '#049fcb', text: '#ffffff' },
    { background: '#004cd3', text: '#ffffff' },
    { background: '#3114a2', text: '#ffffff' },
    { background: '#5b47ad', text: '#ffffff' },
    { background: '#452d58', text: '#ffffff' },
    { background: '#74335a', text: '#ffffff' },
    { background: '#ac2661', text: '#ffffff' },
    { background: '#f8a818', text: '#FFFFFF' },
];