import Vue from 'vue';
import Component from 'vue-class-component';
import TreatmentCategorieAutocomplete
    from '@/components/Autocompletes/TreatmentCategorieAutocomplete/TreatmentCategorieAutocomplete.vue';
import {
    default as CategoriesScript
} from '@/components/Autocompletes/TreatmentCategorieAutocomplete/TreatmentCategorieAutocomplete';
import FormRules from '@/partials/FormRules';
import {Prop, Ref, Watch} from 'vue-property-decorator';
import {StrRandom} from '@/utils/utils';
import Contract from '@/Interfaces/Contract';
import Popup from '@/components/popup/popup.vue';
import {default as PopupComponent} from '@/components/popup/popup';
import apiClient from '@/apiClient';
import dayjs from 'dayjs';

declare interface NewFile {
    treatment_category_id: number | null;
    file: any | null;
    identifier: string;
    reading: boolean;
    data: string | null;
    name: string | null;
}

@Component({
    name: 'AgreementUpload',
    components: {
        TreatmentCategorieAutocomplete,
        Popup,
    },
})
export default class AgreementUpload extends Vue {
    @Prop({default: () => []}) contracts !: Array<Contract>;
    @Prop() value!: any;
    @Ref() deletePopup!: PopupComponent;

    public formRules = FormRules;
    public newFiles: Array<NewFile> = [
        this.newFile(),
    ];
    private contractsMutable = this.contracts;
    private categories = [];
    private loading = true;
    private selectedContract: null | Contract = null;

    get accept(): string {
        return [
            '.jpg',
            '.jpeg',
            '.png',
            '.doc',
            '.docx',
            '.pdf',
        ].join(', ');
    }

    @Watch('value')
    onValueChange() {
        if (this.value === undefined) {
            this.newFiles = [
                this.newFile(),
            ];
        }
    }

    @Watch('newFiles', {deep: true})
    onNewFilesChange() {
        const filter = this.newFiles.filter((item) => !!item.treatment_category_id && !!item.file);
        if (filter.length === this.newFiles.length) {
            this.newFiles.push(this.newFile());
        }

        const emitData = this.newFiles.filter((file) => file.treatment_category_id && file.data)
            .map(({treatment_category_id, data, name}) => ({treatment_category_id, data, name}));

        this.$emit('input', emitData);
    }

    mounted() {
        (new CategoriesScript()).makeApiCall().then((response) => {
            this.categories = response.data;
            this.loading = false;
        });
    }

    private newFile(): NewFile {
        return {
            treatment_category_id: null,
            file: null,
            identifier: StrRandom(),
            reading: false,
            data: null,
            name: null,
        };
    }

    removeNewFile(file: NewFile) {
        const index = this.newFiles.findIndex((item) => item.identifier === file.identifier);
        this.newFiles.splice(index, 1);
    }

    newFileSelected(file: NewFile) {
        const newFile = this.newFiles.find((item) => item.identifier === file.identifier);

        if (newFile) {
            newFile.reading = true;

            const reader = new FileReader();
            reader.readAsDataURL(newFile.file);
            reader.onload = () => {
                newFile.data = reader.result?.toString().split(',')[1] ?? null;
                newFile.name = newFile.file.name;
                newFile.reading = false;
            };

            reader.onerror = () => {
                newFile.reading = false;
            };
        }
    }

    getFormattedDate(date: string): string {
        return dayjs(date).format('DD-MM-YYYY').toString();
    }

    showDeleteModal(contract: Contract): void {
        this.selectedContract = contract;
        this.deletePopup.toggle(contract);
    }

    deleteContract() {
        const data = this.deletePopup.data as Contract;

        apiClient.delete(`contracts/${data.id}`).then(() => {
            const index = this.contractsMutable.findIndex((item) => item.id === data.id);
            this.contractsMutable.splice(index, 1);

            this.$emit('updateContracts', this.contractsMutable);
        }).finally(() => {
            this.deletePopup.toggle();
        });
    }

    selectedContractName(): string {
        if (this.deletePopup?.data) {
            const data = this.deletePopup.data as Contract;

            return data.name;
        }

        return '';
    }
}
