import popup from '@/components/popup/popup.vue';
import PopupForm from '@/partials/PopupForm';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import DeactivateToggle from '@/components/DeactivateToggle/DeactivateToggle.vue';
import InvoiceFormData from "@/Interfaces/InvoiceFormData";
import DateField from "@/components/DateField/DateField.vue";
import dayjs from "dayjs";
import apiClient from "@/apiClient";

@Component({
    methods: {dayjs},
    components: {
        DateField,
        popup,
        DeactivateToggle,
    },
})
export default class InvoiceForm extends PopupForm<InvoiceFormData> {
    @namespace('me').State id: any;
    protected modelName = 'Invoice';
    protected endpoint = 'invoices';

    // Override of parent
    async update(close: boolean): Promise<void> {
        (!this.formData.deactivated_at && this.deactivated) && await this.deactivateModel();
        (!!this.formData.deactivated_at && !this.deactivated) && await this.activateModel();

        if (this.formData.ref === undefined) return;

        this.formData.transaction.cost_center = this.formData.cost_center;
        this.formData.transaction.cost_unit = this.formData.cost_unit;

        return apiClient.put(`${this.endpoint}/${this.formData.ref}`, this.formData).then((response) => {
            this.showNotification(`${this.modelName}SuccessfullyModified`);
            this.handleSuccess('on-update', response.data, close);
        });
    }
}
