import Component from 'vue-class-component';
import OverviewTable from '@/components/OverviewTable/OverviewTable.vue';
import IndexView from '@/partials/IndexView';
import GenderForm from '@/views/DatabaseModels/Components/Gender/Form/GenderForm.vue';

@Component({
    components: {
        OverviewTable,
        GenderForm,
    },
})
export default class GenderIndex extends IndexView<any> {
    protected endpoint = 'genders/dto';
}
