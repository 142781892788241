import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Component
export default class DateField extends Vue {
    @Prop({ default: '' }) label!: string;
    @Prop({ default: '' }) value!: string;
    @Prop({ default: false }) showFutureDates!: boolean;
    @Prop({ default: false }) readonly !: boolean;

    public active: boolean = false;
    public input: string = this.getValue();

    public get display(): string {
        return this.input.split('-').reverse().join('-');
    }

    public set display(value: string) {
        this.input = value.split('-').reverse().join('-');
    }

    private getValue() {
        return this.value ? new Date(this.value).toISOString().substr(0, 10) : '';
    }

    mounted(): void {
        this.input = this.getValue();
    }

    @Watch('input')
    onInputChange() {
        this.$emit('input', new Date(this.input).toISOString());
    }

    @Watch('value')
    onValueChange() {
        if (this.readonly) this.input = this.getValue();
    }
}
