import i18n from '@/i18n';
import zxcvbn from 'zxcvbn';

/**
 * Generic rules that can be used for form validation
 */
const regex = (pattern: RegExp, translatePath: string) => [ (v: any) => (pattern.test(v)) || i18n.t(translatePath) ];
const centsToEuros = (cents: number) => (cents / 100).toLocaleString('nl-NL', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: false,
});

const FormRules = {
    email: [ (v: string) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || i18n.t('Global.FormRule.Email') ] as Array<unknown>,
    phoneNumber: [ (v: string) => !v || /(^\+[0-9]{2}|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{9}$|[0-9\-\s]{10}$)/.test(v) || i18n.t('Global.FormRule.PhoneNumber') ] as Array<unknown>,
    password: [ (v: string) => !!v || i18n.t('Global.FormRule.Password') ] as Array<unknown>,
    required: [ (v: string | number) => v === 0 || !!v || i18n.t('Global.FormRule.Required') ],
    minimalArray: (amount: number) => [ (v: Array<string>) => v && v.length >= amount || i18n.t('Global.FormRule.MinimalArray', { amount }) ],
    regex,
    intOnly: [ (v: string) => /^\d+$/.test(v) || i18n.t('Global.FormRule.IntOnly') ],
    exactDigits: (amount: number) => [ (v: string) => !v || v.toString().length == amount || i18n.t('Global.FormRule.ExactDigits', { amount }) ],
    alphaNumericWithDashes: regex(/([a-zA-Z\-\d]+$)/, 'Global.FormRule.AlphaNumericWithDashes'),
    nrFromTo: (min: number, max: number) => [ (v: string) => v && /^[\d]{0,2}$/.test(v) || i18n.t('Global.FormRule.NumberFromTo', {
        min,
        max,
    }) ],
    passwordStrength: (v: string) => zxcvbn(v).score >= 3 || i18n.t('Global.FormRule.PasswordStrength'),
    passwordsMatch: (other: string) => [ (v: string) => v && other === v || i18n.t('Global.FormRule.PasswordsMatch') ],
    maxPrice: (max: number) => (cents: number) => !cents || cents <= max || i18n.t('Global.FormRule.MaxPrice', { amount: centsToEuros(max) }),
    timeLaterThan: (time: string) => [ (v: string) => parseInt(time?.replace(':', ''))
        < parseInt(v?.replace(':', '')) || i18n.t('Global.FormRule.TimeLaterThan') ],

};

export default FormRules;
