import Vue from 'vue';
import Component from 'vue-class-component';
import PaymentScreen from '@/components/PaymentScreen/PaymentScreen.vue';
import { Ref } from 'vue-property-decorator';
import { default as PaymentScreenComponent } from '@/components/PaymentScreen/PaymentScreen';
import Popup from '@/components/popup/popup.vue';
import { default as PopupComponent } from '@/components/popup/popup';


@Component({
    name: 'PaymentIndex',
    components: {
        Popup,
        PaymentScreen,
    },
})
export default class PaymentIndex extends Vue {
    @Ref() paymentScreen!: PaymentScreenComponent;
    @Ref() paymentPopup!: PopupComponent;

    private closePaymentPopup() {
        this.paymentPopup.close();
    }
}
