import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
    name: 'WeekdayPicker',
})
export default class WeekdayPicker extends Vue {
    @Prop() value!: Array<any>;

    get mutated() {
        return this.value;
    }

    set mutated(value) {
        this.$emit('input', value);
        this.$emit('change', value);
    }

    private weekDays = [ ...Array(7).keys() ]
        .map((index: number) => this.$dayjs().locale(this.$i18n.locale).day(((index + 1) === 8 ? 0 : (index + 1))).format('dd'));

}
