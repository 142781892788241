import Vue from 'vue';
import Component from 'vue-class-component';
import i18n from '@/i18n';
import { Prop, Ref } from 'vue-property-decorator';

Vue.config.ignoredElements.push('vue-popup');

/**
 * Component properties
 */
@Component({
    components: {},
})
export default class Popup extends Vue {
    public hidePrimary = false as boolean;
    public visible = false as boolean;
    public data = null as unknown;

    public get secondaryTextFallback() {
        return i18n.t('Global.Button.Cancel').toString();
    }

    @Prop({ default: false }) public readonly hideSecondary!: boolean;
    @Prop({ default: '' }) public readonly titleText !: string;
    @Prop({ default: i18n.t('Global.Button.Add').toString() }) public readonly primaryText !: string;
    @Prop() public readonly secondaryText !: string;
    @Prop({ default: true }) public readonly primaryEnabled !: boolean;
    @Prop({ default: false }) public readonly loading !: boolean;
    @Prop({ default: false }) public readonly fullscreen !: boolean;
    @Prop({ default: false }) public readonly noPadding !: boolean;
    @Prop({ default: false }) public readonly noScroll !: boolean;
    @Prop({ default: false }) public readonly overwritePrimaryButton !: boolean;

    @Ref() scrollPopup!: HTMLElement;

    public close(): void {
        this.visible = false;
        this.data = undefined;
    }

    /**
     * Toggle popup visible / hidden
     */
    public toggle(data = null as unknown): void {
        this.visible && this.$emit('close');

        this.visible = !this.visible;

        Vue.set(this, 'data', JSON.parse(JSON.stringify(data)));
    }

    /**
     * Bubbles up an event when the primary button is clicked
     */
    public primaryClick(): void {
        this.$emit('primaryClick', this.data);
    }
}
