import PopupForm from '@/partials/PopupForm';
import Component from 'vue-class-component';
import Modal from '@/components/Modal/Modal.vue';
import AgreementUpload from '@/views/ClientIndex/Forms/AgreementUpload/AgreementUpload.vue';
import Contract from '@/Interfaces/Contract';
import { namespace } from 'vuex-class';
import AgreementUploadFormData from "@/Interfaces/AgreementUploadFormData";

@Component({
    name: 'AgreementUploadModal',
    components: {
        Modal,
        AgreementUpload
    }
})
export default class AgreementUploadModal extends PopupForm<AgreementUploadFormData> {
    @namespace('mru').State('client') mruClient!: any;
    protected endpoint!: string;
    protected modelName: string = "Contract";

    private get canSave(): boolean|undefined {
        return this.formData.new_contracts && Object.keys(this.formData.new_contracts).length > 0;
    }

    public mounted() {
        this.endpoint = "contracts/" + this.mruClient.id;
    }

    private updateContracts(contracts: Array<Contract>) {
        Object.assign(this.formData, {
            contracts,
        });
    }
}
