import PopupForm from '@/partials/PopupForm';
import Component from 'vue-class-component';
import Modal from '@/components/Modal/Modal.vue';
import Contract from '@/Interfaces/Contract';
import { namespace } from 'vuex-class';
import apiClient from '@/apiClient';

@Component({
    name: 'AgreementDeleteModal',
    components: {
        Modal,
    }
})
export default class AgreementDeleteModal extends PopupForm<any> {
    @namespace('mru').State('client') mruClient!: any;
    protected endpoint!: string;
    protected modelName: string = "Contract";

    public mounted() {
        this.endpoint = "contracts/" + this.mruClient.id;
    }

    private deleteAgreement() {
        this.loading = true;
        apiClient.delete(`contracts/${this.formData.contractId}`).then(() => {
            this.showNotification(`${this.modelName}SuccessfullyDeleted`);
        }).finally(() => {
           this.$emit('contractRemoved');
           this.loading = false;

           this.toggle();
        });
    }
}
