import Component from 'vue-class-component';
import OverviewTable from '@/components/OverviewTable/OverviewTable.vue';
import User from '@/Interfaces/User';
import EmployeeForm from '@/views/EmployeeIndex/Forms/EmployeeForm.vue';
import IndexView from '@/partials/IndexView';

@Component({
	components: {
		OverviewTable,
		EmployeeForm
	}
})
export default class EmployeeIndex extends IndexView<User> {
	protected endpoint = 'employees';
}
