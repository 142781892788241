import { render, staticRenderFns } from "./TreatmentReportBody.vue?vue&type=template&id=1f6ea4a5&scoped=true&"
import script from "./TreatmentReportBody.ts?vue&type=script&lang=ts&"
export * from "./TreatmentReportBody.ts?vue&type=script&lang=ts&"
import style0 from "./TreatmentReportBody.scss?vue&type=style&index=0&id=1f6ea4a5&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f6ea4a5",
  null
  
)

export default component.exports