import { valueConvertedToCurrency } from '@/utils/utils';
import Component from 'vue-class-component';
import { Prop, Ref } from 'vue-property-decorator';
import FormRules from '@/partials/FormRules';
import { VuetifyTextField } from '@/Interfaces/VuetifyTextField';
import Vue from 'vue';
import currency, { Options } from 'currency.js';
import { LocaleMessage } from 'vue-i18n';

const currencyOptions = { decimal: ',', separator: '.', precision: 2 } as Options;

@Component({})
export default class FloatField extends Vue {
    @Prop(String) label!: string;
    @Prop({ default: false }) allowNegative!: boolean;
    @Prop(Boolean) dense!: boolean;
    @Prop({ default: 'auto' }) hideDetails!: boolean | string;
    @Prop(Number) max!: number;
    @Prop({ default: () => [] }) rules!: Array<any>;
    @Prop() value!: number;
    @Prop({ default: 'text' }) type!: string;
    @Ref() textField!: VuetifyTextField;
    public validationError: boolean | LocaleMessage = true;

    get valueMappedToFloat(): string {
        if ( !this.value ) return '0';

        return valueConvertedToCurrency(this.value);
    }

    onInput(value: string): void {
        this.textField.lazyValue = this.sanitize(value);
    }

    onBlur(event: { target: HTMLInputElement }): void {
        const price = currency(event.target.value, currencyOptions);

        const padded = price.format();
        const cents = price.intValue;

        if (this.max !== undefined) {
            this.updateValidationError(cents);
        }
        this.textField.validate(true, padded);

        this.$emit('input', cents);
        this.$emit('change');
    }

    private sanitize(value: string): string {
        const pattern = this.allowNegative ? /[^0-9,]/g : /[^0-9,-]/g;
        return value.replace(pattern, '') // remove non-numeric and comma characters
            .replace(/,(\d{2})\d*/, ',$1');  // remove any decimals after the first two
    }

    private updateValidationError(cents: number): void {
        this.validationError = FormRules.maxPrice(this.max)(cents);
    }
}
