import PopupForm from '@/partials/PopupForm';
import popup from '@/components/popup/popup.vue';
import Component from 'vue-class-component';
import { getName as GetCountryName } from 'i18n-iso-countries';
import apiClient from '@/apiClient';
import User from '@/Interfaces/User';
import LocationFormData from '@/Interfaces/LocationFormData';
import Country from '@/Interfaces/Country';
import { Watch } from 'vue-property-decorator';
import i18n from '@/i18n';
import BusinessHours from '@/views/LocationIndex/Forms/BusinessHours.vue';
import Rooms from '@/views/LocationIndex/Forms/Rooms.vue';
import DeactivateToggle from '@/components/DeactivateToggle/DeactivateToggle.vue';
import Model from '@/Interfaces/Model';

declare interface LocationType extends Model {
    slug: string;
    name: string;
}

@Component({
    components: { popup, BusinessHours, Rooms, DeactivateToggle },
})

export default class LocationForm extends PopupForm<LocationFormData> {
    public getCountryName = GetCountryName;
    public locationOwners = [] as Array<User>;
    public locationTypes = [] as Array<LocationType>;
    public priceLevels = [] as Array<PriceLevel>;
    public countries = [] as Array<Country>;
    public zipCodeRules = [] as Array<any>;
    public defaultFormData = { rooms: [ { name: '' } ] };
    public dayNames = [ 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday' ];

    protected modelName = 'Location';
    protected endpoint = 'locations';

    public itemsLoading = {
        employees: false,
        countries: false,
        types: false,
        price_levels: false,
    };

    // Once list of countries is loaded, set NL as default selected
    @Watch('countries')
     onCountryChanged() {
        if ( !this.formData.country_id ) {
            const countryCode = (window.__env.DEFAULT_LOCALE || 'nl').toUpperCase();
            this.formData.country_id = this.countries.find((country: Country) => country.code === countryCode)?.id || 0;
        }
    }

    @Watch('formData.country_id')
    onCountryIdchange() {
        this.fetchPriceLevels();
    }

    afterToggle() {
        this.fetchOwners();
        this.fetchCountries();
        this.getZipcodeRules();
        this.fetchTypes();

        if ( this.countries.length > 0 && !this.formData.country_id ) {
            const countryCode = (window.__env.DEFAULT_LOCALE || 'nl').toUpperCase();
            this.formData.country_id = this.countries.find((country: Country) => country.code === countryCode)?.id || 0;
        }
    }

    getZipcodeRules(): void {
        const rules = [ ...this.formRules.required ];
        const idForNL = this.countries.find((country: Country) => country.code === 'NL')?.id;

        if ( this.formData.country_id === idForNL ) {
            rules.push(...this.formRules.regex(/^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i, 'Global.FormRule.ZipCode'));
        }

        this.zipCodeRules = rules;
    }

    get translatedCountries(): Array<Country> {
        return this.countries.map((country: Country) => {
            return Object.assign(country, { name: this.getCountryName(country.code, i18n.locale) });
        });
    }

    private fetchPriceLevels(): void {
        this.itemsLoading.price_levels = true;
        apiClient.get(`list/country/${this.formData.country_id}/price-levels`)
            .then(response => {
                this.priceLevels = response.data;
            })
            .finally(() => this.itemsLoading.price_levels = false);
    }

    private fetchTypes(): void {
        this.itemsLoading.types = true;
        apiClient.get('list/location-types')
            .then(response => {
                this.locationTypes = response.data;
                this.formData.type = this.formData.type || response.data[ 0 ];
            })
            .finally(() => this.itemsLoading.types = false);
    }

    private fetchOwners(): void {
        this.locationOwners = [];
        this.itemsLoading.employees = true;

        apiClient.get('employees/?filters[role]=salon-owner')
            .then(response => this.locationOwners = response.data.body)
            .finally(() => this.itemsLoading.employees = false);
    }

    private fetchCountries(): void {
        if ( this.countries.length === 0 ) {
            apiClient.get('countries')
                .then(response => this.countries = response.data)
                .finally(() => this.itemsLoading.countries = false);

            this.itemsLoading.countries = true;
        }
    }
}
