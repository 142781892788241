import Component from 'vue-class-component';
import IndexView from '@/partials/IndexView';
import { namespace } from 'vuex-class';
import OverviewTable from '@/components/OverviewTable/OverviewTable.vue';
import Popup from '@/components/popup/popup.vue';
import { default as PopupComponent } from '@/components/popup/popup';
import InvoiceTableItem from '@/Interfaces/InvoiceTableItem';
import { Ref } from 'vue-property-decorator';
import apiClient from '@/apiClient';
import store from '@/store';
import InvoiceForm from "@/views/InvoiceIndex/Forms/InvoiceForm.vue";

@Component({
    name: 'ClientInvoicesIndex',
    components: {
        InvoiceForm,
        OverviewTable,
        Popup,
    },
})
export default class ClientInvoicesIndex extends IndexView<InvoiceTableItem> {
    @namespace('mru').State('client') mruClient!: any;
    @namespace('application').Action showNotification: any;
    @namespace('me').State permissions: any;
    @Ref() sendEmailPopup!: PopupComponent;
    @Ref() sendCreditInvoicePopup!: PopupComponent;
    @Ref() invoiceForm!: PopupComponent;

    private hasPermission = this.$store.getters['me/hasPermission']('invoice.update');
    private loading = false;

    get endpoint() {
        return `clients/${this.mruClient.id}/invoices?sort=id&direction=desc`;
    }

    showCreditInvoicePopup(item: InvoiceTableItem) {
        this.sendCreditInvoicePopup.toggle(item);
    }

    showMailPopup(item: InvoiceTableItem) {
        this.sendEmailPopup.toggle(item);
    }

    showInvoiceEditPopup(item: InvoiceTableItem) {
        if (item.item.ref === undefined) return;
        this.loading = true;
        apiClient.get("invoices/" + item.item.ref + "/transaction").then(data => {
            item.item = data.data[0];
            item.item.cost_unit = data.data[0].transaction.cost_unit;
            item.item.cost_center = data.data[0].transaction.cost_center;

            this.invoiceForm.toggle(item.item);
        }).finally(() => {
            this.loading = false;
        });
    }

    createCreditInvoice(item: InvoiceTableItem): void {
        this.loading = true;

        apiClient.post(`invoices/${item.item.ref}/credit`).then(() => {
            this.sendCreditInvoicePopup.close();
            store.dispatch('application/showNotification', 'InvoiceCredited');
            this.$refs.overviewTable.refresh();
        }).catch(exception => {
            const errors = (exception.response.data.errors.invoice || []);
            const payload = errors.length > 0 ? errors.pop() : 'InvoiceCreditedFailure';

            store.dispatch('application/showNotification', payload);
        }).finally(() => this.loading = false);
    }

    sendInvoice(item: InvoiceTableItem) {
        this.loading = true;
        apiClient.get(`invoices/${item.item.ref}/notify`).then(() => {
            this.sendEmailPopup.close();
            store.dispatch('application/showNotification', 'InvoiceSent');
        }).catch(() => {
            store.dispatch('application/showNotification', 'InvoiceSentFailure');
        }).finally(() => {
            this.loading = false;
        });
    }

    private rowClicked(item: InvoiceTableItem): void {
        window.open([ window.__env.API_URL, 'invoices', item.ref ].join('/'));
    }
}
