import Vue from 'vue';
import Component from 'vue-class-component';

/**
 * Component properties
 */
const Props = Vue.extend({
	props: {
		actions: {
			type: Array,
		},
		row: {
			type: Object,
		}
	}
})

@Component
export default class TableActions extends Props {
	public icons = {
		edit: 'mdi-pencil',
		delete: 'mdi-trash-can-outline'
	}

	/**
	 * Emit action click events
	 *
	 * @param action
	 */
	actionClicked(action: string): void {
		this.$emit(`${action}-clicked`, this.row);
	}
}
