// Vue
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
// Project
import apiClient from '@/apiClient';
import VuetifyAutoCompleteGroup from '@/Interfaces/VuetifyAutoCompleteGroup';
import { objectToQueryString } from '@/utils/utils';

@Component({})

export default class DevicePicker extends Vue {
    @Prop() readonly locationId!: number;
    @Prop() readonly type!: string;

    private devices: Record<string, any> = [];

    public loading: boolean = false;

    @Watch('locationId')
    private onLocationIdChange(): void { this.fetch(); }

    public get disabled(): boolean {
        return !this.locationId;
    }

    public get options(): VuetifyAutoCompleteGroup {
        const response: VuetifyAutoCompleteGroup = [];

        Object.entries(this.devices).forEach(([ key, value ]) => {
            response.push({ divider: true });
            response.push({ header: key });
            response.push({ divider: true });

            value.forEach((device: any) => {
                response.push(Object.assign({ group: key }, device));
            });
        });

        return response;
    }

    public mounted(): void {
        this.fetch();
    }

    private fetch(): void {
        if ( !this.locationId ) return;

        const params = objectToQueryString({ location_id: this.locationId, device_type: this.type });
        const endpoint = [ 'event/devices', params ].join('?');

        apiClient.get(endpoint)
            .then(response => this.devices = response.data)
            .finally(() => this.loading = false);

        this.loading = true;
    }
}
