import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import MenuButton from '@/components/MenuButton/MenuButton.vue';
import i18n from '@/i18n';

@Component({
    name: 'Modal',
    components: {
        MenuButton
    }
})
export default class Modal extends Vue {
    @Prop( {default: 'default'}) size!: 'default'|'large'|'fullscreen';
    @Prop() modalTitle!: string;
    @Prop({default: false}) noPadding!: boolean;
    @Prop({default: false}) usePrimaryButtonAsMenuButton!: boolean;
    @Prop({default: false}) persistent!: boolean;
    @Prop({default: false}) displayOpenButton!: boolean;
    @Prop({ default: i18n.t('Global.Modal.Buttons.Save').toString() }) primaryActionButtonText!: string;
    @Prop({ default: i18n.t('Global.Modal.Buttons.Close').toString()}) secondaryActionButtonText!: string;
    @Prop({default: false}) hideDefaultActions!: boolean;
    @Prop({default: false}) loading!: boolean;
    @Prop({default: 0}) steps!: number;
    @Prop({default: false}) useStepper!: boolean;
    @Prop({}) modelName!: string;
    @Prop( {default: false}) hideSaveButton!: boolean;
    @Prop( {default: true}) nextStepEnabled!: boolean;
    @Prop({default: 1}) step!: number;
    @Prop( {default: false}) showPreCloseConfirm!: boolean;
    @Prop({ default: "Confirm close modal title"}) preCloseConfirmModalTitle!: string;
    @Prop({ default: "Confirm close modal body"}) preCloseConfirmModalBody!: string;
    @Prop({ default: true}) primaryButtonEnabled!: boolean;
    @namespace('application').Action showNotification: any;
    public visible: boolean = false;
    public preCloseModalVisible: boolean = false;
    public currentStep = 1;

    @Watch('visible')
    private onVisibilityChange() {
        this.$forceUpdate();
    }

    @Watch('step', { immediate: true, deep: true })
    private onStepEdit() {
        if (this.step !== 0) {
            this.currentStep = this.step;
        }
    }

    /**
     * Toggle popup visible / hidden
     */
    public toggle(): void {
        if (this.showPreCloseConfirm && this.visible) {
            this.preCloseModalVisible = true;
        }
        else {
            this.visible && this.$emit('close');

            this.visible = !this.visible;
        }
    }

    public closeModal(): void {
        this.preCloseModalVisible = false;
        this.visible && this.$emit('close');
        this.visible = !this.visible;
    }

    public get isTabletPortrait(): boolean {
        return window.matchMedia("(orientation: portrait)").matches;
    }

    public get showSaveButtonModal(): boolean {
        if (this.steps > 0 && this.currentStep === this.steps) return true;

        if (this.steps === 0) return true;

        return false;
    }

    public nextStep(): void {
        this.$emit('pressedNextStep');

        this.currentStep = this.currentStep + 1;
    }

    public previousStep(): void {
        this.currentStep = this.currentStep - 1;
    }

    private get getModalTitle(): string {
        if (this.modalTitle !== undefined) {
            return this.modalTitle.toUpperCase();
        }
        return "";
    }

    public get isMobile(): boolean {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

    public clickSaveButton(close: boolean): void {
        this.$emit('save', close);
    }

    /**
     * Defines the modal's width
     */
    public get getModalWidth(): string {
        switch (this.size) {
            case 'default':
                return 600 + 'px';

            case 'large':
                return 960 + 'px';

            case 'fullscreen':
                return 'inherit';
        }
    }
}
