import Component from 'vue-class-component';
import popup from '@/components/popup/popup.vue';
import PopupForm from '@/partials/PopupForm';
import ClientFormData from '@/Interfaces/ClientFormData';
import dayjs from 'dayjs';

@Component({
    components: { popup },
})
export default class ClientImportantNotesForm extends PopupForm<ClientFormData> {
    protected modelName = 'Client';
    protected endpoint = 'client-remarks';

    get birthDate(): string {
        return dayjs(this.formData.birthdate).format('DD-MM-YYYY');
    }
}
