import Component from 'vue-class-component';
import PaymentItemInterface from '@/components/PaymentScreen/interfaces/PaymentItemInterface';
import { StrRandom } from '@/utils/utils';
import apiClient from '@/apiClient';
import presenters from '@/components/OverviewTable/presenters';
import PriceField from '@/components/PriceField/PriceField.vue';
import PaymentItems from '@/components/PaymentScreen/components/PaymentItems';
import { Prop, Watch } from 'vue-property-decorator';
import PaymentItemPackage from '@/components/PaymentScreen/components/PaymentItem/components/package/PaymentItemPackage.vue';
import PaymentItemDiscount from '@/components/PaymentScreen/components/PaymentItem/components/discount/PaymentItemDiscount.vue';
import Vue from 'vue';
import ClientFormData from '@/Interfaces/ClientFormData';
import PaymentItemInsurance from '@/components/PaymentScreen/components/PaymentItem/components/insurance/PaymentItemInsurance.vue';
import PaymentItemTerm from '@/components/PaymentScreen/components/PaymentItem/components/term/PaymentItemTerm.vue';

@Component({
    name: 'PaymentItem',
    components: {
        PriceField,
        PaymentItemPackage,
        PaymentItemDiscount,
        PaymentItemInsurance,
        PaymentItemTerm,
    },
})
export default class PaymentItem extends PaymentItems {
    @Prop() private value!: Array<PaymentItemInterface>;
    @Prop() public client!: ClientFormData;
    @Prop() public locationId!: number;
    @Prop() public prefillData!: Array<any>;
    @Prop({ default: null }) countryId!: number | null;

    protected items: Array<PaymentItemInterface> = [];

    public resetItems() {
        this.itemLists.treatment = [];
        this.itemLists.product = [];
        this.itemLists.package = [];

        this.items = this.items.filter((item) => item.item !== 'treatment'
            && item.item !== 'product'
            && item.item !== 'package');
        this.$emit('input', this.items);
    }

    @Watch('countryId')
    onCountryIdChange(newValue: number | null, oldValue: number | null) {
        if ( newValue !== oldValue ) {
            this.resetItems();
        }
    }

    @Watch('locationId')
    onLocationIDChang(newValue: number | null, oldValue: number | null) {
        if ( newValue !== oldValue ) {
            this.resetItems();
        }
    }

    @Watch('items', { deep: true })
    onItemsChanged() {
        this.$emit('input', this.items);
    }

    @Watch('value')
    onValueChange() {
        Vue.set(this, 'items', this.value);
        if ( this.items.length === 0 ) this.addItem();
    }

    private blankItem = {
        item: null,
        type: null,
        amount: 1,
        price: 0,
        vue_id: StrRandom(),
        discount: [],
        packages: [],
        terms: [],
        insurance: [],
    };

    private amountLimit = {
        min: 1,
        max: 99,
    };

    private itemLists = {
        treatment: [],
        product: [],
        package: [],
        voucher: [],
    } as Record<string, Array<any>>;

    private loadingType = false;

    @Watch('prefillData')
    onPrefillDataChange() {
        this.handlePrefillData();
    }

    mounted() {
        this.addItem();

        this.handlePrefillData();
    }

    private handlePrefillData() {
        if ( this.prefillData?.length > 0 ) {
            Promise.all(this.prefillData.map(item => this.fetchItemListData(item.item))).then(() => {

                this.items = this.prefillData.map((item) => {
                    const blank = JSON.parse(JSON.stringify(this.blankItem));

                    return Object.assign(blank, {
                        item: item.item,
                        type: this.itemLists[ item.item ].find((record) => record.id === item.type.id),
                        vue_id: StrRandom(),
                    });
                });
            });
        }
    }

    get itemOptions(): Array<{ key: string; value: string }> {
        return Object.keys(this.itemLists).map((item: string) => ({
            key: item,
            value: this.$t(`CRM.Views.Payment.ItemOptions.${item}`).toString(),
        }));
    }

    private selectedItemChange(item: PaymentItemInterface): void {
        item.type = null;

        const key = item.item as string;

        this.fetchItemListData(key);
    }

    private fetchItemListData(key: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let requestUrl = '';

            switch ( key ) {
                case 'treatment':
                    requestUrl = `list/country/${this.countryId}/${this.locationId}/treatments`;
                    break;
                case 'product':
                    requestUrl = `list/country/${this.countryId}/${this.locationId}/products?filters[withoutVoucher]=true`;
                    break;
                case 'package':
                    requestUrl = `list/country/${this.countryId}/${this.locationId}/packages?filters[package]=true`;
                    break;
                case 'voucher':
                    requestUrl = `list/country/${this.countryId}/${this.locationId}/products?filters[voucher]=true`;
                    break;
            }

            this.loadingType = true;
            apiClient.get(requestUrl)
                .then((response) => {
                    this.itemLists[ key ] = response.data.body;
                    resolve();
                })
                .catch(() => reject())
                .finally(() => this.loadingType = false);
        });
    }

    private getTypeKey(key: string): string {
        const keys: Record<string, string> = {
            treatment: 'name',
            product: 'name',
            package: 'treatment_name',
            voucher: 'name',
        };

        return keys[ key ];
    }

    private getTotalPrice(item: PaymentItemInterface): string {
        if ( item.discount.length === 1 && (item.terms.length > 0 && item.terms[ 0 ].id) ) {
            item.price = this.getTermPrice(item, true);
        } else {
            item.price = ((item.type?.price || 0) * item.amount);
        }
        return presenters.get('currency', item.price);
    }

    private getTermPrice(item: PaymentItemInterface, returnInt = false): any {
        item.amount = 1;

        const amountTreated = item.terms[ 0 ].amount_treated || 0;
        const amountTerms = item.terms[ 0 ].terms || -1;

        if ( amountTreated >= amountTerms ) {
            item.price = 0;
        } else {
            item.price = ((item.terms[ 0 ].price || 0) / (item.terms[ 0 ].terms || 1));
        }

        return returnInt ? item.price : presenters.get('currency', item.price);
    }

    private getTotalDiscountedPrice(item: PaymentItemInterface, returnInt = false): any {
        const calculation = item.price - item.discount[ 0 ].amount;

        return returnInt ? calculation : this.numberToPrice(calculation);
    }

    private numberToPrice(number: number): string {
        return presenters.get('currency', number);
    }

    private validateAmountLimit(item: PaymentItemInterface): void {
        if ( item.amount < this.amountLimit.min ) Object.assign(item, { amount: this.amountLimit.min });
        if ( item.amount > this.amountLimit.max ) Object.assign(item, { amount: this.amountLimit.max });

        this.$forceUpdate();
    }

    private addItem(): void {
        // Deep clone a new item
        const clone = JSON.parse(JSON.stringify(this.blankItem));

        // Set new id
        const newItem: PaymentItemInterface = Object.assign(clone, { vue_id: StrRandom() });

        this.items.push(newItem);
    }

    private removeItem(item: PaymentItemInterface) {
        const index = this.items.findIndex((paymentItem) => paymentItem.vue_id === item.vue_id);

        this.items.splice(index, 1);

        if ( this.items.length === 0 ) {
            this.addItem();
        }
    }

    public addDiscount(item: PaymentItemInterface) {
        item.discount.push({
            amount: 0,
            percentage: 0,
        });
    }

    private addInsurance(item: PaymentItemInterface) {
        item.insurance.push({
            id: 1,
        });
    }

    private addPackage(item: PaymentItemInterface) {
        Vue.set(item, 'packages', [ {
            id: null,
        } ]);
    }

    private addTerm(item: PaymentItemInterface) {
        Vue.set(item, 'terms', [ {
            id: null,
        } ]);

        if (item.terms.length && item.terms[0].amount_treated !== 0) {
            item.discount = [];
        }
    }

    private isFirstTerm(item: PaymentItemInterface) {
        if (!item.terms.length) return true;

        return item.terms[0].amount_treated === 0;
    }

    private checkIfNotExist(item: Record<string, any>, input: Array<string>): boolean {
        let returnValue = true;

        input.forEach((i: string) => {
            if ( item[ i ].length > 0 ) {
                returnValue = false;
                return false;
            }
        });
        return returnValue;
    }

    private hasSelectedTreatment(item: PaymentItemInterface): boolean {
        return item.item === 'treatment' && item.type !== null;
    }
}
