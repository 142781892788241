import Vue from 'vue';
import VueI18n, {LocaleMessages} from 'vue-i18n';
import dayjsPackage from 'dayjs';
import nlLocale from 'dayjs/locale/nl';
import IsoWeek from 'dayjs/plugin/isoWeek';
import isoWeeksInYear from 'dayjs/plugin/isoWeeksInYear';
import isLeapYear from 'dayjs/plugin/isLeapYear';
import updateLocale from 'dayjs/plugin/updateLocale';
import weekday from 'dayjs/plugin/weekday';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';


Vue.use(VueI18n)

const fallbackLocale = process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'nl';

function loadLocaleMessages (): LocaleMessages {
  const locales = require.context('@/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages: LocaleMessages = {}
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if ( matched && matched.length > 1 ) {
            const locale = matched[ 1 ];
            messages[ locale ] = locales(key);
        }
    });
    return messages;
}

const locale = window.localStorage.getItem('locale') ? window.localStorage.getItem('locale') : process.env.VUE_APP_I18N_LOCALE;

dayjsPackage.locale(nlLocale, { weekStart: 1 });
dayjsPackage.extend(IsoWeek);
dayjsPackage.extend(isoWeeksInYear);
dayjsPackage.extend(isLeapYear);
dayjsPackage.extend(updateLocale);
dayjsPackage.extend(utc);
dayjsPackage.extend(weekday);
dayjsPackage.extend(duration);

export default new VueI18n({
    locale: locale || 'nl',
    fallbackLocale: fallbackLocale,
    formatFallbackMessages: true,
    messages: loadLocaleMessages(),
    silentTranslationWarn: true,
});

export const dayjs = dayjsPackage.utc;
