import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Ref } from 'vue-property-decorator';
import TreatmentReportInterface from '@/Interfaces/TreatmentReportInterface';
import apiClient from '@/apiClient';
import i18n from '@/i18n';
import FitText from '@/components/FitText/FitText.vue';
import Popup from '@/components/popup/popup.vue';
import { default as PopupComponent } from '@/components/popup/popup';
import { namespace } from 'vuex-class';
import TreatmentReportPhotoComparisonComponent from '@/components/TreatmentReport/Components/TreatmentReportPhotoComparison/TreatmentReportPhotoComparisonComponent.vue';

declare interface HistoryData extends TreatmentReportInterface {
    visible: boolean;
    loading: boolean;
}

@Component({
    name: 'TreatmentReportHistory',
    components: {
        FitText,
        TreatmentReportPhotoComparisonComponent,
        Popup,
    },
})
export default class TreatmentReportHistory extends Vue {
    @Prop() data!: TreatmentReportInterface;
    @Ref() treatmentPhotosPopup!: PopupComponent;

    @namespace('reporthistory').Mutation('add') public addReportHistory: any;

    private openedPhotoComparison: boolean = false;
    private history = [] as Array<TreatmentReportHistory>;
    private historyData = {} as Record<number, any>;
    private loading: boolean = true;

    mounted() {
        apiClient.get(`event/${this.data.appointment.id}/treatment-report/history`).then(response => {
            this.history = response.data;
            this.loading = false;

            this.addReportHistory({ data: response.data });
        });
    }

    public openTreatmentPhotos(): void {
        this.closeTreatmentHistory();

        this.openedPhotoComparison = !this.openedPhotoComparison;
    }

    private getTreatmentDate(date: string): string {
        return new Date(date).toLocaleDateString(i18n.locale, {
            weekday: 'short',
            day: '2-digit',
            month: 'long',
            year: 'numeric',
        });
    }

    private getTreatmentTimeRange(from: string, till: string): string {
        const dateStart = new Date(from);
        const dateEnd = new Date(till);

        return [
            dateStart.toLocaleTimeString(i18n.locale, { hour: '2-digit', minute: '2-digit' }),
            dateEnd.toLocaleTimeString(i18n.locale, { hour: '2-digit', minute: '2-digit' }),
        ].join(' - ');
    }

    loadFull(id: number): void {
        if ( id in this.historyData ) {
            const obj = this.historyData[ id ];
            obj.visible = !obj.visible;
        } else {
            Vue.set(this.historyData, id, {
                loading: true,
                visible: true,
            });

            this.fetchFullItem(id);
        }
    }

    fetchFullItem(id: number): void {
        apiClient.get(`event/${id}/treatment-report`).then(response => {
            const obj = response.data;
            Object.assign(obj, { loading: false, visible: this.historyData[ id ].visible });

            Vue.set(this.historyData, id, obj);
        });
    }

    openReport(id: number) {
        this.closeTreatmentHistory();

        this.$emit('open', id);
    }

    closeTreatmentHistory() {
        this.$emit('closeTreatmentHistory');
    }
}
