import Component from 'vue-class-component';
import OverviewTable from '@/components/OverviewTable/OverviewTable.vue';
import IndexView from '@/partials/IndexView';
import DeviceTypeForm from '@/views/DatabaseModels/Components/DeviceTypes/Form/DeviceTypeForm.vue';

@Component({
    components: {
        OverviewTable,
        DeviceTypeForm,
    },
})
export default class DeviceTypeIndex extends IndexView<any> {
    protected endpoint = 'device-types/dto';
}