import Vue from 'vue';
import Component from 'vue-class-component';
import Wysiwyg from '@/components/Wysiwyg/Wysiwyg.vue';
import apiClient from '@/apiClient';
import i18n from '@/i18n';
import store from '@/store';
import {Prop, Watch} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import SignatureField from "@/components/SignatureField/SignatureField";
import ClientFormData from "@/Interfaces/ClientFormData";

@Component({
    name: "AgreementSignTemplate",
    components: {
        SignatureField,
        Wysiwyg
    }
})
export default class AgreementSignTemplate extends Vue {
    @Prop() client!: ClientFormData;
    public agreementsTemplates: Array<any> = [];
    public treatmentCategories: Array<any>  = [];
    public agreement_template_id: number|null = null;
    public treatment_category_id: number|null = null;
    public loadingTemplate: boolean = false;
    public report: string = "";
    public itemsLoading = {
        agreements_templates: false,
        treatment_categories: false,
    };
    public locales = [] as Array<any>;

    @namespace('mru').State('selectedAgreement') mruSelectedAgreement!: any;
    @namespace('mru').Mutation('set') setMru!: any;

    mounted() {
        this.fetch();

        if (this.mruSelectedAgreement !== undefined) {
            this.agreement_template_id = this.mruSelectedAgreement;
        }
    }

    @Watch('report')
    public reportUpdated(){
        this.$emit('reportUpdated', this.report)
    }

    @Watch('allFieldsValid', {immediate: true})
    public allFieldsValidChange(){
        this.$emit('allFieldsValidChange', this.allFieldsValid)
    }

    @Watch('agreement_template_id')
    public setDefaultAgreementTemplateId() {
        this.setMru({ key: 'selectedAgreement', value: this.agreement_template_id });
    }

    @Watch('treatment_category_id')
    public treatmentCategoryIdUpdated(value: number) {
        this.$emit('treatmentCategoryIdUpdated', value)
    }

    public fetch() {
        this.fetchAgreementsTemplates();
        this.fetchAppointmentCategories();
        this.fetchLocales();
    }

    public clear(): void {
        this.agreement_template_id = null;
        this.treatment_category_id = null;
        this.report = '';
    }

    private fetchLocales() {
        apiClient.get('locales').then(response => {
            this.locales = response.data;
        });
    }

    public get allFieldsValid(){
        return this.agreement_template_id != null
            && this.treatment_category_id != null
            && this.report !== ""
    }

    private fetchAgreementsTemplates(): void {
        this.itemsLoading.agreements_templates = true;

        apiClient.get('template-agreements/for-country').then(data => {
            this.agreementsTemplates = data.data.body;
        }).finally(() => {
            this.itemsLoading.agreements_templates = false;

            if (this.mruSelectedAgreement === null) {
                this.agreement_template_id = this.agreementsTemplates[0]?.id;
            }
        });
    }

    private fetchAppointmentCategories(): void {
        this.itemsLoading.treatment_categories = true;

        apiClient.get('treatment-categories').then(data => {
            this.treatmentCategories = data.data;
        }).finally(() => {
            this.itemsLoading.treatment_categories = false;
        });
    }

    private loadTemplate(): void {
        this.loadingTemplate = true;
        this.report = "";

        apiClient.get(`template-agreements/${this.agreement_template_id}`)
            .then(r => {
                let locale = this.locales.find((locale: any) => locale.id === this.client.locale_id)?.code ?? null;
                locale = locale ?? i18n.locale;

                if (r.data.translation.content[locale] === null
                    || r.data.translation.content[locale] === undefined
                    || r.data.content === undefined
                    || r.data.content === null) {
                    store.dispatch('application/showNotification', 'TreatmentReportTemplateNotMade');
                    this.report = "";
                    return;
                }

                this.report = r.data.translation.content[locale];

                store.dispatch('application/showNotification', 'TreatmentReportTemplateAdded');
            }).finally(() => {
            this.loadingTemplate = false;
        }).catch(() => {
            store.dispatch('application/showNotification', 'TreatmentReportTemplateNotMade');
        });
    }
}
