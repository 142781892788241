import { render, staticRenderFns } from "./PaymentItemDiscount.vue?vue&type=template&id=0e38e17c&scoped=true&"
import script from "./PaymentItemDiscount.ts?vue&type=script&lang=ts&"
export * from "./PaymentItemDiscount.ts?vue&type=script&lang=ts&"
import style0 from "./PaymentItemDiscount.scss?vue&type=style&index=0&id=0e38e17c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e38e17c",
  null
  
)

export default component.exports