import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Ref } from 'vue-property-decorator';

@Component({
    name: 'FitText',
})
export default class FitText extends Vue {
    @Prop({ default: null }) width!: string | null;

    @Ref() container!: HTMLElement;
    @Ref() test!: HTMLElement;

    private containerWidth = 0;
    private testWidth = 0;

    mounted() {
        this.$nextTick(() => {
            this.testWidth = this.test.clientWidth;
            this.containerWidth = this.container.clientWidth;
        });
    }
}
