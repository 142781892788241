import { render, staticRenderFns } from "./StatusIcon.vue?vue&type=template&id=1260c0da&scoped=true&"
import script from "./StatusIcon.ts?vue&type=script&lang=ts&"
export * from "./StatusIcon.ts?vue&type=script&lang=ts&"
import style0 from "./StatusIcon.scss?vue&type=style&index=0&id=1260c0da&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1260c0da",
  null
  
)

export default component.exports