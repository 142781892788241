import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
    name: 'MenuButton',
})
export default class MenuButton extends Vue {
    @Prop({ default: () => ({ class: 'primary' }) }) buttonProps!: Record<string, any>;
    @Prop({ default: false }) public readonly loading!: boolean;
    @Prop({ default: false }) public readonly disabled!: boolean;
    @Prop({ type: Boolean, default: false }) public readonly dense!: boolean;
}
