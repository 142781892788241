// Vue
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
// Project
import apiClient from '@/apiClient';
import axios, { AxiosResponse } from 'axios';
import { namespace } from 'vuex-class';

@Component({
    name: 'UserPicker',
})

export default class UserPicker extends Vue {
    @Prop() private readonly models!: Array<number>;
    @Prop() private readonly locationId!: number;
    @Prop() private readonly treatmentId!: number;

    @Ref() private autocomplete: any;

    @namespace('me').State roles: any;

    private users: Array<any> = [];
    public loading: boolean = false;

    private requestCancelToken: any;

    @Watch('models')
    private onModelsChange(): void { this.fetch(); }

    @Watch('locationId')
    private onLocationIdChange(): void { this.fetch(); }

    @Watch('treatmentId')
    private onTreatmentIdChange(): void { this.fetch(); }

    public get disabled(): boolean {
        return !this.locationId || !this.treatmentId || this.users.length === 0;
    }

    public get formLabel(): TranslateResult {
        return this.$t(this.users.length === 0 ? 'Global.FormLabel.PractitionersNull' : 'Global.FormLabel.Practitioner');
    }

    public get options(): Array<any> {
        return this.roles.some((role: any) => role.slug === 'physician') ? this.users :
            [ { id: null, name: this.$t('CRM.Views.AppointmentIndex.Forms.CreateAppointment.NoPreference') }, ...this.users ];
    }

    public mounted(): void {
        this.fetch();
    }

    private fetch(): void {
        if ( !this.locationId || !this.treatmentId ) return;

        try {
            this.requestCancelToken.cancel();
        } catch ( e ) {
            // dont do anything
        }

        this.requestCancelToken = axios.CancelToken.source();

        const params = {
            location_id: this.locationId,
            models: this.models,
            treatment_id: this.treatmentId,
        };

        apiClient.get('event/employees', { cancelToken: this.requestCancelToken.token, params })
            .then((response) => {
                this.onFetchSuccess(response);
                this.loading = false;
            }).catch((error) => {
            if ( !axios.isCancel(error) ) this.loading = false;
        });

        this.loading = true;
    }

    private onFetchSuccess(response: AxiosResponse): void {
        this.users = response.data;

        const user = this.users.find(i => i.id === this.autocomplete.internalValue);
        if ( !user ) this.autocomplete.setValue(null);
    }
}
