import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Ref } from 'vue-property-decorator';
import TreatmentReportInterface from '@/Interfaces/TreatmentReportInterface';
import StatusIcon from '@/components/StatusIcon/StatusIcon.vue';
import ClientImportantNotesForm from '@/views/ClientIndex/Forms/ClientImportantNotesForm/ClientImportantNotesForm.vue';
import ClientFormData from '@/Interfaces/ClientFormData';

@Component({
    name: 'Header',
    components: {
        StatusIcon,
        ClientImportantNotesForm,
    },
})
export default class TreatmentReportHeader extends Vue {
    @Prop() data!: TreatmentReportInterface;
    @Ref() remarkForm!: any;

    private canEdit = this.$store.getters['me/hasPermission']('treatment-report.update');

    editRemark() {
        if (this.canEdit) this.remarkForm.toggle({ ...this.data.client });
    }

    onRemarkUpdate(client: ClientFormData) {
        this.data.client = client;
    }

    openTreatmentHistory() {
        this.$emit('openTreatmentHistory');
    }
}
