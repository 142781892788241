import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import AppointmentEventData from '@/Interfaces/AppointmentEventData';
import TrafficLight from '@/components/TrafficLight/TrafficLight.vue';
import EventStatusService from '@/services/EventStatusService';

@Component({
    components: {
        TrafficLight,
    },
})
export default class EventStatusIcon extends Vue {
    @Prop() private data!: AppointmentEventData;
    @Prop({default: 15}) private size!: number;

    private eventStatusService = EventStatusService;
}
