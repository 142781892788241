import Vue from 'vue';
import Component from 'vue-class-component';
import TimeSlot from '@/components/TimeSlot/TimeSlot.vue';
import { Prop, Watch } from 'vue-property-decorator';
import dayjs from 'dayjs';

@Component({
    components: {
        TimeSlot,
    },
})

export default class BusinessHours extends Vue {

    @Prop() value!: Array<any>;

    public input: Array<any> = this.getInput();

    @Watch('input', { deep: true, immediate: true })
    private onInputChange(): void {
        this.$emit('input', this.input);
    }

    private getDefault(): Array<any> {
        const days = Array.from({ length: 7 }, (_, index) => index + 1);

        return days.map(weekday => {
            return new Object({
                active: weekday <= 5,
                time_from: dayjs().set('hour', 8).set('minute', 0).set('second', 0).toDate(),
                time_till: dayjs().set('hour', 22).set('minute', 0).set('second', 0).toDate(),
                weekday: weekday,
            });
        });
    }

    private getInput(): Array<any> {
        return this.value ? this.value.map(timetable => {
            return Object.assign(timetable, {
                time_from: new Date(timetable.time_from),
                time_till: new Date(timetable.time_till),
            });
        }).sort((a, b) => a.weekday - b.weekday) : this.getDefault();
    }
}
