import i18n from '@/i18n';

/**
 * Vuex state module for devices
 */
declare interface State {
    types: Record<string, string>;
}

const state = (): State => {
    const Api = i18n.messages.nl.Api as { DeviceTypes: {} };

    return {
        types: Api.DeviceTypes,
    };
};

export default {
    namespaced: true,
    state,
};
