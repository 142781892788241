import Vue from 'vue';
import VueRouter, {NavigationGuardNext, Route, RouteConfig} from 'vue-router';
import i18n from '@/i18n';
import EmployeeIndex from '@/views/EmployeeIndex/EmployeeIndex.vue';
import Dashboard from '@/views/Dashboard/Dashboard.vue';
import DeviceIndex from '@/views/DeviceIndex/DeviceIndex.vue';
import RoutePasstrough from '@/components/RoutePasstrough/RoutePasstrough.vue';
import LocationIndex from '@/views/LocationIndex/LocationIndex.vue';
import TreatmentIndex from '@/views/TreatmentIndex/TreatmentIndex.vue';
import ProductIndex from '@/views/ProductIndex/ProductIndex.vue';
import ScheduleIndex from '@/views/ScheduleIndex/ScheduleIndex.vue';
import ForgotPassword from '@/views/ForgotPassword/ForgotPassword.vue';
import PasswordReset from '@/views/PasswordReset/PasswordReset.vue';
import LoginForm from '@/views/LoginForm/LoginForm.vue';
import ClientDashboard from '@/views/ClientDashboard/ClientDashboard.vue';
import PackageIndex from '@/views/PackageIndex/PackageIndex.vue';
import PaymentIndex from '@/views/PaymentIndex/PaymentIndex.vue';
import DaySummaryIndex from '@/views/DaySummaryIndex/DaySummaryIndex.vue';
import store from '@/store';
import CalendarLocationIndex from '@/views/CalendarIndex/CalendarLocationIndex/CalendarLocationIndex.vue';
import CalendarEmployeeIndex from '@/views/CalendarIndex/CalendarEmployeeIndex/CalendarEmployeeIndex.vue';
import DatabaseModels from '@/views/DatabaseModels/DatabaseModels.vue';
import dayjs from 'dayjs';
import TemplateIndex from '@/views/TemplateIndex/TemplateIndex.vue';
import DesignSystem from '@/views/DesignSystem/DesignSystem.vue';
import EmailTranslations from "@/views/EmailTranslations/EmailTranslations.vue";
import ZvInsuranceNamesIndex from "@/views/ZvInsuranceNames/ZvInsuranceNamesIndex.vue";
import PriceLevelIndex from "@/views/PriceLevelIndex/PriceLevelIndex.vue";

Vue.use(VueRouter);

/**
 * NOTE: management children are sorted in Dutch alphabetic order (hardcoded)
 */
const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'CRM.Views.Dashboard.Title',
        component: Dashboard,
        meta: {
            icon: 'mdi-view-dashboard-outline',
        },
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
            icon: 'mdi-view-dashboard-outline',
            showInNavigation: false
        },
    },
    {
        path: '/login',
        name: 'CRM.Views.LoginForm.Title',
        component: LoginForm,
        meta: {
            preLogin: true,
            showInNavigation: false,
        },
    },

    {
        path: '/forgot-password/:email?',
        name: 'CRM.Views.ForgotPassword.Title' + ' ', // // Trailing space to quiet vue-router warning about duplicate route name.
        component: ForgotPassword,
        meta: {
            preLogin: true,
            showInNavigation: false,
        },
    },
    {
        path: '/password-resets/:token',
        name: 'CRM.Views.PasswordReset.Title',
        component: PasswordReset,
        meta: {
            preLogin: true,
            showInNavigation: false,
        },
    },
    {
        path: '/calendar',
        name: 'CRM.Views.CalendarIndex.Title',
        component: RoutePasstrough,
        meta: {
            icon: 'mdi-calendar-outline',
        },
        children: [
            {
                path: 'employee',
                name: 'CRM.Views.CalendarEmployeesIndex.Title',
                component: CalendarEmployeeIndex,
                meta: {
                    icon: 'mdi-calendar-account',
                    type: 'user',
                    permission: 'calendar.employee.read',
                },
            },
            {
                path: 'location',
                name: 'CRM.Views.CalendarLocationIndex.Title',
                component: CalendarLocationIndex,
                meta: {
                    icon: 'mdi-calendar-blank-outline',
                    type: 'location',
                    permission: 'calendar.location.read',
                },
            },
        ],
    },
    {
        path: '/calendar/new/:client_id',
        component: CalendarLocationIndex,
        meta: {
            showInNavigation: false,
            type: 'location',
        },
    },
    {
        path: '/calendar/repeat/:appointment_id',
        component: CalendarLocationIndex,
        meta: {
            showInNavigation: false,
            type: 'location',
        },
    },
    {
        path: '/calendar/edit/:appointment_id',
        component: CalendarLocationIndex,
        meta: {
            showInNavigation: false,
            type: 'location',
        },
    },
    {
        path: '/clients',
        name: 'CRM.Views.ClientDashboard.Title',
        component: ClientDashboard,
        meta: {
            icon: 'mdi-account-circle',
        },
    },
    {
        path: '/clients/:client_id?',
        component: ClientDashboard,
        meta: {
            showInNavigation: false,
        },
    },
    {
        path: '/clients/:client_id?/:tab_id?',
        component: ClientDashboard,
        meta: {
            showInNavigation: false,
        },
    },
    {
        path: '/payment',
        name: 'CRM.Views.Payment.MenuTitle',
        component: PaymentIndex,
        meta: {
            icon: 'mdi-shopping-outline',
            permission: 'checkout.read',
        },
    },
    {
        path: '/schedule',
        name: 'CRM.Views.ScheduleIndex.Title',
        component: ScheduleIndex,
        meta: {
            icon: 'mdi-clock-time-four-outline',
            permission: 'schedule.read',
        },
    },
    {
        path: '/day-summary',
        name: 'CRM.Views.DaySummaryIndex.Title',
        component: DaySummaryIndex,
        meta: {
            icon: 'receipt_long',
            permission: 'management.day-summary',
        },
    },
    {
        path: '/management',
        name: 'Global.RouteGroups.Management',
        component: RoutePasstrough,
        meta: {
            icon: 'mdi-cog-outline',
        },
        children: [
            {
                path: 'locations',
                name: 'CRM.Views.LocationIndex.Title',
                component: LocationIndex,
                meta: {
                    icon: 'mdi-map-marker-outline',
                    permission: 'management.location',
                },
            },
            {
                path: 'employees',
                name: 'CRM.Views.EmployeeIndex.Title',
                component: EmployeeIndex,
                meta: {
                    icon: 'mdi-account-group-outline',
                    permission: 'management.user',
                },
            },
            {
                path: 'devices',
                name: 'CRM.Views.DeviceIndex.Title',
                component: DeviceIndex,
                meta: {
                    icon: 'mdi-hair-dryer-outline',
                    permission: 'management.device',
                },
            },
            {
                path: 'treatments',
                name: 'CRM.Views.TreatmentIndex.Title',
                component: TreatmentIndex,
                meta: {
                    icon: 'mdi-face-woman-shimmer-outline',
                    permission: 'management.treatment',
                },
            },
            {
                path: 'zv_insurances',
                name: 'CRM.Views.ZvInsuranceNamesIndex.Title',
                component: ZvInsuranceNamesIndex,
                meta: {
                    icon: 'mdi-hand-heart-outline',
                    permission: 'zv_insurance.read',
                },
            },
            {
                path: 'products',
                name: 'CRM.Views.ProductIndex.Title',
                component: ProductIndex,
                meta: {
                    icon: 'mdi-tag-outline',
                    permission: 'management.product',
                },
            },
            // TODO: Temporarily removed in CT-963
            // {
            //     path: 'clients',
            //     name: 'CRM.Views.ClientIndex.Title',
            //     component: ClientIndex,
            //     meta: {
            //         icon: 'mdi-account-multiple-outline',
            //         permission: 'management.client',
            //     },
            // },
            {
                path: 'packages',
                name: 'CRM.Views.PackageIndex.Title',
                component: PackageIndex,
                meta: {
                    icon: 'mdi-package-variant-closed',
                    permission: 'management.package',
                },
            },

            {
                path: 'price_levels',
                name: 'CRM.Views.PriceLevelIndex.Title',
                component: PriceLevelIndex,
                meta: {
                    icon: 'mdi-currency-eur',
                    permission: 'admin.only',
                },
            },
            // TODO: Temporarily removed in CT-963
            // {
            //     path: 'appointments',
            //     name: 'CRM.Views.AppointmentIndex.Title',
            //     component: AppointmentIndex,
            //     meta: {
            //         icon: 'mdi-calendar-edit',
            //         permission: 'management.appointment',
            //     },
            // },
            {
                path: 'templates/:tab_id?',
                name: 'CRM.Views.TemplateIndex.Title',
                component: TemplateIndex,
                meta: {
                    icon: 'mdi-clipboard-edit-outline',
                    permission: 'management.templates',
                },
            },
            // TODO: Temporarily removed in CT-963
            // {
            //     path: 'sold-packages',
            //     name: 'CRM.Views.SoldPackageIndex.Title',
            //     component: SoldPackageIndex,
            //     meta: {
            //         icon: 'mdi-package-variant-closed',
            //         permission: 'management.package_sale',
            //     },
            // },
            {
                path: 'database-models',
                name: 'CRM.Misc.DatabaseModels.Title',
                component: DatabaseModels,
                meta: {
                    icon: 'mdi-database-cog-outline',
                    permission: 'admin.only',
                },
            },
            {
                path: 'email-translations',
                name: 'Emails',
                component: EmailTranslations,
                meta: {
                    icon: 'mdi-email-edit-outline',
                    permission: 'admin.only',
                },
            },
            {
                path: 'design-system',
                name: 'CRM.Misc.DesignSystem.Title',
                component: DesignSystem,
                meta: {
                    icon: 'mdi-pencil-ruler',
                    permission: 'admin.only',
                    showInNavigation: process.env.NODE_ENV !== 'production',
                },
            },
        ],
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

const DEFAULT_TITLE = window.__env.DEFAULT_TITLE ?? '';

router.beforeEach((to: Route, from: Route, next: NavigationGuardNext) => {

    dayjs.updateLocale(i18n.locale, {weekStart: 1});

    const hasPermission = (to.meta && 'permission' in to.meta) ? store.getters[ 'me/hasPermission' ](to.meta?.permission) : true;

    Vue.nextTick(() => {
        document.title = to.name ? [ i18n.t(to.name.trim()), DEFAULT_TITLE ].join(' | ') : DEFAULT_TITLE;
    });

    if ( hasPermission ) {
        return next();
    } else {
        return next('/');
    }
});

export default router;
