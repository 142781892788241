import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { TranslationMap } from '@/Interfaces/TranslationMap';
import presenters from '@/components/OverviewTable/presenters';
import { DataTableHeader } from 'vuetify';

Vue.config.ignoredElements.push('vue-table-filters');

declare interface FilterItem {
    key: string | number;
    value: string;
}

interface FiltersDataTableHeader extends DataTableHeader {
    type: string;
    value: string;
}

@Component
export default class TableFilters extends Vue {
    private unfilteredData = [];
    public selectedFilters = {} as Record<string, any>;
    public availableFilters = {} as Record<string, Array<FilterItem>>;
    public amountSelected = 0;

    @Prop() filters!: Array<string>;
    @Prop() data!: Array<Record<string, any>>;
    @Prop() translationPath!: string;
    @Prop() translationMap!: Array<TranslationMap>;
    @Prop() headers!: Array<FiltersDataTableHeader>;

    @Watch('data', { immediate: true, deep: true })
    dataChanged() {
        if (!this.data.length) return false;

        // Deep clone data
        this.unfilteredData = JSON.parse(JSON.stringify(this.data));

        // Get available filters
        this.getAvailableFilters();

        Object.entries(this.selectedFilters).forEach((values, key) => {
            this.selectedFilters[ key ] = values.filter(value => this.availableFilters[ key ]?.find(filter => filter === value));
        });

        this.apply();
    }

    translateResult(key: string, value: string): string {
        const map = this.translationMap as Array<TranslationMap>;
        const path = map.find((item) => item.key === key)?.path;

        return this.$t(`${path}.${value}`).toString();
    }

    /**
     * Get available filter list
     */
    getAvailableFilters() {
        if ( !this.filters ) return;

        const tmpFilters = this.filters as Array<string>;

        tmpFilters.forEach((key: string) => {
            this.availableFilters[ key ] = this.unfilteredData.map((item) => item[ key ])
                .flat()
                .filter((item, index, self) => self.indexOf(item) === index)
                .map((item: string) => ({ key: item, value: item }));

            const translationMap = this.translationMap as Array<TranslationMap>;
            const translationKeys = translationMap.map((item) => {
                return item.key;
            });

            if ( translationKeys.includes(key) ) {
                this.availableFilters[ key ] = this.availableFilters[ key ].map(item => ({
                    key: item.key,
                    value: this.translateResult(key, item.value),
                }));
            }

            const header = this.headers?.find((header) => header.value === key);
            if (header) {
                Object.assign(header, { type: header?.type === 'boolean' ? 'booleanString' : header?.type });

                this.availableFilters[ key ].map((item: { key: string | number; value: string }) =>
                    Object.assign(item, { value: presenters.get(key, item.value, header?.type) }));
            }

            if ( key === 'deactivated_at' ) {
                this.availableFilters[ key ] = [
                    {
                        key: 'active',
                        value: this.$t('Global.Status.Active').toString(),
                    },
                    {
                        key: 'inactive',
                        value: this.$t('Global.Status.Inactive').toString(),
                    },
                ];
            }
        });
    }

    /**
     * Apply the filters
     */
    apply() {
        //deep clone data
        const tmpData = JSON.parse(JSON.stringify(this.unfilteredData));
        const returnData = [] as Array<any>;
        const selectedFilters = this.getSelectedFiltersValue();

        this.amountSelected = Object.keys(selectedFilters).reduce((acc: number, value: string) => {
            return acc += selectedFilters[ value ].length;
        }, 0);

        this.$emit('input', this.amountSelected);

        if ( Object.keys(selectedFilters).length === 0 ) {
            this.$emit('apply', this.unfilteredData);
            return;
        }

        // Filter on regular data
        Object.keys(selectedFilters).forEach((filterKey: string) => {
            selectedFilters[ filterKey ].forEach((filterValue) => {
                if ( filterKey !== 'deactivated_at' ) {
                    const intermediate = tmpData.filter((dataRecord: any) => {
                        if ( typeof dataRecord[ filterKey ] === 'object' && !!dataRecord[ filterKey ] ) {
                            return dataRecord[ filterKey ].includes(filterValue);
                        } else {
                            return dataRecord[ filterKey ] === filterValue;
                        }
                    });

                    returnData.push(...intermediate);
                }
            });
        });

        // Filter on status
        if ( Object.keys(selectedFilters).includes('deactivated_at') ) {
            selectedFilters.deactivated_at.forEach((status: string) => {
                const intermediate = tmpData.filter((dataRecord: any) => {
                    if ( status === 'active' ) {
                        return !dataRecord.deactivated_at;
                    }
                    if ( status === 'inactive' ) {
                        return !!dataRecord.deactivated_at;
                    }
                });

                returnData.push(...intermediate);
            });
        }

        this.$emit('apply', returnData.filter((value, index, self) => self.indexOf(value) === index));
    }

    /**
     * Transforms list of indexes to properly formatted values
     */
    private getSelectedFiltersValue(): Record<string, Array<string>> {
        const selectedFilters = {} as any;

        Object.keys(this.selectedFilters).forEach((key: string) => {
            if ( this.selectedFilters[ key ].length > 0 ) {
                selectedFilters[ key ] = this.availableFilters[ key ]
                    .filter((value, index) => this.selectedFilters[ key ].includes(index))
                    .map((item) => item.key);
            }
        });

        return selectedFilters;
    }

    /**
     * Deselect all options
     */
    public deselectAll() {
        this.selectedFilters = {};
        this.apply();
    }

    /**
     * Emit close event
     */
    public close() {
        this.$emit('close');
    }
}
