/**
 * Documentation
 * https://louismazel.github.io/maz-ui/documentation/maz-phone-number-input
 */
import 'maz-ui/lib/css/index.css';
import './PhoneNumberInput.scss';
import Vue from 'vue';
import Component from 'vue-class-component';
import { MazPhoneNumberInput } from 'maz-ui';
import { Prop, Watch } from 'vue-property-decorator';


declare interface MazPhoneNumber {
    countryCode: string;
    phoneNumber: string | null;
    isValid: boolean;
    countryCallingCode?: string;
    nationalNumber?: string;
    formatInternational?: string;
    formatNational?: string;
    uri?: string;
    e164?: string;
}

declare interface Translations {
    countrySelectorLabel: string;
    countrySelectorError: string;
    phoneNumberLabel: string;
    example: string;
}

@Component({
    components: {
        MazPhoneNumberInput,
    },
})

export default class PhoneNumberInput extends Vue {
    @Prop() value!: string;
    @Prop({ default: false, type: Boolean }) required!: boolean;
    @Prop({ default: null }) translations!: null | Translations;
    @Prop({ default: 'NL' }) defaultCountryCode!: null | string;

    public countryCode = 'NL';
    public mutatableVariable: string | null = this.value || null;
    public defaultNumber: string | null = null;
    public data: null | MazPhoneNumber = null;
    public isValid = true;

    updatePhoneNumber(mazPhone: MazPhoneNumber) {
        this.data = mazPhone;

        this.$emit('input', mazPhone.isValid ? mazPhone.e164 : null); // .e164 is the correct formatted phone number
    }

    mounted() {
        /**
         * There seems to be an issue with MazUI component where it will not
         * update the defaultCountryCode if its mutatable and set immediately.
         *
         * This workaround should should fix that
         */
        this.$nextTick(() => {
            this.countryCode = this.defaultCountryCode ?? 'NL';
        });

    }

    @Watch('data')
    onDataChange() {
        if ( this.data?.isValid ) {
            this.defaultNumber = this.data.nationalNumber || null;
        }
    }

    @Watch('defaultCountryCode')
    onDefaultCountryCodeChange() {
        this.countryCode = this.defaultCountryCode ?? 'NL';
    }

    fieldOnBlur() {
        if ( !this.required ) {
            this.isValid = true;

            return;
        }

        this.isValid = (this.data?.isValid || false);
    }

    validate() {
        if ( this.required ) {
            this.fieldOnBlur();
        }
    }

}