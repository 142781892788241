import { render, staticRenderFns } from "./ClientImportantNotes.vue?vue&type=template&id=40789465&scoped=true&"
import script from "./ClientImportantNotes.ts?vue&type=script&lang=ts&"
export * from "./ClientImportantNotes.ts?vue&type=script&lang=ts&"
import style0 from "./ClientImportantNotes.scss?vue&type=style&index=0&id=40789465&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40789465",
  null
  
)

export default component.exports