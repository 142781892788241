import Component from 'vue-class-component';
import SignatureParent from '@/components/SignatureParent/SignatureParent.vue';
import { default as SignatureParentComponent } from '@/components/SignatureParent/SignatureParent';
import Modal from '@/components/Modal/Modal.vue';
import {Prop, Ref, Watch} from "vue-property-decorator";
import PopupForm from '@/partials/PopupForm';

@Component({
    name: "signature-field",
    components: {
        Modal,
        SignatureParent
    }
})
export default class SignatureField extends PopupForm<any> {
    @Ref() signatureParent!: SignatureParentComponent;
    @Prop() signatureId?: number|null;
    @Prop({ default: 'client'}) type!: string;
    @Prop() authorId?: number;
    public signature: string|null = '';
    protected endpoint: string = "signatures";
    protected modelName: string = "Signature";

    @Watch('signatureId', { immediate: true, deep: true })
    public onSignatureChange(value: number|null): void {
        this.signature = this.getSignatureUrl(value);
    }

    public clear(): void {
        this.signature = '';
        if (this.signatureParent) {
            this.signatureParent.makeEmpty();
        }
    }

    private updateSignatureData() {
        this.signatureParent.save();

        Object.assign(this.formData, {
            'signature': this.signatureParent.signaturePad.saveSignature().data,
            'type': this.type,
            'author_id': this.authorId
        });

        this.submit(true);
    }

    private getSignatureUrl(signatureId: number|null) {
        return [ window.__env.API_URL, 'signatures', signatureId ].join('/');
    }
}
