import AppointmentEvent from '@/views/CalendarIndex/Components/AppointmentEvent.vue';
import AppointmentEventData from '@/Interfaces/AppointmentEventData';
import Component from 'vue-class-component';
import MenuButton from '@/components/MenuButton/MenuButton.vue';
import PaymentScreen from '@/components/PaymentScreen/PaymentScreen.vue';
import Popup from '@/components/popup/popup.vue';
import RoomFormData from '@/Interfaces/RoomFormData';
import TreatmentReport from '@/components/TreatmentReport/TreatmentReport.vue';
import TreatmentReportPopup from '@/components/TreatmentReport/Components/Popup/TreatmentReportPopup.vue';
import Vue from 'vue';
import apiClient from '@/apiClient';
import dayjs from 'dayjs';
import i18n from '@/i18n';
import { AxiosResponse } from 'axios';
import { Prop, Ref } from 'vue-property-decorator';
import { default as PaymentScreenComponent } from '@/components/PaymentScreen/PaymentScreen';
import { default as PopupComponent } from '@/components/popup/popup';
import { default as TreatmentReportComponent } from '@/components/TreatmentReport/TreatmentReport';
import { default as TreatmentReportPopupComponent } from '@/components/TreatmentReport/Components/Popup/TreatmentReportPopup';
import { formatTimeRange } from '@/utils/utils';
import { namespace } from 'vuex-class';

@Component({
    components: { AppointmentEvent, Popup, TreatmentReport, MenuButton, TreatmentReportPopup, PaymentScreen },
})

export default class Calendar extends Vue {
    @Prop() appointment!: AppointmentEventData;
    @Ref() cancelPopup!: PopupComponent;
    @Ref() paymentPopup!: PopupComponent;
    @Ref() paymentScreen!: PaymentScreenComponent;
    @Ref() treatmentReportPopup!: TreatmentReportPopupComponent;
    @Ref() treatmentReport!: TreatmentReportComponent;
    @Prop() value!: any;

    @Prop({ default: () => [] }) appointments!: Array<AppointmentEventData>;
    @Prop({ default: 0 }) public activeRoom!: number;
    @Prop({ default: () => [] }) rooms!: Array<RoomFormData>;
    @Prop({ default: () => [] }) weekFormat!: Array<RoomFormData>;
    @Prop({ default: () => [] }) weekdays!: Array<number>;
    @Prop({ default: () => [] }) public locations!: Array<any>;
    @Prop({ default: 'week' }) type!: string;
    @Prop({ default: false }) isSwitchCalendar!: boolean;

    @Prop() overwriteStartDateTime!: Date;
    @Prop() overwriteEndDateTime!: Date;

    @namespace('application').Action showNotification: any;
    @namespace('locations').Getter('get') locationsState!: any;

    public formatTimeRange = formatTimeRange;
    public intervalHeight: number = 36;
    public intervalMinutes: number = 30;
    public radius: number = 1;
    public ready: boolean = false;

    public get calendar(): any {
        return this.ready ? this.$refs.calendar : null;
    }

    public getCalendarType(): string {
        const calendarType = this.$route.meta?.type;

        return calendarType == 'location' ? 'location' : 'user';
    }

    public getActiveLocation(currentDate: Date): string {
        const date = dayjs(currentDate).format('YYYY-MM-DD');
        return this.locations.find((data: any) => {
            return data.date === date;
        })?.name ?? '-';
    }

    public get closedString(): string {
        return this.getCalendarType() === 'location'
            ? this.$t('CRM.Views.CalendarIndex.Closed').toString()
            : this.$t('CRM.Views.CalendarIndex.NotWorking').toString();
    }

    public openingTimeString(time_from: string, time_till: string): string {
        const timeFromDate = dayjs(new Date(time_from));
        const timeTillDate = dayjs(new Date(time_till));

        const timeDifference = `(${timeTillDate.diff(timeFromDate, 'minutes') / 60} ${this.$t('Global.Times.Hours').toString()})`;

        return this.getCalendarType() === 'location'
            ? formatTimeRange(time_from, time_till)
            : formatTimeRange(time_from, time_till) + ' ' + timeDifference;
    }

    public get categories(): Array<any> {
        return this.rooms.map(room => {
            if ( 'schedule' in room ) {
                return Object.assign(room, {
                    close: dayjs(room.schedule?.active ? room.schedule.time_till : '2020-01-01 12:00:00').toDate(),
                    open: dayjs(room.schedule?.active ? room.schedule.time_from : '2020-01-01 12:00:00').toDate(),
                });
            }

            if ( 'schedules' in room ) {
                const openingTimes = room.schedules?.map((schedule) => {
                    return dayjs(schedule.time_from);
                });


                const closingTimes = room.schedules?.map((schedule) => {
                    return dayjs(schedule.time_till);
                });

                const earliestOpeningTime = openingTimes && openingTimes.length > 0 ? openingTimes?.reduce((a, b) => {
                    const dummyDateA = dayjs().set('hour', a.hour()).set('minute', a.minute()).set('second', a.second());
                    const dummyDateB = dayjs().set('hour', b.hour()).set('minute', b.minute()).set('second', b.second());

                    return dummyDateA.isBefore(dummyDateB, 'hour') ? a : b;

                }) : dayjs().hour(0);

                const latestClosingTime = closingTimes && closingTimes.length > 0 ? closingTimes?.reduce((a, b) => {
                    const dummyDateA = dayjs().set('hour', a.hour()).set('minute', a.minute()).set('second', a.second());
                    const dummyDateB = dayjs().set('hour', b.hour()).set('minute', b.minute()).set('second', b.second());

                    return dummyDateB.isAfter(dummyDateA, 'hour') ? b : a;
                }) : dayjs().hour(0);

                return Object.assign(room, {
                    close: latestClosingTime?.toDate(),
                    open: earliestOpeningTime?.toDate(),
                });
            }

        });
    }

    public get date(): Date {
        return this.value.toDate();
    }

    public get end(): Date {
        return this.overwriteEndDateTime || this.categories.map(i => i.close).reduce((a, b) => a > b ? a : b);
    }

    public get events(): Array<any> {
        return this.appointments.map(appointment => {
            return {
                appointment: appointment,
                start: dayjs(appointment.datetime_from).toDate(),
                end: dayjs(appointment.datetime_till).toDate(),
                category: this.categories.find(i => i.id === appointment.room_id)?.name,
            };
        });
    }

    public isToday(date: any): string {
        this.$dayjs.extend(require('dayjs/plugin/isToday'));

        return this.$dayjs(date).isToday();
    }

    public getDateDay(date: any): string {
        return dayjs(date).format('D');
    }

    public getDateMonth(date: any): string {
        return dayjs(date).format('MMM');
    }

    public get firstInterval(): number {
        return (this.start.getMinutes() + (this.start.getHours() - this.radius) * 60) / this.intervalMinutes;
    }

    public get intervalCount(): number {
        return this.lastInterval - this.firstInterval + (this.end.getMinutes() !== 0 ? 1 : 0);
    }

    public get lastInterval(): number {
        return ((this.end.getHours() - this.start.getHours()) * 2) + 4;
        /*return (this.end.getMinutes() + (this.end.getHours() + this.radius) * 60) / this.intervalMinutes;*/
    }

    public get nowOffset(): string {
        return [ this.calendar ? this.calendar.timeToY(this.calendar.times.now) : -10, 'px' ].join('');
    }

    public get start(): Date {
        return this.overwriteStartDateTime || this.categories.map(i => i.open).reduce((a, b) => a > b ? a : b);
    }

    public mounted() {
        if (!this.locationsState.locations.length) {
            this.$store.dispatch('locations/fetch').finally(() => {
                this.ready = true;
            });
        }
        else {
            this.ready = true;
        }
    }

    public getClosed(from: string, till: string): object {
        const [ bottom, top ] = [ this.calendar?.timeToY(till), this.calendar?.timeToY(from) ];

        return new Object({
            height: [ bottom - top, 'px' ].join(''),
            top: `calc(100% + ${[ top, 'px' ].join('')})`,
        });
    }

    public getClosedAfter(datetime: Date): object {
        const from = [ datetime.getHours(), datetime.getMinutes() ].join(':');
        const till = [ this.end.getHours() + this.radius, this.end.getMinutes() ].join(':');

        return this.getClosed(from, till);
    }

    public getClosedBefore(datetime: Date): object {
        const from = [ this.start.getHours() - this.radius, this.start.getMinutes() ].join(':');
        const till = [ datetime.getHours(), datetime.getMinutes() ].join(':');

        return this.getClosed(from, till);
    }

    public getEventColor(event: any): string {
        return event.appointment.user?.color.background || '#2b2f39';
    }

    public getEventTextColor(event: any): string {
        return event.appointment.user?.color.text || '#ffffff';
    }

    public getShowIntervalLabel(interval: any): boolean {
        return interval.minute === 0;
    }

    public onCancel(data: any): void {
        this.cancelPopup.toggle(data);
    }

    public onCancelConfirm(data: any): void {
        apiClient.delete(`event/${data.id}`)
            .then(this.onCancelSuccess.bind(this))
            .finally(this.onUpdate.bind(this));
    }

    public onCancelSuccess(): void {
        this.showNotification('AppointmentSuccessfullyDeleted');
        this.cancelPopup.toggle();
    }

    public onEdit(data: any): void {
        this.$emit('edit', data);
    }

    public onNoShow(data: any): void {
        this.$emit('no-show', data);
    }

    public onRepeat(data: any): void {
        this.$emit('repeat', data);
    }

    public onRequest(date: any): void {
        this.$emit('request', date);
    }

    public onUpdate(): void {
        this.$emit('update');
    }

    private savedTreatmentReport(response: AxiosResponse<any>) {
        const appointment = this.appointments.find(appointment => appointment.id === response.data.appointment.id);

        this.$emit('updateAppointment', Object.assign(appointment, {
            report: true,
        }));
    }

    private openPayment(id: number) {
        this.paymentPopup.toggle(id);
    }

    private closePaymentPopup() {
        this.paymentPopup.close();
    }

    private paymentMade(eventId: number) {
        this.$emit('eventPaymentComplete', eventId);
    }

    private weekDayFormat(data: any) {
        const date = dayjs(data.date).toDate();

        return date.toLocaleDateString(i18n.locale, {
            weekday: 'long',
        });
    }

    private getActiveRoomTimeString(date: Date): string {
        const room = this.rooms.find(room => room.id === this.rooms[ this.activeRoom - 1 ].id);

        const searchDate = dayjs(date);

        const schedule = room?.schedules?.find(schedule => {
            const scheduleDate = dayjs(schedule.actual_time_from);

            return scheduleDate.isSame(searchDate, 'day');
        });

        if ( !schedule?.active ) {
            if ( this.getCalendarType() === 'user' ) {
                return this.$t('CRM.Views.CalendarIndex.NotWorking').toString();
            }
            return this.$t('CRM.Views.CalendarIndex.Closed').toString();
        }

        const timeFromDate: number = new Date(schedule.time_from).getHours();
        const timeTillDate: number = new Date(schedule.time_till).getHours();

        const timeDifference: string = `(${Math.abs(timeFromDate - timeTillDate).toString()} ${this.$t('Global.Times.Hours').toString()})`;

        return this.getCalendarType() === 'user'
            ? dayjs(this.getActiveRoomTime(date, 'open')).format('HH:mm') + ' - ' + dayjs(this.getActiveRoomTime(date, 'close')).format('HH:mm') + ' ' + timeDifference
            : dayjs(this.getActiveRoomTime(date, 'open')).format('HH:mm') + ' - ' + dayjs(this.getActiveRoomTime(date, 'close')).format('HH:mm');
    }

    private getActiveRoomTime(date: Date, type: string): Date {
        const room = this.rooms.find(room => room.id === this.rooms[ this.activeRoom - 1 ].id);
        const searchDate = dayjs(date);

        const schedule = room?.schedules?.find(schedule => {
            const scheduleDate = dayjs(schedule.actual_time_from);

            return scheduleDate.isSame(searchDate, 'day');
        });

        if ( type === 'open' ) {
            return schedule?.active ? dayjs(schedule?.time_from).utc().toDate() : dayjs().hour(0).utc().toDate();
        }

        if ( type === 'close' )
            return schedule?.active ? dayjs(schedule?.time_till).utc().toDate() : dayjs().hour(0).utc().toDate();

        return dayjs().utc().toDate();
    }
}
