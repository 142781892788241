import PaymentData from '@/Interfaces/PaymentData';
import Vue from 'vue';
import Component from 'vue-class-component';
import ClientPicker from '@/components/ClientPicker/ClientPicker.vue';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import apiClient from '@/apiClient';
import ClientFormData from '@/Interfaces/ClientFormData';
import ClientForm from '@/views/ClientIndex/Forms/ClientForm.vue';
import ClientPaymentDetails from '@/views/ClientDashboard/Components/ClientPaymentDetails/ClientPaymentDetails.vue';
import { default as ClientFormComponent } from '@/views/ClientIndex/Forms/ClientForm';
import { namespace } from 'vuex-class';

@Component({
    components: { ClientPicker, ClientForm, ClientPaymentDetails },
})
export default class ClientDetails extends Vue {
    @namespace('country').Getter getCountryById: any;
    @Ref() clientForm!: ClientFormComponent;
    @Prop() private paymentData!: PaymentData;
    @Prop() private prefillClientId!: number | null;
    @Prop() private value!: ClientFormData;

    private loading = false;
    private selectedClient = this.prefillClientId as number | null;
    private missingRequiredFields: Array<string> = [];

    public client: null | ClientFormData = null;

    @Watch('prefillClientId')
    onPrefillClientIdChange() {
        this.selectedClient = this.prefillClientId;
    }

    @Watch('selectedClient')
    onSelectedClientChange() {
        this.loading = true;
        this.client = null;
        apiClient.get(`payment-screen/client/${this.selectedClient}`).then(response => {
            this.client = response.data;
            this.$emit('input', response.data);
            this.checkRequiredFields();
        }).finally(() => {
            this.loading = false;
        });
    }

    editClient() {
        this.clientForm.toggle(this.value);
    }

    updateClient(data: ClientFormData): void {
        this.$emit('input', data);
        this.checkRequiredFields();
    }

    private checkRequiredFields() {
        const requiredFields = [ 'email', 'first_name', 'last_name' ];
        this.missingRequiredFields = [];

        requiredFields.forEach((field: string) => {
            if ( this.client && (!this.client[ field ] || this.client[ field ] === '') ) {
                this.missingRequiredFields.push(this.$t(`CRM.Views.Payment.MissingClientFields.${field}`).toString());
            }
        });
        this.$emit('missingFields', this.missingRequiredFields.length > 0);
    }
}
