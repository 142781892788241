import AppointmentForm from '@/views/CalendarIndex/Forms/AppointmentForm.vue';
import AppointmentRaw from '@/views/AppointmentIndex/Forms/AppointmentForm.vue';
import Calendar from '@/views/CalendarIndex/Components/Calendar/Calendar.vue';
import ColorLegend from '@/views/CalendarIndex/Components/ColorLegend/ColorLegend.vue';
import Component from 'vue-class-component';
import LocationPicker from '@/components/LocationPicker/LocationPicker.vue';
import MenuButton from '@/components/MenuButton/MenuButton.vue';
import dayjs from 'dayjs';
import { Prop } from 'vue-property-decorator';
import LocationCalendarParent from '@/views/CalendarIndex/Components/LocationCalendarParent';
import CalendarPlanning from '@/views/CalendarIndex/Components/CalendarPlanning/CalendarPlanning.vue';
import { namespace } from 'vuex-class';

@Component({
    name: 'CalendarWeekView',
    components: {
        AppointmentForm,
        AppointmentRaw,
        LocationPicker,
        Calendar,
        ColorLegend,
        MenuButton,
        CalendarPlanning,
    },
})

export default class CalendarWeekView extends LocationCalendarParent {
    // Vuex
    @namespace('mru').State('user') user!: any;

    // Props
    @Prop({ default: undefined }) public activeRoom!: number | undefined;
    @Prop({ default: [] }) public weekdays!: Array<number>;

    // Public variables
    public dateWeekDays = [] as Array<any>;
    public currentWeekRange = '';
    public weeknumber = '';

    // Overwrite from super class
    fetch() {
        this.changeCalenderDate();
        this.$emit('fetch');
        this.currentRangeNotation();
    }

    // Mounted
    public mounted(): void {
        this.setWeekDays();
        this.currentRangeNotation();
    }

    // Getters & Setters
    get weekNumber(): number {
        return dayjs(this.date).isoWeek();
    }

    //Public methods
    public currentRangeNotation(): string {

        const firstDayOfTheWeek = this.date.startOf('week');
        const lastDayOfTheWeek = this.date.endOf('week');

        const firstMonthFormat = firstDayOfTheWeek.format('MMM') === lastDayOfTheWeek.format('MMM') ? '' : firstDayOfTheWeek.format(' MMM');
        const firstYearFormat = firstDayOfTheWeek.format('YYYY') === lastDayOfTheWeek.format('YYYY') ? '' : firstDayOfTheWeek.format(' YYYY');

        this.weeknumber = this.date.format('W');

        return this.currentWeekRange = firstDayOfTheWeek.format('DD') + firstMonthFormat + firstYearFormat + lastDayOfTheWeek.format(' - DD MMM, YYYY');
    }

    public getCalendarType(): string {
        const calendarType = this.$route.meta?.type;

        return calendarType == 'location' ? 'location' : 'user';
    }

    public travelWeek(amount: number): void {
        this.weekTravel = this.weekTravel + amount;
        this.setWeekDays();
    }

    public onChange(amount: number): void {
        this.$emit('change-week', amount);

        this.travelWeek(amount);

        this.$emit('fetch');
    }

    public onReset(): void {
        this.$emit('reset');
        this.resetWeekdays();
    }

    public changeActiveRoom(amount: number): void {
        this.$emit('change-active-room', amount);
    }

    // Private methods
    private resetWeekdays() {
        this.weekTravel = 0;
        this.setWeekDays();
    }

    private setWeekDays(): void {
        this.dateWeekDays = [];

        for ( let i = 0; i <= 6; i++ ) {
            const date = dayjs().startOf('week').add(i, 'day').add(this.weekTravel, 'week');

            this.dateWeekDays.push(date);
        }
    }
}
