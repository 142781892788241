import { render, staticRenderFns } from "./SignatureField.vue?vue&type=template&id=273630dc&scoped=true&"
import script from "./SignatureField.ts?vue&type=script&lang=ts&"
export * from "./SignatureField.ts?vue&type=script&lang=ts&"
import style0 from "./SignatureField.scss?vue&type=style&index=0&id=273630dc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "273630dc",
  null
  
)

export default component.exports