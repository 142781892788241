import Vue from 'vue';
import Component from 'vue-class-component';
import FormRules from '@/partials/FormRules';
import apiClient from '@/apiClient';
import { AxiosError } from 'axios';
import PasswordField from '@/components/PasswordField/PasswordField.vue';
import { namespace } from 'vuex-class';
import { Watch } from 'vue-property-decorator';

@Component({
    components: { PasswordField }
})
export default class PasswordReset extends Vue {
    public formRules = FormRules;
    public loading = false as boolean;
    public valid = false as boolean;
    public formData = { password: '' } as PasswordResetFormData;
    public tokenValid = true as boolean;
    private errorMessage = null as string | null;
    $refs!: {
        form: HTMLFormElement;
    }

    @namespace('me').Action fetch: any;
    @namespace('authentication').Action passLoginCheck: any;

    mounted(): void {
        this.formData.email = this.$route.query.email;
        this.formData.token = this.$route.params.token;

        this.validateToken();
    }

    validateToken(): void {
        apiClient.get(`/password-resets/${this.formData.token}`, { params: { email: this.formData.email } })
            .catch((response: AxiosError) => {
                if (response.response?.status === 422) {
                    this.tokenValid = false;
                }
            });
    }

    // Manually trigger validation when password changes, because otherwise the passwordConfirmation's
    // equality rule wouldn't rerun.
    @Watch('formData.password')
    validate(): void {
        this.$nextTick(() => {
            if(this.formData.passwordConfirmation)
                this.$refs.form.validate();
        });
    }

    submit(): void {
        this.loading = true;

        apiClient.put(`/password-resets/${this.formData.token}`, this.formData).then(() => {
            this.fetch();
            this.passLoginCheck();
            this.$router.push('/');
        }).catch((response: AxiosError) => {
            if (response.response?.status === 422) {
                this.errorMessage = this.$t('CRM.Views.PasswordReset.Errors.Expired').toString();
            }
        }).finally(() => {
            this.loading = false;
        });
    }
}
