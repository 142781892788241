import ClientDetails from '@/components/PaymentScreen/components/ClientDetails/ClientDetails.vue';
import ClientPicker from '@/components/ClientPicker/ClientPicker.vue';
import Component from 'vue-class-component';
import FormRules from '@/partials/FormRules';
import Location from '@/Interfaces/Location';
import LocationAutocomplete from '@/components/Autocompletes/LocationAutocomplete/LocationAutocomplete.vue';
import LocationPractitionersAutocomplete
    from '@/components/Autocompletes/LocationPractitionersAutocomplete/LocationPractitionersAutocomplete.vue';
import PaymentData from '@/Interfaces/PaymentData';
import PaymentItem from '@/components/PaymentScreen/components/PaymentItem/PaymentItem.vue';
import PaymentMethod, {
    default as PaymentMethodComponent
} from '@/components/PaymentScreen/components/PaymentMethod/PaymentMethod.vue';
import User from '@/Interfaces/User';
import Vue from 'vue';
import apiClient from '@/apiClient';
import store from '@/store';
import {Prop, Ref, Watch} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {valueConvertedToCurrency} from '@/utils/utils';
import EditableDatePicker from '@/components/EditableDatePicker/EditableDatePicker.vue';
import dayjs from 'dayjs';

declare interface FormData {
    appointment_id?: null;
    location_id?: number | null;
    practitioner_id: number | null;
    invoice_date: string | null;
    client: any;
    items: Array<any>;
    paymentMethods: Array<any>;
    send_notification: boolean;
}

declare interface PrefillData {
    location_id: number;
    practitioner_id: number;
    appointment_id: number;
    client: any;
    items: Array<any>;
}

@Component({
    name: 'Payment',
    components: {
        ClientPicker,
        LocationAutocomplete,
        LocationPractitionersAutocomplete,
        PaymentItem,
        PaymentMethod,
        ClientDetails,
        EditableDatePicker
    },
})
export default class PaymentScreen extends Vue {
    @namespace('application').State notifications: any;
    @namespace('country').Getter getCountryById: any;
    @namespace('me').State id: any;
    @namespace('paymentmethods').Action fetchPaymentMethods!: any;
    @namespace('paymentmethods').Getter getPaymentMethodBySlug!: CallableFunction;
    @namespace('mru').State('paymentScreenLocationId') mruLocation: any;
    @namespace('mru').State('paymentScreenEmployeeId') mruEmployee: any;
    @namespace('mru').Mutation('set') setMru!: any;

    @Prop() prefillData!: PrefillData;
    @Prop({ default: false }) showCancelButton!: boolean;
    @Prop({ default: null }) prefillEventId!: { data: number } | null;
    @namespace('me').State permissions: any;

    @Ref() public paymentMethod!: PaymentMethodComponent;

    public paymentData: PaymentData = {
        creditUsed: 0,
        packagesUsed: new Map<number, number>(),
        termsUsed: new Map<number, number>(),
    };
    public countryId: number | null = null;
    public location = null;
    public practitioner: number | null = null;
    public formRules = FormRules;
    public formData = {
        invoice_date: dayjs(Date.now()).format('YYYY-MM-DD'),
        send_notification: false,
        practitioner_id: null,
        client: null,
        items: [],
        paymentMethods: [],
    } as FormData;

    public editableDatePickerMaxDate: string = dayjs(Date.now()).format('YYYY-MM-DD');
    public editableDatePickerMinDate: string = dayjs(Date.now()).subtract(30, 'days').format('YYYY-MM-DD');

    private clientHasMissingDetails = false;
    private dataPrefilled = {} as PrefillData;
    private paymentWithTermDisabled = false;
    private prefillClientId: null | number = null;
    private storeLoading = false;
    private validationErrors: Array<string> = [];

    @Watch('formData.items', { deep: true })
    onFormDataItemsChange() {
        const hasPackage = this.formData.items.some(item => item.item === 'package');
        const hasPackageTerm = this.formData.items.some(item => item.terms.length > 0);

        this.paymentWithTermDisabled = false;

        this.setPaymentData();

        if ( hasPackage || hasPackageTerm ) {
            if ( this.formData.paymentMethods.some(method => method.method === this.getPaymentMethodBySlug('credit')?.id) && this.formData.paymentMethods.length > 1 ) {
                Vue.set(this.formData, 'paymentMethods', this.formData.paymentMethods.filter(method => method.method !== this.getPaymentMethodBySlug('credit')?.id));
            }
            this.paymentWithTermDisabled = true;
        }
    }

    @Watch('formData.paymentMethods', { deep: true })
    onFormDataPaymentMethodsChange() {
        const creditAdded = this.formData.paymentMethods.find(method => (method.method === this.getPaymentMethodBySlug('prepaid')?.id));

        if ( creditAdded ) {
            this.paymentData = {
                ...this.paymentData,
                creditUsed: creditAdded.amount,
            };
        }
    }

    onLocationModelChange(location: Location) {
        this.countryId = location?.country_id;
        this.setMru({ key: 'paymentScreenLocationId', value: this.formData.location_id });
    }

    onPractitionerIdChange() {
        this.setMru({ key: 'paymentScreenEmployeeId', value: this.formData.practitioner_id });
    }

    mounted() {
        this.fetchPaymentMethods();

        if ( this.prefillEventId ) {
            this.fetchEventData(this.prefillEventId?.data);
            this.convertAppointmentPayment(this.prefillEventId?.data);
        }

        this.dataPrefilled = this.prefillData;

        if (this.mruLocation && !this.prefillData.location_id) {
            this.formData.location_id = this.mruLocation;
        }

        if (this.mruLocation && this.mruEmployee && !this.prefillData.practitioner_id) {
            this.formData.practitioner_id = this.mruEmployee;
        }

        if ( 'id' in this.$router.currentRoute.params ) {
            const id = parseInt(this.$router.currentRoute.params.id);
            this.fetchEventData(id);
            this.convertAppointmentPayment(id);
        } else {
            if ( this.dataPrefilled?.client ) {
                this.prefillClientId = this.dataPrefilled?.client?.id;
            }

            if ( this.dataPrefilled?.appointment_id ) {
                Vue.set(this.formData, 'appointment_id', this.dataPrefilled?.appointment_id);
            }
        }
    }

    private fetchEventData(id: number) {
        apiClient.get(`event/${id}/treatment-report`).then(response => {
            this.dataPrefilled = {
                client: response.data.client,
                location_id: response.data.location.id,
                practitioner_id: response.data.practitioner.id,
                appointment_id: response.data.appointment.id,
                items: [
                    {
                        item: 'treatment',
                        type: {
                            id: response.data.treatment.id,
                        },
                    },
                ],
            };

            Vue.set(this, 'prefillClientId', this.dataPrefilled.client?.id);
            Vue.set(this.formData, 'appointment_id', this.dataPrefilled.appointment_id);
            Vue.set(this.formData, 'location_id', this.dataPrefilled.location_id);
            Vue.set(this.formData, 'practitioner_id', this.dataPrefilled.practitioner_id);
        });

    }

    private convertAppointmentPayment(appointmentId: number) {
        apiClient.post(`credit/convert/${appointmentId}`).then(response => {
            if ( response.data ) {
                const convertedAmount = valueConvertedToCurrency(response.data);
                this.$store.dispatch('application/showNotification', [ 'DownPaymentHasBeenConverted', { amount: convertedAmount } ]);
            }
        });
    }

    private setPaymentData(): void {
        // Separate packages and terms
        const packages = this.formData.items.filter(item => item.packages.length > 0).map(item => {return item.packages[ 0 ].id;});
        const terms = this.formData.items.filter(item => item.terms.length > 0).map(item => {return item.terms[ 0 ].package_sale_id;});

        // Map packages and terms to id and number of times used
        const packagesMap = packages.reduce((map, key) => map.set(key, (map.get(key) || 0) + 1), new Map());
        const termsMap = terms.reduce((map, key) => map.set(key, (map.get(key) || 0) + 1), new Map());

        this.paymentData = {
            ...this.paymentData,
            packagesUsed: packagesMap,
            termsUsed: termsMap,
        } as PaymentData;
    }

    locationsFetched(data: Array<Location>) {
        if ( this.dataPrefilled?.location_id ) {
            Vue.set(this.formData, 'location_id', this.dataPrefilled.location_id);
        } else if (this.mruLocation) {
            Vue.set(this.formData, 'location_id', this.mruLocation);
        } else {
            this.formData.location_id = data.find((item) => item.id === this.id)?.id as number;
        }

        this.$forceUpdate();

    }

    practitionersFetched(data: Array<User>) {
        if ( this.dataPrefilled?.practitioner_id ) {
            Vue.set(this.formData, 'practitioner_id', this.dataPrefilled.practitioner_id);
        } else if (this.mruLocation && this.mruEmployee) {
            Vue.set(this.formData, 'practitioner_id', this.mruEmployee);
        } else {
            this.formData.practitioner_id = data.find((item) => item.id === this.id)?.id as number;
        }

        this.$forceUpdate();

    }

    pay(type: string) {
        Vue.set(this, 'validationErrors', []);

        this.formData.send_notification = type === 'with-notification';

        const validation = this.validateData();
        if ( validation.length > 0 ) {
            this.validationErrors = validation;
        } else {
            this.storeLoading = true;
            apiClient.post('transactions', this.formData).then(() => {
                const notificationTranslationKey = type === 'with-notification' ? 'TransactionCompleteNotify' : 'TransactionComplete';
                store.dispatch('application/showNotification', notificationTranslationKey);

                this.paid();
            }).finally(() => {
                this.storeLoading = false;
            });
        }
    }

    private validateData(): Array<string> {
        const errors = [];

        if ( !this.formData.client ) {
            errors.push(this.$t('CRM.Views.Payment.Errors.client'));
        }

        if ( !this.formData.location_id ) {
            errors.push(this.$t('CRM.Views.Payment.Errors.location'));
        }

        if ( !this.formData.practitioner_id ) {
            errors.push(this.$t('CRM.Views.Payment.Errors.practitioner'));
        }

        if ( this.formData.items.some(item => !item.item || !item.type) ) {
            errors.push(this.$t('CRM.Views.Payment.Errors.items'));
        }

        if ( this.formData.paymentMethods.some(item => typeof item.method !== 'number') ) {
            errors.push(this.$t('CRM.Views.Payment.Errors.paymentMethod'));
        }

        if ( this.formData.paymentMethods.some(item => item.method === this.getPaymentMethodBySlug('prepaid')?.id && item.amount > this.formData.client.credit_balance) ) {
            errors.push(this.$t('CRM.Views.Payment.Errors.exceedsAvailableCredit'));
        }

        return errors.length > 0 ? errors.map(item => item.toString()) : [];
    }

    private close() {
        this.$emit('close');

        this.formData.items = [];
        this.formData.paymentMethods = [];

        this.$forceUpdate();
    }

    private paid() {
        this.$emit('paid', this.formData.appointment_id);
        this.close();
    }
}
