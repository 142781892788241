import Vue from 'vue';
import Component from 'vue-class-component';
import Popup from '@/components/popup/popup.vue';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import TreatmentReportInterface from '@/Interfaces/TreatmentReportInterface';
import { default as PopupComponent } from '@/components/popup/popup';
import TreatmentReportPhotoComparison from '@/components/TreatmentReport/Components/TreatmentReportPhotoComparison/Comparison/TreatmentReportPhotoComparison.vue';
import { namespace } from 'vuex-class';
import TreatmentReportPhotoComparisonSendPopup from '@/components/TreatmentReport/Components/TreatmentReportPhotoComparison/Popup/TreatmentReportPhotoComparisonSendPopup.vue';
import { default as TreatmentReportPhotoComparisonSendPopupComponent } from '@/components/TreatmentReport/Components/TreatmentReportPhotoComparison/Popup/TreatmentReportPhotoComparisonSendPopup';

@Component({
    name: 'TreatmentReportPhotoComparisonComponent',
    components: {
        TreatmentReportPhotoComparison,
        TreatmentReportPhotoComparisonSendPopup,
        Popup,
    },
})

export default class TreatmentReportPhotoComparisonComponent extends Vue {
    @Prop() history!: Array<TreatmentReportInterface>;
    @Prop() popupOpen!: boolean;
    @Prop() setImageId!: number;
    @Ref() treatmentPhotosComparePopup!: PopupComponent;
    @Ref() treatmentPhotosCompareSendPopup!: TreatmentReportPhotoComparisonSendPopupComponent;

    @namespace('reporthistory').Mutation('setLeftCompareImage') public setLeftCompareImage!: any;
    @namespace('reporthistory').Mutation('setRightCompareImage') public setRightCompareImage!: any;

    @Watch('popupOpen')
    public toggleComparePopup(): void {
        this.treatmentPhotosComparePopup.toggle();
    }

    public toggleSendPhotoPopup(): void {
        this.treatmentPhotosComparePopup.toggle();
    }

    public openSendPopup(): void{
        this.treatmentPhotosComparePopup.toggle();
        this.treatmentPhotosCompareSendPopup.treatmentSendPhotosPopup.toggle();
    }
}
