import PopupForm from '@/partials/PopupForm';
import popup from '@/components/popup/popup.vue';
import Component from 'vue-class-component';
import ClientFormData from '@/Interfaces/ClientFormData';
import { getName as GetCountryName } from 'i18n-iso-countries';
import Country from '@/Interfaces/Country';
import Gender from '@/Interfaces/Gender';
import { Ref, Watch } from 'vue-property-decorator';
import apiClient from '@/apiClient';
import i18n from '@/i18n';
import DateField from '@/components/DateField/DateField.vue';
import AgreementUpload from '@/views/ClientIndex/Forms/AgreementUpload/AgreementUpload.vue';
import DeactivateToggle from '@/components/DeactivateToggle/DeactivateToggle.vue';
import PhoneNumberInput from '@/components/PhoneNumberInput/PhoneNumberInput.vue';
import { default as PhoneNumberInputComponent } from '@/components/PhoneNumberInput/PhoneNumberInput';

@Component({
    components: {
        popup,
        DateField,
        DeactivateToggle,
        AgreementUpload,
        PhoneNumberInput,
    },
})

export default class ClientForm extends PopupForm<ClientFormData> {
    public birthdate = false;
    public countries = [] as Array<Country>;
    public genders = [] as Array<Gender>;
    public getCountryName = GetCountryName;
    public locales = [] as Array<any>;

    protected modelName = 'Client';
    protected endpoint = 'clients';

    public result = null;

    public phoneNumberCountryCode = this.countries.find((item: Country) => item.id === this.formData.country_id)?.code.toUpperCase() ?? (window.__env.DEFAULT_LOCALE || 'nl').toUpperCase();

    public itemsLoading = {
        countries: false,
        genders: false,
        locales: false,
    };

    @Ref() phoneInput!: PhoneNumberInputComponent;


    get nin_country(): Array<Country> {
        return this.countries.map((country: Country) => {
            return Object.assign(country, country.name);
        });
    }

    // Specification of NIN header for NL and BE
    get ninLable(): string {
        let ninName;

        this.formData.nin_country === this.netherlandsId ? ninName = 'BSN' :
            this.formData.nin_country === this.belgiumId ? ninName = this.$t('Global.FormLabel.RRN').toString() :
                ninName = ninName = this.$t('Global.FormLabel.Nin').toString();

        return ninName;
    }

    // Value length validation for NL nin and BE nin
    get ninLengthValidation(): number | null {
        let ninLength = 25;

        if ( this.formData.nin_country === this.netherlandsId ) {
            ninLength = 9;
            this.formatNin(ninLength);
        } else if ( this.formData.nin_country === this.belgiumId ) {
            ninLength = 11;
            this.formatNin(ninLength);
        }

        return ninLength;
    }

    public setPhoneNumberCountryCode(countryId: number){
        this.phoneNumberCountryCode = this.countries.find((item: Country) => item.id === countryId)?.code.toUpperCase() ?? (window.__env.DEFAULT_LOCALE || 'nl').toUpperCase();
        this.$forceUpdate();
    }

    public get zipCodeRules(): Array<any> {
        const response = [];

        if ( !!this.formData.postcode && this.countries.find(v => v.id === this.formData.country_id)?.code === 'NL' ) {
            response.push(...this.formRules.regex(/^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i, 'Global.FormRule.ZipCode'));
        }

        return response;
    }

    // Translate countries to i18n.locale
    get translatedCountries(): Array<Country> {
        return this.countries.map((country: Country) => {
            return Object.assign(country, { name: this.getCountryName(country.code, i18n.locale) ?? country.name });
        });
    }

    // Once list of countries is loaded, set NL as default selected
    @Watch('countries')
    onCountryChanged() {
        if ( !this.formData.country_id ) {
            const countryCode = (window.__env.DEFAULT_LOCALE || 'nl').toUpperCase();
            this.formData.country_id = this.countries.find((country: Country) => country.code === countryCode)?.id || 0;
        }
    }

    afterToggle() {
        this.fetchCountries();
        this.fetchGenders();
        this.fetchLocales();

        if ( this.countries.length > 0 && !this.formData.country_id ) {
            const countryCode = (window.__env.DEFAULT_LOCALE || 'nl').toUpperCase();
            this.formData.country_id = this.countries.find((country: Country) => country.code === countryCode)?.id || 0;
        }

        this.phoneNumberCountryCode = this.countries.find((item: Country) => item.id === this.formData.country_id)?.code.toUpperCase() ?? (window.__env.DEFAULT_LOCALE || 'nl').toUpperCase();
    }

    // Fetch data for available countries
    private fetchCountries() {
        if ( this.countries.length === 0 ) {
            this.itemsLoading.countries = true;

            apiClient.get('countries').then(response => {
                this.countries = response.data;
            }).finally(() => {
                this.itemsLoading.countries = false;
            });
        }
    }

    // Fetch data for available genders
    private fetchGenders() {
        this.itemsLoading.genders = true;

        apiClient.get('genders').then(response => {
            this.genders = response.data;
        }).finally(() => {
            this.itemsLoading.genders = false;
        });
    }

    private fetchLocales() {
        if ( this.locales.length === 0 ) {
            this.itemsLoading.locales = true;

            apiClient.get('locales').then(response => {
                this.locales = response.data;
            }).finally(() => {
                this.itemsLoading.locales = false;
                this.setPreferredLocale();
            });
        } else {
            this.setPreferredLocale();
        }
    }

    private setPreferredLocale() {
        if ( !this.formData.locale_id ) {
            this.formData.locale_id = this.locales.find((locale: any) => locale.code === i18n.locale)?.id ?? null;
        }
    }

    protected validate(): boolean {
        this.phoneInput.validate();

        return !!this.phoneInput.data?.isValid;
    }

    private formatNin(countrySpecificLength: number) {
        if ( this.formData.nin ) {
            this.formData.nin = this.formData.nin?.toString().substr(0, countrySpecificLength);
        }
    }

    public get netherlandsId(): any {
        return this.countries.find((country: Country) => country.code === 'NL')?.id;
    }

    public get belgiumId(): any {
        return this.countries.find((country: Country) => country.code === 'BE')?.id;
    }

}


