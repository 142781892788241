import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Ref } from 'vue-property-decorator';
import { StrRandom } from '@/utils/utils';
import FitText from '@/components/FitText/FitText.vue';
import apiClient from '@/apiClient';
import MenuButton from '@/components/MenuButton/MenuButton.vue';
import { namespace } from 'vuex-class';
import Popup from '@/components/popup/popup.vue';
import { default as PopupComponent } from '@/components/popup/popup';
import TreatmentReportPhotoComparisonComponent from '@/components/TreatmentReport/Components/TreatmentReportPhotoComparison/TreatmentReportPhotoComparisonComponent.vue';
import Loader from '@/components/Loader/Loader.vue';
import { ReportHistoryInterface } from '@/store/modules/reporthistory';

declare interface ImageData {
    name: string;
    id: number;
    data: string | null;
    vueId: string;
    src: string;
    loading: boolean;
    file: File;
}

@Component({
    name: 'TreatmentReportPhotos',
    components: {
        FitText,
        MenuButton,
        TreatmentReportPhotoComparisonComponent,
        Popup,
        PopupComponent,
        Loader,
    },
})
export default class TreatmentReportPhotos extends Vue {
    @Ref() uploadInput!: HTMLInputElement;
    @Ref() deletePhotoPopup!: PopupComponent;
    @Prop() value!: Array<ImageData>;
    @Ref() treatmentPhotosPopup!: PopupComponent;
    @Prop() photos!: Array<{ name: string; created_at: string; id: number }>;
    @Prop() reportHistoryObject!: ReportHistoryInterface;

    @namespace('application').Action showNotification: any;
    @namespace('reporthistory').Getter('state') public history: any;
    @namespace('reporthistory').Mutation('add') public addReportHistory: any;

    private openedPhotoComparison: boolean = false;
    private canEdit = this.$store.getters[ 'me/hasPermission' ]('treatment-report.update');
    private selectedImageId: number | null = null;

    private imageResources = [] as Array<ImageData>;
    private deletePhotoId!: number;
    private localPhotos: Array<{ name: string; created_at: string; id: number }> = this.photos;

    private openUploadModal() {
        this.uploadInput.click();
    }

    public openTreatmentPhotos(): void {
        this.openedPhotoComparison = !this.openedPhotoComparison;
    }

    imageLoaded(image: any) {
        const selected = this.mutatedPhotos.find((photo) => photo.id === image.id);

        if ( selected ) selected.loaded = true;

        this.$forceUpdate();
    }

    get mutatedPhotos() {
        return this.localPhotos?.map((photo) => Object.assign({ loaded: false }, photo));
    }

    private compareImage(selectedImageId: number) {
        this.selectedImageId = selectedImageId;
        this.openedPhotoComparison = !this.openedPhotoComparison;
    }

    private removeUpload(index: number) {
        this.imageResources.splice(index, 1);
    }

    private processFiles(event: Event) {
        const target = event.target as HTMLInputElement;
        const files = target.files as FileList;
        const promises = [];

        for ( let i = 0; i < files.length; i++ ) {
            const preSaveImage = {} as ImageData;
            preSaveImage.loading = true;
            preSaveImage.src = '';
            preSaveImage.file = files[ i ];
            this.imageResources.push(preSaveImage);
            promises.push(this.uploadImage(preSaveImage));
        }

        Promise.all(promises).then(() => {
            target.value = '';
        });
    }

    private uploadImage(image: ImageData): Promise<any> {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append('file', image.file);

            apiClient.post(`treatment-report/photos/pre-save`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then((response) => {
                    if ( response.data ) {
                        image.id = response.data;
                        image.loading = false;

                        this.setDataForPhotoCompression(image);

                        this.renderImage(image);
                        this.$emit('preSavePhotoAdded', response.data);
                        resolve(image);
                    }
                })
                .catch(() => {
                    reject();
                });
        });
    }

    private renderImage(image: ImageData): void {
        const reader = new FileReader();
        reader.readAsDataURL(image.file);

        reader.onload = () => {
            image.src = reader.result?.toString() || '';
            image.data = reader.result?.toString().split(',')[ 1 ] ?? null;
            image.name = image.file.name;
            image.vueId = StrRandom();
            image.loading = false;
        };
    }

    public onDelete(photo: any): void {
        this.deletePhotoId = photo;
        this.deletePhotoPopup.toggle();
    }

    public onDeleteConfirm() {
        apiClient.delete(`treatment-report/photos/${this.deletePhotoId}`)
            .then(this.onDeleteSuccess.bind(this))
            .finally(this.onUpdate.bind(this));
    }

    public onDeleteSuccess(): void {
        this.localPhotos = this.localPhotos.filter((photo: any) => photo.id !== this.deletePhotoId);
        this.deletePhotoPopup.toggle();
        this.showNotification('PhotoDeleteSuccess');
    }

    public onUpdate(): void {
        this.$emit('update');
    }

    private getDate(date: Date) {
        return date.toLocaleDateString(this.$i18n.locale, { day: '2-digit', month: 'short', year: 'numeric' });
    }

    private getImageUrl(id: number): string {
        return [ window.__env.API_URL, 'treatment-report', 'photos', id ].join('/');
    }

    private openImage(imageId: number): void {
        window.open(this.getImageUrl(imageId));
    }

    private setDataForPhotoCompression(image: ImageData) {
        const vuexStorageObject: Array<ReportHistoryInterface> = [ {
            appointment: {
                datetime_from: this.reportHistoryObject.appointment.datetime_from,
                datetime_till: this.reportHistoryObject.appointment.datetime_till,
                id: this.reportHistoryObject.appointment.id,
            },
            practitioner: {
                name: this.reportHistoryObject.practitioner.name,
                id: this.reportHistoryObject.practitioner.id,
            },
            treatment: {
                categoryName: this.reportHistoryObject.treatment.categoryName,
                name: this.reportHistoryObject.treatment.name,
            },
            photos: [
                {
                    id: image.id,
                    name: image.name,
                    thumbnailId: image.id,
                },
            ],
            id: this.reportHistoryObject.id,
        } ];

        this.addReportHistory({ data: vuexStorageObject });
    }
}
