import Vue from 'vue';
import Popup from '@/components/popup/popup';
import VueI18n from 'vue-i18n';
import { namespace } from 'vuex-class';
import FormRules from '@/partials/FormRules';
import { AxiosError, AxiosResponse } from 'axios';
import ResponseErrors from '@/Interfaces/ResponseErrors';
import apiClient from '@/apiClient';
import Component from 'vue-class-component';
import Model from '@/Interfaces/Model';
import { Prop, Watch } from 'vue-property-decorator';
import TranslateResult = VueI18n.TranslateResult;

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
@Component
export default abstract class PopupForm<T extends Model> extends Vue {
    $refs!: {
        popup: Popup;
        form: HTMLFormElement;
        error: HTMLElement;
    };

    @Prop({ default: true }) allowOneMoreEntry!: boolean;

    protected abstract modelName: string;
    protected abstract endpoint: string;
    public formData = {} as T;
    public loading = false;
    public errorMessage = null as null | TranslateResult;
    public formRules = FormRules;
    public valid = false;
    public oneMoreEntry = false as boolean;
    public deactivated = false as boolean;
    public isDirty = false as boolean;

    @namespace('application').Action showNotification: any;
    @namespace('me').State permissions: any;

    protected defaultFormData = {} as Record<string, any>;

    protected afterToggle?(): void;
    protected validate?(): boolean;

    @Watch('formData.deactivated_at')
    onFormDataChanged() {
        this.deactivated = !!this.formData?.deactivated_at || false;
    }

    get showOneMoreEntry(): boolean {
        return this.allowOneMoreEntry && !this.formData.id;
    }

    resetForm(): void {
        this.formData = JSON.parse(JSON.stringify(this.defaultFormData)) as T;
        this.errorMessage = null;
    }

    get primaryButtonText(): string {
        return this.formData.id ?
            this.$t('Global.Button.Edit').toString() :
            this.$t('Global.Button.Add').toString();
    }

    get title(): TranslateResult {
        return this.formData.id ?
            this.$t(`CRM.Views.${(this.modelName)}Index.Forms.Create${this.modelName}.Edit.title`) :
            this.$t(`CRM.Views.${this.modelName}Index.Forms.Create${this.modelName}.Create.title`);
    }

    close(): void {
        this.resetForm();

         Vue.set(this, 'formData', {});
        this.$refs.popup.close();
    }

    toggle(formData?: any): void {
        this.resetForm();

        /* todo: 26-11-2021 | This change has the potential to cause and solve a lot of reference problems.
            If any arise this is most likely the issue.
         */
        //Vue.set(this, 'formData', formData || {});
        Vue.set(this, 'formData', formData ? JSON.parse(JSON.stringify(formData)) : {});

        this.deactivated = false;
        this.oneMoreEntry = false;

        this.$refs.popup.toggle();
        this.afterToggle?.();
    }

    /**
     * Create a new model
     */
    create(close: boolean): Promise<void> {
        return apiClient.post(this.endpoint, this.formData).then((response) => {
            this.showNotification(`${this.modelName}SuccessfullyAdded`);
            this.handleSuccess('on-create', response.data, close);
        });
    }

    /**
     * Update existing model
     */
    async update(close: boolean): Promise<void> {
        (!this.formData.deactivated_at && this.deactivated) && await this.deactivateModel();

        (!!this.formData.deactivated_at && !this.deactivated) && await this.activateModel();

        return apiClient.put(`${this.endpoint}/${this.formData.id}`, this.formData).then((response) => {
            this.showNotification(`${this.modelName}SuccessfullyModified`);
            this.handleSuccess('on-update', response.data, close);
        });
    }

    protected activateModel(): Promise<AxiosResponse> {
        return apiClient.patch(`${this.endpoint}/${this.formData.id}/activate`);
    }

    protected deactivateModel(): Promise<AxiosResponse> {
        return apiClient.patch(`${this.endpoint}/${this.formData.id}/deactivate`);
    }

    protected onFormDataChange(): void {
        if (!this.loading) {
            this.isDirty = true;
        }
    }

    /**
     * Submit the form
     */
    submit(close: boolean): void {
        this.$refs.form.validate();
        this.errorMessage = null;

        const externalValidation = this.validate?.() ?? true;

        if (this.valid && externalValidation) {
            this.loading = true;
            const createOrUpdate = this.formData.id ? this.update : this.create;

            createOrUpdate(close).catch((error: AxiosError) => {
                this.handleErrors(error);
            }).finally(() => {
                this.loading = false;
            });
        }
    }

    /**
     * Handle submission success
     */
    handleSuccess(action: string, data: T, close: boolean): void {
        this.$emit(action, data);
        this.errorMessage = null;

        if (!this.oneMoreEntry && (close || close == null)) {
            this.resetForm();
            this.toggle();
        }
    }

    /**
     * Handle submission errors
     */
    handleErrors(error: { response?: { data: ResponseErrors } }): void {
        const validationErrors = error?.response?.data.errors;
        if (validationErrors) {
            Object.entries(validationErrors).forEach(([ attr, error ]) => {
                this.errorMessage = this.$t(`Api.Errors.${attr}.${error[ 0 ]}`);
                return;
            });
        } else throw error;
    }
}
