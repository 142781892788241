import Component from 'vue-class-component';
import Vue from "vue";
import {Prop} from "vue-property-decorator";
import PriceField from "@/components/PriceField/PriceField.vue";

@Component({
    components: {
        PriceField
    }
})
export default class PriceLevelPriceInput extends Vue {
    @Prop() public readonly priceLevel!: PriceLevelItem;
}
