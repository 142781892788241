import apiClient from '@/apiClient';
import ClientFormData from '@/Interfaces/ClientFormData';
import {valueConvertedToCurrency} from '@/utils/utils';
import CreditMutations from '@/views/ClientDashboard/Components/CreditMutations/CreditMutations.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import SoldPackage from '@/Interfaces/SoldPackage.ts';
import {Prop, Watch} from 'vue-property-decorator';
import Loader from "@/components/Loader/Loader.vue";

@Component({
    name: 'ClientPaymentDetails',
    components: {
        CreditMutations,
        Loader
    },
})
export default class ClientPaymentDetails extends Vue {
    @Prop() client!: ClientFormData;
    @Prop({ default: false }) reload!: boolean;
    @Prop({default: false}) showHistory!: boolean;
    @Prop({default: false, type: Boolean}) creditLoading!: boolean;
    @Prop() value!: any;

    public creditMutationsDialog: boolean = false;

    public creditBalance: string = '';
    public loading: boolean = false;
    public soldPackagesAndTerms: Array<SoldPackage> = [];

    public hasSoldPackages: boolean = false;
    public hasSoldTerms: boolean = false;

    public soldPackages: Array<any> = [];
    public soldTerms: Array<any> = [];

    @Watch('client', {immediate: true, deep: true})
    onClientChange() {
        this.reloadData();
    }

    @Watch('value', { immediate: true })
    onValueChange() {
        this.creditBalance = this.setCreditMappedToEuros(this.value?.creditUsed);
        this.soldPackages = this.setSoldPackages(this.value?.packagesUsed);
        this.soldTerms = this.setSoldTerms(this.value?.termsUsed);
    }

    mount() {
        if ( this.client ) this.setClientSoldPackages();
    }

    public showCreditMutations(): void {
        this.creditMutationsDialog = !this.creditMutationsDialog;
    }

    private reloadData(): void {
        this.setClientSoldPackages(true);
        this.creditBalance = valueConvertedToCurrency(this.client?.credit_balance ?? 0);
    }

    private setClientSoldPackages(reload: boolean = false): void {
        if ( !this.client ) return;

        this.loading = true;
        if ( this.soldPackagesAndTerms.length > 0 && !reload ) return;
        else apiClient.get(`list/client/${this.client?.id}/sold-packages`).then(response => {
            this.setPackagesAndTerms(response.data.body);
        }).finally(() => {
            this.loading = false;
        });
    }

    private setCreditMappedToEuros(creditUsed: number): string {
        if ( !this.client.credit_balance ) return '0';

        const amount = creditUsed <= this.client.credit_balance ? creditUsed : this.client.credit_balance;
        const credit = creditUsed ? this.client.credit_balance - amount : this.client.credit_balance;
        return valueConvertedToCurrency(credit);
    }

    private setSoldPackages(packagesUsed: Map<number, number>): Array<any> {
        const soldPackages = this.soldPackagesAndTerms.filter(soldItem => !soldItem.term);

        if ( packagesUsed?.size === 0 ) return soldPackages;
        else return soldPackages.map(soldPackage => {
            if ( soldPackage.id != null ) {
                const amount = (soldPackage.amount_treated ?? 0) + (packagesUsed.get(soldPackage.id) ?? 0);
                return { ...soldPackage, amount_treated: amount };
            }
        });
    }

    private setSoldTerms(termsUsed: Map<number, number>): Array<any> {
        const soldTerms = this.soldPackagesAndTerms.filter(soldItem => soldItem.term);

        if ( termsUsed?.size === 0 ) return soldTerms;
        else return soldTerms.map(soldTerm => {
            if ( soldTerm.id != null ) {
                const amount = (soldTerm.amount_treated ?? 0) + (termsUsed.get(soldTerm.id) ?? 0);
                return { ...soldTerm, amount_treated: amount };
            }
        });
    }

    private setPackagesAndTerms(response: Array<SoldPackage>) {
        this.soldPackagesAndTerms = response;
        this.soldPackages = this.soldPackagesAndTerms.filter(soldItem => !soldItem.term);
        this.soldTerms = this.soldPackagesAndTerms.filter(soldItem => soldItem.term);

        if ( this.soldPackages.length ) {
            this.hasSoldPackages = true;
        }

        if ( this.soldTerms.length ) {
            this.hasSoldTerms = true;
        }
    }
}
