import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop, Watch} from 'vue-property-decorator';
import PaymentItemInterface from '@/components/PaymentScreen/interfaces/PaymentItemInterface';
import apiClient from '@/apiClient';
import ClientFormData from '@/Interfaces/ClientFormData';

declare interface Package {
    id: number | null;
    package_sale_id?: number | null;
    terms: number | null;
    amount_treated?: number | null;
    price?: number | null;

}

@Component({
    name: 'PaymentItemTerm',
})
export default class PaymentItemTerm extends Vue {
    @Prop({default: () => []}) value!: Array<Package>;
    @Prop() item!: PaymentItemInterface;
    @Prop() locationId!: number;
    @Prop() private client!: ClientFormData;

    private packages = [] as Array<Package>;
    private loading = true;
    private packageSale = null as {
        amount_treated: number;
        amount_treatments: number;
        terms: number;
        id: number;
    } | null;

    private package = null as Record<string, any> | null;

    @Watch('value', {immediate: true})
    onValueChange() {
        this.packages = this.value;
    }

    @Watch('client')
    onClientChange() {
        this.client !== null && this.fetchPackage();
    }

    @Watch('item.type', {deep: true})
    onItemChange() {
        if (this.item.item !== 'treatment') {
            this.removePackage();
        } else {
            this.fetchPackage();
        }
    }

    mounted() {
        this.fetchPackage();
    }

    private fetchPackage() {
        this.loading = true;
        this.package = null;
        this.packageSale = null;

        // Fetch package
        apiClient.get(`packages/terms/for-treatment/${this.item.type?.id}/${this.locationId}`)
            .then(response => {

                this.packages = [{
                    id: response.data.id,
                    terms: response.data.terms,
                    amount_treated: 0,
                    price: response.data.price,
                }];

                this.package = {
                    amount_treatments: response.data.treatments,
                    terms: response.data.terms,
                };

                this.fetchPackageSale()
                    .then((response: any) => {
                        this.packageSale = {
                            amount_treated: response.data.amount_treated,
                            amount_treatments: response.data.amount_treatments,
                            terms: response.data.terms,
                            id: response.data.id,
                        };

                        Object.assign({
                            amount_treatments: response.treatments,
                            terms: response.terms,
                        }, this.package);

                        Vue.set(this.packages[0], 'amount_treated', response.data.amount_treated);
                        Vue.set(this.packages[0], 'package_sale_id', response.data.id);
                        Vue.set(this.packages[0], 'price', response.data.price);
                        Vue.set(this.packages[0], 'terms', response.data.amount_terms);


                    }).catch(() => this.loading = false)
                    .finally(() => {
                        this.$emit('input', this.packages);
                        this.loading = false;
                    });

            })
            .catch(() => {
                this.packages[0] = {id: null, package_sale_id: null, terms: null};
                this.loading = false;
            });
    }

    private fetchPackageSale() {
        return apiClient.get(`package_sales/client/${this.client?.id}/treatment/${this.item.type?.id}/available/terms`);
    }

    private removePackage() {
        this.$emit('input', []);
    }
}
