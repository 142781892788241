import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import PaymentItemInterface from '@/components/PaymentScreen/interfaces/PaymentItemInterface';
import apiClient from '@/apiClient';
import ClientFormData from '@/Interfaces/ClientFormData';

@Component({
    name: 'PaymentItemPackage',
})
export default class PaymentItemPackage extends Vue {
    @Prop({ default: () => [] }) value!: Array<{ id: number | null }>;
    @Prop() item!: PaymentItemInterface;
    @Prop() private client!: ClientFormData;

    private packages = [] as Array<{ id: number | null }>;
    private loading = true;
    private package = {} as {
        amount_treated: number;
        amount_treatments: number;
    } | null;

    @Watch('value', { immediate: true })
    onValueChange() {
        this.packages = this.value;
    }

    @Watch('client')
    onClientChange() {
        this.client !== null && this.fetchPackage();
    }

    @Watch('item.type', { deep: true })
    onItemChange() {
        if ( this.item.item !== 'treatment' ) {
            this.removePackage();
        } else {
            this.fetchPackage();
        }
    }

    mounted() {
        this.fetchPackage();
    }

    private fetchPackage() {
        this.loading = true;
        this.package = null;

        apiClient.get(`package_sales/client/${this.client?.id}/treatment/${this.item.type?.id}/available`).then((response) => {
            this.packages = [
                {
                    id: response.data.id,
                },
            ];

            this.package = {
                amount_treated: response.data.amount_treated,
                amount_treatments: response.data.amount_treatments,
            };

            this.$emit('input', this.packages);

        }).catch(() => this.packages[ 0 ] = { id: null }).finally(() => this.loading = false);
    }

    private removePackage() {
        this.$emit('input', []);
    }
}
