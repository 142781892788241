import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class TrafficLight extends Vue {
    @Prop({ default: 0 }) private status!: number;
    @Prop({ default: 15 }) private size!: number;

    private colors = [
        '#ff0000',
        '#ffac45',
        '#38da76',
    ];

    get color() {
        return this.colors[ this.status ];
    }
}
