import Vue from 'vue';
import Component from 'vue-class-component';
import { Editor, EditorContent } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import { Prop, Watch } from 'vue-property-decorator';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import TextAlign from '@tiptap/extension-text-align';
import { TaskList } from '@tiptap/extension-task-list';
import { TaskItem } from '@tiptap/extension-task-item';

@Component({
    name: 'Wysiwyg',
    components: {
        EditorContent,
    },
})
export default class Wysiwyg extends Vue {
    @Prop({ default: '' }) value!: string;
    @Prop({ default: true }) canEdit!: boolean;

    private editor: Editor = new Editor({
        content: this.value,
        extensions: [
            StarterKit,
            TaskList,
            TaskItem.configure({
                nested: true,
            }),
            Table.configure({
                resizable: true,
            }),
            TableRow,
            TableHeader,
            TableCell,
            TextAlign.configure({
                types: [ 'heading', 'paragraph' ],
            }),
        ],
        onUpdate: () => {
            this.$emit('input', this.editor.getHTML());
        },
        editable: this.canEdit
    });

    @Watch('value')
    onValueChange() {
        // HTML
        const isSame = this.editor.getHTML() === this.value;

        if ( isSame ) {
            return;
        }

        this.editor.commands.setContent(this.value, false);
    }

    beforeDestroy() {
        this.editor?.destroy();
    }
}
