import Vue from 'vue';
import presenters from '@/components/OverviewTable/presenters';
import PaymentItemInterface from '@/components/PaymentScreen/interfaces/PaymentItemInterface';

export default abstract class PaymentItems extends Vue {

    protected abstract items: Array<PaymentItemInterface>;

    get totalAmount(): number {
        return this.items?.reduce((accumulator, item) => {
            return accumulator + this.calculatePrice(item);
        }, 0);
    }

    get totalAmountString(): string {
        return presenters.get('currency', this.totalAmount);
    }

    private calculatePrice(item: PaymentItemInterface): number {
        if ( item.packages.length === 1 ) {
            return 0;
        }

        if ( item.discount.length === 1 ) {
            const amount = Math.round(item.discount.length === 1 ? item.discount[ 0 ].amount : 0);

            return (item.price - amount);
        }

        return item.price;
    }
}
