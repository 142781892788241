import Vue from 'vue';
import Component from 'vue-class-component';
import {namespace} from 'vuex-class';
import ClientForm from '@/views/ClientIndex/Forms/ClientForm.vue';
import ClientPicker from '@/components/ClientPicker/ClientPicker.vue';
import ClientFormData from '@/Interfaces/ClientFormData';
import ClientDetails from '@/views/ClientDashboard/ClientDetails.vue';
import ClientImportantNotes from '@/views/ClientDashboard/Components/ClientImportantNotes/ClientImportantNotes.vue';
import ClientPaymentDetails from '@/views/ClientDashboard/Components/ClientPaymentDetails/ClientPaymentDetails.vue';
import ClientAppointmentIndex
    from '@/views/ClientDashboard/Components/ClientAppointmentIndex/ClientAppointmentIndex.vue';
import apiClient from '@/apiClient';
import {Prop, Watch} from 'vue-property-decorator';
import ClientInvoicesIndex from '@/views/ClientDashboard/Components/ClientInvoicesIndex/ClientInvoicesIndex.vue';
import ClientScheduledAppointments
    from '@/views/ClientDashboard/Components/ClientScheduledAppointments/ClientScheduledAppointments.vue';
import Appointment from '@/Interfaces/Appointment.ts';
import SignAgreementModal from '@/components/SignAgreementModal/SignAgreementModal.vue';
import StatusIcon from '@/components/StatusIcon/StatusIcon.vue';
import FitText from '@/components/FitText/FitText.vue';
import Contract from '@/Interfaces/Contract.ts';
import AgreementUploadModal from '@/components/AgreementUploadModal/AgreementUploadModal.vue';
import AgreementDeleteModal from '@/components/AgreementDeleteModal/AgreementDeleteModal.vue';
import Popup from '@/components/popup/popup';

@Component({
    components: {
        AgreementUploadModal,
        AgreementDeleteModal,
        ClientForm,
        ClientPicker,
        ClientDetails,
        ClientImportantNotes,
        ClientAppointmentIndex,
        ClientInvoicesIndex,
        ClientPaymentDetails,
        ClientScheduledAppointments,
        SignAgreementModal,
        StatusIcon,
        FitText,
    },
})
export default class ClientDashboard extends Vue {
    @Prop() value!: ClientFormData;
    @namespace('me').State permissions: any;
    @namespace('mru').State('client') mruClient!: any;
    @namespace('mru').Mutation('set') setMru!: any;
    @namespace('mutationtypes').Action fetchMutationTypes!: any;

    public appointments = [] as Array<Appointment>;

    private selectedClient: ClientFormData = {};
    private selectedClientId: number | null = null;
    public tab = 0 as number;
    public loading = {
        appointments: false,
        contracts: false,
        data: false,
        credit: false
    };

    public $refs!: {
        agreementDeleteModal: Popup;
    }

    private dashboardTabActivated(): void {
        this.refreshCredits();
    }

    private contracts: Array<Contract> = [];

    created() {
        const {client_id, tab_id} = this.$route.params;

        if (client_id && parseInt(client_id)) {
            this.setMru({key: 'client', value: null});
            this.selectedClientId = parseInt(client_id);
            Vue.set(this, 'selectedClient', {id: client_id});
        } else if (this.mruClient) {
            this.selectedClient = this.mruClient;
        }

        if (tab_id && parseInt(tab_id)) {
            this.tab = parseInt(tab_id);
        }

    }

    mounted() {
        this.setClientAppointments();
        this.fetchContracts();
        this.fetchMutationTypes();
    }

    @Watch('selectedClient')
    onSelectedClientChange() {
        this.loading.data = true;

        this.appointments = [];
        this.setClient();
    }

    private refreshCredits() {
        this.loading.credit = true;
        apiClient.get(`clients/${this.selectedClient?.id}/credit`).then(response => {
            if (this.client) {
                Vue.set(this.client, 'credits', response.data.credits);
                Vue.set(this.client, 'credit_balance', response.data.credit_balance);
            }
        }).finally(() => this.loading.credit = false);
    }

    private setClient() {
        apiClient.get(`client-dashboard/${this.selectedClient?.id}/client`).then(response => {
            this.client = response.data;
            this.setClientAppointments();
            this.fetchContracts();
        }).finally(() => this.loading.data = false);
    }

    private setClientAppointments() {
        if (!this.client) return;

        this.loading.appointments = true;

        apiClient.get(`list/client/${this.client?.id}/appointments`).then(response => {
            this.appointments = response.data;
        }).finally(() => {
            this.loading.appointments = false;
        });
    }

    get client(): ClientFormData | null {
        return this.mruClient;
    }

    set client(value: ClientFormData | null) {
        this.setMru({key: 'client', value: value});

        if (parseInt(this.$route.params?.client_id) !== value?.id) {
            this.$router.replace(`/clients/${value?.id || ''}`);
        }
    }


    private setTab(tab: number) {
        this.tab = tab;
    }

    private changeTab(id: number) {
        const pathParts = this.$router.currentRoute.path.split('/');

        if (pathParts.length > 3) {
            pathParts.pop();
        }

        const path = pathParts.join('/');
        this.$router.push([path, id].join('/'));
    }

    private onCreate(client: ClientFormData): void {
        this.selectedClient = client;
    }

    private openContract(contract: Contract) {
        window.open([window.__env.API_URL, 'contracts', contract.id].join('/'));
    }

    private fetchContracts() {
        this.loading.contracts = true;
        apiClient.get(`list/client/${this.selectedClient?.id}/contracts`).then((response) => {
            this.contracts = response.data;
        }).finally(() => this.loading.contracts = false);
    }

    private deleteContract(contractId: number, contractName: string): void {
        const data = {
            contractId,
            contractName
        }

        this.$refs.agreementDeleteModal.toggle(data);
    }
}
