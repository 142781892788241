import PopupForm from '@/partials/PopupForm';
import Component from 'vue-class-component';
import Modal from '@/components/Modal/Modal.vue';
import {Prop} from 'vue-property-decorator';
import apiClient from '@/apiClient';
import ClientFormData from '@/Interfaces/ClientFormData';
import presenters from '@/components/OverviewTable/presenters';

@Component({
    name: 'PaymentOverpayModal',
    components: {
        Modal,
    }
})
export default class PaymentOverpayModal extends PopupForm<any> {
    @Prop() client!: ClientFormData;
    @Prop() practitionerId!: number;
    @Prop() overpaidAmount!: number;
    @Prop() locationId!: number;
    @Prop() paymentMethod!: 'with-notification'|'no-notification';
    @Prop({default: []}) paymentMethods!: [];
    @Prop({type: Number, required: true}) amountTotal!: number;

    private isLoading: boolean = false;

    protected endpoint: string = '';
    protected modelName: string = '';

    public get getClientName() {
        return this.client?.name;
    }

    public get getCurrencyAmount() {
        return presenters.get('currency', this.overpaidAmount);
    }

    private convertAppointmentPayment() {
        this.isLoading = true;

        const data = {
            'paymentMethods': this.paymentMethods,
            'client_id': this.client.id,
            'practitioner_id': this.practitionerId,
            'overpaidAmount': this.overpaidAmount,
            'location_id': this.locationId,
            'amountTotal': this.amountTotal
        }

        apiClient.post(`credit/convert-overpay`, data).then(response => {
            if ( response.data ) {
                this.$emit('pay', this.paymentMethods);
            }
        }).finally(() => {
            this.isLoading = false;
        });
    }
}
