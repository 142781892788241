import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import Avatar from '@/components/Avatar/Avatar.vue';

interface ColorLegendData {
    id: number;
    name: string;
    color: string;
}

@Component({ components: { Avatar } })
export default class ColorLegend extends Vue {
    @Prop() value!: Array<ColorLegendData>;
    @Prop() titleText!: string;
}
