import Vue from 'vue';
import { Prop, Watch } from 'vue-property-decorator';
import apiClient from '@/apiClient';
import Component from 'vue-class-component';
import { AxiosResponse } from 'axios';


// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
@Component
export default abstract class Autocomplete<T> extends Vue {
    @Prop() value!: number;
    @Prop() label!: string;
    @Prop({ default: () => [] }) prefill!: Array<any>;
    @Prop() rules!: Array<any>;
    @Prop({ default: true }) dense!: boolean;

    public loading = false;
    public selected: null | number = null;
    public data: Array<T> = [];

    protected abstract endpoint: string;

    @Watch('selected')
    onSelectedChange() {
        this.$emit('input', this.selected);
        this.$emit('onModelChange', this.data.find((item: any) => item.id === this.selected));
    }

    @Watch('value', { immediate: true })
    onValueChange() {
        this.selected = this.value;
    }

    fetch() {
        if ( this.prefill.length > 0 ) {
            this.afterFetch(this.prefill);
        } else {
            this.apiFetch();
        }
    }

    private apiFetch() {
        this.loading = true;

        this.makeApiCall()
            .then((response) => {
                this.afterFetch(response.data);
            })
            .finally(() => this.loading = false);
    }


    protected afterFetch(data: any) {
        this.data = data;
        this.$emit('after-fetch', this.data);
    }

    makeApiCall(): Promise<AxiosResponse<any>> {
        return apiClient.get(this.endpoint);
    }
}
