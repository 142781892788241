import Vue from 'vue';
import Component from 'vue-class-component';
import Appointments from '@/views/Dashboard/Components/Appointments/Appointments.vue';
import Welcome from '@/views/Dashboard/Components/Welcome/Welcome.vue';
import apiClient from '@/apiClient';
import TreatmentReportPopup from '@/components/TreatmentReport/Components/Popup/TreatmentReportPopup.vue';
import TreatmentReportPopupComponent from '@/components/TreatmentReport/Components/Popup/TreatmentReportPopup';
import { namespace } from 'vuex-class';

@Component({
    components: {
        Appointments,
        TreatmentReportPopup,
        Welcome
    },
})

export default class Dashboard extends Vue {
    @namespace('mru').Mutation('set') setMru!: any;
    @namespace('me').State me: any;

    public appointments: Array<any> = [];
    public loading: boolean = false;

    public get appointmentsCreated(): Array<any> {
        const filter = (i: any) => !i.deleted_at && new Date(i.created_at) > new Date((new Date()).setDate(new Date().getDate() - 7));
        const sorter = (a: any, b: any) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime();

        return this.appointments.filter(filter).sort(sorter);
    }

    public get appointmentsDeleted(): Array<any> {
        const filter = (i: any) => !!i.deleted_at;
        const sorter = (a: any, b: any) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime();

        return this.appointments.filter(filter).sort(sorter);
    }

    public get appointmentsUpdated(): Array<any> {
        const filter = (i: any) => !i.deleted_at && i.updated_at && i.created_at !== i.updated_at;
        const sorter = (a: any, b: any) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime();

        return this.appointments.filter(filter).sort(sorter);
    }

    public headersCreated: Array<any> = [
        { text: this.$t('CRM.Views.Dashboard.TableHeader.Client'), value: 'client.name' },
        { text: this.$t('CRM.Views.Dashboard.TableHeader.Date'), value: 'datetime_from' },
        { text: this.$t('CRM.Views.Dashboard.TableHeader.Treatment'), value: 'treatment.name' },
        { text: this.$t('CRM.Views.Dashboard.TableHeader.Location'), value: 'location.name' },
        { text: '', value: 'actions' },
    ];

    public headersDeleted: Array<any> = [
        { text: this.$t('CRM.Views.Dashboard.TableHeader.Client'), value: 'client.name' },
        { text: this.$t('CRM.Views.Dashboard.TableHeader.Date'), value: 'datetime_from' },
        { text: this.$t('CRM.Views.Dashboard.TableHeader.Treatment'), value: 'treatment.name' },
    ];

    public headersUpdated: Array<any> = [
        { text: this.$t('CRM.Views.Dashboard.TableHeader.Client'), value: 'client.name' },
        { text: this.$t('CRM.Views.Dashboard.TableHeader.Date'), value: 'datetime_from' },
        { text: this.$t('CRM.Views.Dashboard.TableHeader.Treatment'), value: 'treatment.name' },
        { text: this.$t('CRM.Views.Dashboard.TableHeader.Location'), value: 'location.name' },
        { text: '', value: 'actions' },
    ];

    public created(): void {
        apiClient.get('dashboard')
            .then(response => this.appointments = response.data)
            .finally(() => this.loading = false);

        this.loading = true;
    }

    public onAppointment(item: any): void {
        this.$store.commit('mru/setMruUserFromMe', this.$store.getters['me/get']);

        this.$router.push({ path: 'calendar/employee', query: { date: item.datetime_from, locationId: item.location.id } });
    }

    public onClient(item: any): void {
        this.$router.push({ path: [ 'clients', item.client.id ].join('/') });
    }

    public onTreatmentReport(item: any): void {
        (this.$refs.treatmentReportPopup as TreatmentReportPopupComponent).toggle({ id: item.id });
    }
}
