import Component from 'vue-class-component';
import OverviewTable from '@/components/OverviewTable/OverviewTable.vue';
import IndexView from '@/partials/IndexView';
import LocaleForm from '@/views/DatabaseModels/Components/Locale/Form/LocaleForm.vue';

@Component({
    components: {
        OverviewTable,
        LocaleForm,
    },
})
export default class LocaleIndex extends IndexView<any> {
    protected endpoint = 'locales/dto';
}