import Component from 'vue-class-component';
import AppointmentForm from '@/views/CalendarIndex/Forms/AppointmentForm.vue';
import AppointmentRaw from '@/views/AppointmentIndex/Forms/AppointmentForm.vue';
import ColorLegend from '@/views/CalendarIndex/Components/ColorLegend/ColorLegend.vue';
import Calendar from '@/views/CalendarIndex/Components/Calendar/Calendar.vue';
import MenuButton from '@/components/MenuButton/MenuButton.vue';
import CalendarDayView from '@/views/CalendarIndex/Components/Calendar/DayView/CalendarDayView.vue';
import CalendarWeekView from '@/views/CalendarIndex/Components/Calendar/WeekView/CalendarWeekView.vue';
import EmployeePicker from '@/components/EmployeePicker/EmployeePicker.vue';
import CalendarIndexParent from '@/views/CalendarIndex/CalendarIndexParent';
import { AxiosResponse } from 'axios';
import Location from '@/Interfaces/Location';
import { Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import User from '@/Interfaces/User';

@Component({
    components: {
        AppointmentForm,
        AppointmentRaw,
        Calendar,
        CalendarDayView,
        CalendarWeekView,
        ColorLegend,
        EmployeePicker,
        MenuButton,
    },
})

export default class CalendarEmployeeIndex extends CalendarIndexParent {
    // VueX
    @namespace('mru').State('user') public mruUser!: User;

    public selectedUser: User | null = this.mruUser;

    // Private variables
    private locations = [] as Array<Location>;

    protected endpoint() {
        return this.calendarView === 'week'
            ? [ 'calendar', this.mruUser?.id, 'employee/week', this.startWeekDate.format('YYYY-M-D'), this.endWeekDate.format('YYYY-M-D')].join('/')
            : [ 'calendar', this.mruUser?.id, 'employee', this.date.format('YYYY-M-D') ].join('/');
    }

    setResponseFromParent(response: AxiosResponse) {
        if (response.data.locations) {
            this.locations = response.data.locations;
        }
    }

    @Watch('mruUser')
    onSelectedUserChange(){
        this.fetch();
    }

    mounted(){
        this.$store.dispatch('locations/fetch');
    }
}
