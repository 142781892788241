import Component from 'vue-class-component';
import Vue from 'vue';
import UserCredentials from '@/Interfaces/UserCredentials';
import FormRules from '@/partials/FormRules';
import apiClient from '@/apiClient';
import PasswordField from '@/components/PasswordField/PasswordField.vue';

@Component({
    components: { PasswordField },
})

export default class ForgotPassword extends Vue {
    public valid = false as boolean;
    public formData = { email: '' } as UserCredentials;
    public showResetLinkSentIfEmailExists = false as boolean;
    private loading = false as boolean;
    private errorMessage = null as string | null;
    public formRules = FormRules;

    mounted(): void {
        if (this.$route.params.email) {
            this.formData.email = this.$route.params.email;
        }
    }

    submit(): void {
        this.loading = true;
        this.errorMessage = null;

        apiClient.post('/password-resets', { email: this.formData.email }).then(() => {
            this.showResetLinkSentIfEmailExists = true;
        }).finally(() => {
            this.loading = false;
        });
    }
}
