declare interface EventStatusData extends Record<string, any> {
    is_paid: boolean;
    has_contract: boolean;
    treatment_report_status: boolean;
}

export default class EventStatusService {
    static requiredKeys = [
        'is_paid',
        'has_contract',
        'treatment_report_status',
    ];

    static get(data: EventStatusData): number {
        const tmpData = JSON.parse(JSON.stringify(data));

        Object.keys(data).forEach(key => !EventStatusService.requiredKeys.includes(key) && delete tmpData[ key ]);


        const allPassed = Object.values(tmpData).filter((item) => item === true).length === 3;
        if ( allPassed ) {
            return 2;
        }

        const somePassed = Object.values(tmpData).some((item) => item === true);
        if ( somePassed ) {
            return 1;
        }

        return 0;
    }
}
