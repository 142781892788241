import PopupForm from '@/partials/PopupForm';
import Component from 'vue-class-component';
import Modal from '@/components/Modal/Modal.vue';
import apiClient from '@/apiClient';
import { Prop } from 'vue-property-decorator';
import EmployeeFormData from '@/Interfaces/EmployeeFormData';
import store from '@/store';

@Component({
    name: 'EmployeeMFAConfirmModal',
    components: {
        Modal,
    }
})
export default class EmployeeMFAConfirmModal extends PopupForm<any> {
    @Prop() employeeData!: EmployeeFormData;
    protected endpoint!: string;
    protected modelName: string = "MFA";

    public mounted() {
        this.endpoint = "mfa/reset";
    }

    private resetUserMFA(): void {
        this.loading = true;

        const data = {
            id: this.employeeData.id
        }

        apiClient.post(this.endpoint, data)
            .then(response => {
                this.toggle();
                store.dispatch('application/showNotification', 'MFAReset');
            }).finally(() => {
                this.loading = false;
        })
    }
}
