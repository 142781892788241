import apiClient from '@/apiClient';
import Component from 'vue-class-component';
import DeviceFormData from '@/Interfaces/DeviceFormData';
import i18n from '@/i18n';
import popup from '@/components/popup/popup.vue';
import PopupForm from '@/partials/PopupForm';
import { Watch } from 'vue-property-decorator';
import DeactivateToggle from '@/components/DeactivateToggle/DeactivateToggle.vue';
import { objectToQueryString } from '@/utils/utils';

@Component({
    components: { popup, DeactivateToggle },
})
export default class DeviceForm extends PopupForm<DeviceFormData> {
    protected modelName = 'Device';
    protected endpoint = 'devices';

    public selectedLink = 'LocationRoom';

    public brands: Array<any> = [];
    public locations: Array<any> = [];
    public rooms: Array<any> = [];
    public types: Array<any> = [];
    public users: Array<any> = [];

    public dataLoading = {
        brands: false,
        locations: false,
        rooms: false,
        types: false,
        users: false,
    };

    public get locationRooms(): Array<any> {
        const response = this.rooms.filter(room => room.location_id === this.formData.location_id);

        response.unshift({
            id: null,
            name: i18n.t('CRM.Views.DeviceIndex.Popup.Create.NoRoom'),
        });

        return response;
    }

    public get links(): object {
        return (i18n.messages[this.$i18n.locale].Api as any).DeviceLinks;
    }

    @Watch('selectedLink')
    private onSelectedLinkChange(): void {
        if ( !this.dataLoading.locations ) {
            if ( this.selectedLink === 'LocationRoom' ) this.formData.user_id = null;
            else if ( this.selectedLink === 'Practitioner' ) this.formData.location_id = null;
        }
    }

    @Watch('formData.location_id', { deep: true })
    private onLocationIdChange(): void {
        if ( !this.dataLoading.locations ) {
            this.formData.room_id = null;
        }
    }

    public afterToggle(): void {
        this.selectedLink = this.formData.user_id ? 'Practitioner' : 'LocationRoom';

        this.getBrands();
        this.getLocations();
        this.getRooms();
        this.getTypes();
        this.getUsers();
    }

    public onKeyDown(event: KeyboardEvent): void {
        this.formData.brand_name = (event.target as HTMLInputElement).value;
    }

    private getBrands(): void {
        this.brands = [];

        apiClient.get('brands')
            .then(response => this.brands = response.data)
            .finally(() => this.dataLoading.brands = false);

        this.dataLoading.brands = true;
    }

    private getLocations(): void {
        this.locations = [];

        apiClient.get('locations/raw')
            .then(response => this.locations = response.data)
            .finally(() => this.dataLoading.locations = false);

        this.dataLoading.locations = true;
    }

    private getRooms(): void {
        this.rooms = [];

        apiClient.get('rooms/raw')
            .then(response => this.rooms = response.data)
            .finally(() => this.dataLoading.rooms = false);

        this.dataLoading.rooms = true;
    }

    private getTypes(): void {
        this.types = [];

        apiClient.get('devices/types')
            .then(response => this.types = response.data)
            .finally(() => this.dataLoading.types = false);

        this.dataLoading.types = true;
    }

    private getUsers(): void {
        this.users = [];

        const params = objectToQueryString({ filters: { practitioners: true } });
        const endpoint = [ 'employees', params ].join('?');

        apiClient.get(endpoint)
            .then(response => this.users = response.data.body)
            .finally(() => this.dataLoading.users = false);

        this.dataLoading.users = true;
    }
}
