import Component from 'vue-class-component';
import { VTextField } from 'vuetify/lib';
import { Prop } from 'vue-property-decorator';
import zxcvbn from 'zxcvbn';

@Component
export default class PasswordField extends VTextField {
    public showPassword = false as boolean;
    public value!: string;

    @Prop({ type: Boolean, default: false }) readonly strengthIndicator!: boolean;

    get passwordStrength(): PasswordStrength {
        const { score } = zxcvbn(this.value);

        switch (score) {
            case 4:
                return {
                    score,
                    color: 'success',
                    value: 100,
                };
            case 3:
                return {
                    score,
                    color: 'success',
                    value: 100,
                };
            case 2:
                return {
                    score,
                    color: 'orange',
                    value: 66,
                };
            case 1:
                return {
                    score,
                    color: 'red',
                    value: 33,
                };
            default:
                return {
                    score,
                    color: 'red',
                    value: 0,
                };
        }
    }
}