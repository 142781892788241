import Component from 'vue-class-component';
import LocationForm from '@/views/LocationIndex/Forms/LocationForm.vue';
import Vue from 'vue';
import { namespace } from 'vuex-class';
import { findEachWordInOneOf } from '@/utils/utils';
import { Prop } from 'vue-property-decorator';

@Component({
    components: { LocationForm },
})

export default class LocationPicker extends Vue {
    @Prop() public returnObject!: boolean;

    @namespace('locations').State public locations: any;
    @namespace('locations').State public loading: any;
    @namespace('locations').Action private fetch: any;
    public findEachWordInOneOf = findEachWordInOneOf;

    public mounted(): void {
        this.fetch();
    }
}
