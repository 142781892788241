import Vue from 'vue';
import Component from 'vue-class-component';
import NumberField from '@/components/NumberField/NumberField.vue';
import FloatField from '@/components/FloatField/FloatField.vue';
import DateField from '@/components/DateField/DateField.vue';
import LotNumberStorage from '@/Interfaces/LotNumberStorage';
import { Prop } from 'vue-property-decorator';

@Component({
    name: 'TreatmentReportInjectable',
    components: {
        NumberField,
        FloatField,
        DateField,
    },
})
export default class TreatmentReportInjectable extends Vue {
    @Prop() availableInjectables!: Array<{ name: string; id: number }>;
    @Prop({ default: {} }) value!: any;

    protected localStorageLotNumbers: Array<LotNumberStorage> = [];
    private localInjectables: Array<{ name: string; id: number }> = this.availableInjectables;


    getLocalStorageLotNumbers() {
        return this.localStorageLotNumbers.map((lotNumber: LotNumberStorage) => {
            return lotNumber.lot_number;
        });
    }

    private updateDateFieldFromLotNumberStorage(lotNumber: string) {
        const find = this.localStorageLotNumbers.find((item: LotNumberStorage) => item.lot_number === lotNumber);
        if ( find ) this.value.expiration_date = find.expiration_date;
    }

    mounted(): void {
        if ( localStorage.getItem('lotNumbers') ) {
            this.localStorageLotNumbers = JSON.parse(localStorage.getItem('lotNumbers') as string);
        } else {
            localStorage.setItem('lotNumbers', JSON.stringify(this.localStorageLotNumbers));
        }
    }
}