import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import AppointmentFormComponent from '@/views/CalendarIndex/Forms/AppointmentForm';
import AppointmentRawComponent from '@/views/AppointmentIndex/Forms/AppointmentForm';
import RoomFormData from '@/Interfaces/RoomFormData';
import AppointmentEventData from '@/Interfaces/AppointmentEventData';
import EmployeeFormData from '@/Interfaces/EmployeeFormData';
import LocationFormData from '@/Interfaces/LocationFormData';
import dayjs from 'dayjs';
import AppointmentFormData from '@/Interfaces/AppointmentFormData';
import Location from '@/Interfaces/Location';

@Component
export default class LocationCalendarParent extends Vue {
    @Prop() value!: any;
    @Prop({ default: () =>  [] }) public rooms!: Array<RoomFormData>;
    @Prop({ default: () =>  [] }) public appointments!: Array<AppointmentEventData>;
    @Prop({ default: () =>  [] }) public users!: Array<EmployeeFormData>;
    @Prop( { default: () => [] }) public locations!: Array<Location>;
    @Prop({ default: false }) public loadingCalendar!: boolean;
    @Prop({ default: 'day' }) public calendarView!: 'day'|'week'|'switch';

    // Public vars
    public showSidebar = false;
    public date = this.value;
    public weekTravel = 0 as number;
    public loading = false;

    // Protected variables
    protected endpoint = 'appointments';

    // VueX
    @namespace('mru').State('location') mruLocation!: any;
    @namespace('mru').Mutation('set') setMru!: any;
    @namespace('me').State permissions: any;

    // Refs
    @Ref('appointment-form') public readonly form!: AppointmentFormComponent;
    @Ref('appointment-raw') public readonly raw!: AppointmentRawComponent;

    // Watchers
    @Watch('value')
    onValueChange() {
        this.date = this.value;
    }

    @Watch('date')
    fetch(): void {
        this.changeCalenderDate();

        this.$emit('fetch');
    }

    public changeCalenderDate(): void {
        this.$emit('changeCalenderDate', this.date);
    }

    protected getShowHidePlanningCopy(): string {
        const copy = !this.showSidebar ? this.$t('CRM.Views.CalendarIndex.Header.ViewPlanning') : this.$t('CRM.Views.CalendarIndex.Header.ClosePlanning');

        return copy.toString();
    }

    // Getters & Setters
    get getStartOfWeek() {
        return this.date.startOf('week');
    }

    get getEndOfWeek() {
        return this.date.endOf('week');
    }

    set location(value: LocationFormData | number | null) {
        this.setMru({ key: 'location', value: value });
        this.fetch();
    }

    get location(): LocationFormData | number | null {
        return this.mruLocation;
    }

    get calendarDate() {
        const newDate = new Date(this.date.toDate().getTime() + Math.abs(this.date.toDate().getTimezoneOffset() * 60000));

        return newDate.toISOString().substr(0, 10);
    }

    set calendarDate(date: string) {
        this.date = dayjs(date);
    }

    // Public methods
    public onCreate(response: any): void {
        this.$emit('on-create', response);
    }

    public onEdit(event: AppointmentFormData): void {
        this.$emit('edit', event);
    }

    public onNoShow(data: any): void {
        this.$emit('no-show', data);
    }

    public onRepeat(event: any): void {
        this.$emit('repeat', event);
    }

    public onReset(): void {
        this.$emit('reset');
    }

    public changeCalendarView(calendarView: 'day'|'week'|'switch'): void {
        this.$emit('setCalendarView', calendarView);
    }

    public onUpdate(): void {
        this.$emit('update');
    }

    public updateAppointment(newAppointment: AppointmentEventData) {
        this.$emit('update-appointment', newAppointment);
    }

    public eventPaymentComplete(eventId: number) {
        this.$emit('eventPaymentComplete', eventId);
    }

    public getRoomIdFromPosition(position: number): any {
        return this.rooms[ position - 1 ].id;
    }

}
