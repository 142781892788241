import { valueConvertedToCurrency } from '@/utils/utils';
import Component from 'vue-class-component';
import StatusIcon from '@/components/StatusIcon/StatusIcon.vue';
import TreatmentReportHeader from '@/components/TreatmentReport/Components/TreatmentReportHeader/TreatmentReportHeader.vue';
import TreatmentReportBody from '@/components/TreatmentReport/Components/TreatmentReportBody/TreatmentReportBody.vue';
import { Prop } from 'vue-property-decorator';
import Vue from 'vue';
import apiClient from '@/apiClient';
import { AxiosPromise } from 'axios';
import TreatmentReportInterface from '@/Interfaces/TreatmentReportInterface';
import TreatmentReportHistory from '@/components/TreatmentReport/Components/TreatmentReportHistory/TreatmentReportHistory.vue';
import { namespace } from 'vuex-class';
import LotNumberStorage from '@/Interfaces/LotNumberStorage';
import Injectable from '@/Interfaces/Injectable';

@Component({
    name: 'TreatmentReport',
    components: {
        StatusIcon,
        TreatmentReportHeader,
        TreatmentReportBody,
        TreatmentReportHistory,
    },
})
export default class TreatmentReport extends Vue {
    public data = {} as TreatmentReportInterface;
    public loading = true;
    public isTreatmentHistoryOpen = false;

    @Prop() id!: number | null;
    @namespace('reporthistory').Mutation('setCompareClient') public setCompareClient: any;
    @namespace('reporthistory').Mutation('setCompareAppointment') public setCompareAppointment: any;
    protected localStorageLotNumbers: Array<LotNumberStorage> = [];

    private attr = {
        class: 'mb-6',
        boilerplate: false,
        elevation: 0,

    };

    mounted() {
        this.fetch();

        if ( localStorage.getItem('lotNumbers') ) {
            this.localStorageLotNumbers = JSON.parse(localStorage.getItem('lotNumbers') as string);
        } else {
            localStorage.setItem('lotNumbers', JSON.stringify(this.localStorageLotNumbers));
        }
    }

    openForAppointment(id: number) {
        this.fetch(id);
    }

    openTreatmentHistory() {
        this.isTreatmentHistoryOpen = true;
    }

    closeTreatmentHistory() {
        this.isTreatmentHistoryOpen = false;
    }

    fetch(id: number | null = null) {
        this.loading = true;

        apiClient.get(`event/${id || this.id}/treatment-report`)
            .then(response => {
                this.data = response.data;

                this.setCompareClient({ data: response.data.client.email });
                this.setCompareAppointment({ data: response.data.appointment.id });
            })
            .finally(() => this.loading = false);
    }

    saveOrUpdate(): AxiosPromise {
        this.saveLocalStorageLotNumbers();

        return this.data.formData.id ? this.update() : this.save();
    }

    save(): AxiosPromise {
        return apiClient.post('treatment-report', this.data.formData);
    }

    update(): AxiosPromise {
        return apiClient.put(`treatment-report/${this.data.formData.id}`, this.data.formData);
    }

    get paymentMutatedData() {
        return {
            client: this.data.client,
            location_id: this.data.location.id,
            practitioner_id: this.data.practitioner.id,
            appointment_id: this.data.appointment.id,
            items: [
                {
                    item: 'treatment',
                    type: {
                        id: this.data.formData.treatment,
                    },
                },
            ]/*TODO: Disabled, not needed for now but probaly in the future
            .concat(this.data.formData.peelings.map(item => {
                return {
                    item: 'product',
                    type: {
                        id: item.id as number,
                    },
                };
            }))*/,
        };
    }

    public convertAppointmentPayment(appointmentId: number): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            apiClient.post(`credit/convert/${appointmentId}`).then(response => {
                if ( response.data ) {
                    const convertedAmount = valueConvertedToCurrency(response.data);
                    this.$store.dispatch('application/showNotification', [ 'DownPaymentHasBeenConverted', { amount: convertedAmount } ]);
                }
                resolve();
            }, error => reject(error));
        });
    }

    private saveLocalStorageLotNumbers() {
        this.data.formData.injectables.forEach((injectable: Injectable) => {
            this.localStorageLotNumbers.push({
                lot_number: injectable.lot_number,
                expiration_date: injectable.expiration_date,
            });
        });

        const unique = this.localStorageLotNumbers.filter(
            (v, i, a) => a.findIndex(
                v2 => [ 'lot_number', 'expiration_date' ].every(k => v2[ k ] === v[ k ]),
            ) === i,
        );

        this.localStorageLotNumbers = unique.slice(-3);

        localStorage.setItem('lotNumbers', JSON.stringify(this.localStorageLotNumbers));
    }
}
