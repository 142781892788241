import { render, staticRenderFns } from "./TrafficLight.vue?vue&type=template&id=0a09e106&scoped=true&"
import script from "./TrafficLight.ts?vue&type=script&lang=ts&"
export * from "./TrafficLight.ts?vue&type=script&lang=ts&"
import style0 from "./TrafficLight.scss?vue&type=style&index=0&id=0a09e106&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a09e106",
  null
  
)

export default component.exports