import Vue from 'vue';
import Component from 'vue-class-component';
import {Total} from '@/components/OverviewTable/OverviewTable';
import presenters from '@/components/OverviewTable/presenters';
import {Prop} from 'vue-property-decorator';

@Component({
    name: 'TableTotals',
})
export default class TableTotals extends Vue {
    @Prop() filteredTotals!: Array<Total>;
    @Prop() prependIcons!: Record<string, Array<string>>;
    public presenters = presenters;

}
