import Component from 'vue-class-component';
import Vue from 'vue';
import AppointmentFormComponent from '@/views/CalendarIndex/Forms/AppointmentForm';
import AppointmentRawComponent from '@/views/AppointmentIndex/Forms/AppointmentForm';
import { Ref, Watch } from 'vue-property-decorator';
import apiClient from '@/apiClient';
import colors from '@/views/CalendarIndex/colors';
import AppointmentEventData from '@/Interfaces/AppointmentEventData';
import EmployeeFormData from '@/Interfaces/EmployeeFormData';
import axios, { AxiosResponse } from 'axios';
import RoomFormData from '@/Interfaces/RoomFormData';
import { namespace } from 'vuex-class';
import LocationFormData from '@/Interfaces/LocationFormData';
import AppointmentFormData from '@/Interfaces/AppointmentFormData';
import dayjs, { Dayjs } from 'dayjs';
import User from '@/Interfaces/User';

@Component
export default class CalendarIndexParent extends Vue {
    protected setResponseFromParent?(response: AxiosResponse): void;

    public date = dayjs().utc().startOf('day');
    public startWeekDate = dayjs().utc().startOf('week');
    public endWeekDate = dayjs().utc().endOf('week');

    public weekTravel = 0 as number;
    public weekdays: Array<number> = [ 1,2,3,4,5,6,0 ];
    public formVisible = false as boolean;

    public calendarView: 'day'|'week'|'switch' = 'day';

    public switchViewDaysDates = [] as Array<any>;
    public switchViewDatePast: any = dayjs();
    public appointments = [] as Array<AppointmentEventData>;
    public filteredAppointments = [] as Array<AppointmentEventData>;
    public rooms = [] as Array<RoomFormData>;
    public activeRoom: number = 0;
    public users = [] as Array<EmployeeFormData>;
    public loading = false;
    public loadingCalendar = true;

    public selectedUser = null as User | null;

    protected endpoint?(): void;

    @namespace('mru').State('location') mruLocation!: any;
    @namespace('mru').State('user') mruUser!: any;
    @namespace('mru').State('date') mruDate!: any;
    @namespace('mru').State('calendarView') mruCalendarView!: any;
    @namespace('mru').Mutation('set') setMru!: any;
    @namespace('me').State permissions: any;
    @namespace('application').Action showNotification: any;

    @Ref('appointment-form') public readonly form!: AppointmentFormComponent;
    @Ref('appointment-raw') public readonly raw!: AppointmentRawComponent;

    private requestCancelToken: any;

    set location(value: LocationFormData | number | null) {
        this.setMru({ key: 'location', value: value });
        this.fetch();
        this.activeRoom = 1;
    }

    get location(): LocationFormData | number | null {
        return this.mruLocation;
    }

    set employee(value: User | number | null) {
        this.setMru({ key: 'user', value: value });
        this.fetch();
    }

    get employee(): User | number | null {
        return this.mruUser;
    }

    public setCalendarView(calenderView: 'day'|'week'|'switch'): void {
        this.calendarView = calenderView;
        this.setMru({ key: 'calendarView', value: this.calendarView });

        this.fetch();
    }

    public changeCalenderDate(date: Dayjs): void {
        this.date = date;
        this.setMru({ key: 'date', value: this.date });
    }

    public changeActiveRoom(amount: number) {
        const maxRooms = this.rooms.length;

        this.activeRoom = this.activeRoom + amount;

        if (this.activeRoom > maxRooms) {
            this.activeRoom = 1;
        } else if (this.activeRoom < 1) {
            this.activeRoom = maxRooms;
        }
    }

    public mounted(): void {
        if ( 'appointment_id' in this.$router.currentRoute.params ) {
            const id = parseInt(this.$router.currentRoute.params.appointment_id);
            this.fetchAppointment(id);
        }

        if ( 'client_id' in this.$router.currentRoute.params ) {
            const id = parseInt(this.$router.currentRoute.params.client_id);
            this.form.toggle({ client_id: id });
        }

        if ( typeof this.$router.currentRoute.query.locationId === 'string' ) {
            this.location = parseInt(this.$router.currentRoute.query.locationId);
        }

        if (this.mruLocation && !this.location) {
            this.location = this.mruLocation;
        }

        if (this.mruDate) {
            this.date = this.mruDate;
        }

        if (this.mruCalendarView && this.mruCalendarView !== 'switch') {
            this.calendarView = this.mruCalendarView;
        }

        if ( typeof this.$router.currentRoute.query.date === 'string' ) {
            this.date = dayjs(this.$router.currentRoute.query.date).startOf('day');
        }

        this.fetch();
    }

    private afterFetchAppointment(data: AppointmentFormData) {
        const path = this.$router.currentRoute.path;

        if ( path.includes('edit') ) {
            this.onEdit(data);
        }

        if ( path.includes('repeat') ) {
            this.onRepeat(data);
        }
    }

    private fetchAppointment(id: number) {
        this.loading = true;
        apiClient.get(`/appointments/${id}`).then((response) => {
            this.loading = false;
            this.location = response.data.location_id;

            this.date = dayjs.utc(response.data.datetime_from).startOf('day');

            setTimeout(() => {
                this.afterFetchAppointment(response.data);
            }, 500);
        }).finally(() => this.loading = false);
    }

    public onChange(amount: number): void {
        this.date = this.date.add(amount, 'day');
    }

    public onChangeWeek(amount: number): void {
        this.date = this.date.add(amount, 'week');

        this.startWeekDate = this.date.add(amount, 'week');
        this.endWeekDate = this.date.add(amount, 'week');
    }

    public onChangeSwitch(direction: 'forward'|'backward'): void {
        if (direction === 'forward') {
            this.date = this.switchViewDaysDates[1];
        }
        else if (direction === 'backward') {
            if (this.switchViewDatePast !== null) {
                this.date = this.switchViewDatePast;
            }
         }
    }

    public onCreate(response: any): void {
        if ( response.location_id !== this.location ) {
            this.location = response.location_id;
        }

        this.date = dayjs(response.datetime_from);
    }

    public onEdit(event: AppointmentFormData): void {
        const eventCopy = JSON.parse(JSON.stringify(event)) as AppointmentEventData;
        this.form.toggle(eventCopy);
    }

    public onNoShow(data: any): void {
        apiClient.patch(`event/${data.id}/no-show`)
            .then(this.onNoShowSuccess.bind(this))
            .finally(this.fetch.bind(this));
    }

    private onNoShowSuccess(): void {
        this.showNotification('AppointmentSuccessfullyRegisteredAsNoShow');
    }

    public onRaw(event: any): void {
        this.form.close();
        this.raw.toggle(event);
    }

    public onRepeat(event: any): void {
        const eventCopy = JSON.parse(JSON.stringify(event)) as AppointmentEventData;
        const modifiedCopy = Object.assign(eventCopy, {
            id: undefined,
            datetime: undefined,
            datetime_from: undefined,
            datetime_till: undefined,
        });

        // When appoint is a follow up appointment, the behandelaar needs to be locked CT-1078
        Vue.set(modifiedCopy, 'lock_user', true);

        this.form.toggle(modifiedCopy);
    }

    public onReset(): void {
        this.date = dayjs().startOf('day');
        this.startWeekDate = dayjs().startOf('week');
        this.endWeekDate = dayjs().endOf('week');
    }

    public onRequest(date: any): void {
        const datetime = date ? new Date([ date.date, [
            date.hour.toString().padStart(2, 0),
            (Math.round(date.minute / 15) * 15).toString().padStart(2, '0'),
            (0).toString().padStart(2, '0'),
        ].join(':') ].join('T')) : new Date();

        this.form.toggle({
            datetime: datetime.toISOString(),
            location_id: this.location,
        });
    }

    public getCalendarType(): string {
        const calendarType = this.$route.meta?.type;

        return calendarType == 'location' ? 'location' : 'user';
    }

    @Watch('calendarView')
    onCalendarViewChange(){
        this.appointments = [];
        this.filteredAppointments = [];

        this.filterByActiveRoom();
    }

    @Watch('date')
    fetch(): void {

        this.startWeekDate = this.date.startOf('week');
        this.endWeekDate = this.date.endOf('week');

        if (this.getCalendarType() === 'location' && !this.mruLocation) return;
        if (this.getCalendarType() === 'user' && !this.mruUser) return;

        try {
            this.requestCancelToken.cancel();
        } catch ( e ) {
            // Doe maar niks
        }
        this.requestCancelToken = axios.CancelToken.source();

        const endpoint = this.endpoint?.() || '';

        this.loadingCalendar = true;
        apiClient.get(endpoint, { cancelToken: this.requestCancelToken.token })
            .then(this.setResponse.bind(this)).then(() => this.loadingCalendar = false );

    }

    @Watch('activeRoom')
    public filterByActiveRoom() {
        this.filteredAppointments = this.appointments.filter((appointment: AppointmentEventData) => {
            return appointment.room_id == this.rooms[this.activeRoom-1]?.id;
        });
    }

    private setResponse(response: AxiosResponse): void {
        if (this.calendarView === 'switch') {
            this.switchViewDaysDates = response.data.switchCalendarDays.map((switchViewDay: any) => {
                return dayjs(switchViewDay.date);
            });

            if (response.data.previousDate !== null) {
                this.switchViewDatePast = dayjs(response.data.previousDate);
            }

            this.setResponseFromParent?.(response);
            return;
        }

        this.rooms = response.data.rooms;

        if (!this.rooms[this.activeRoom-1]) {
            this.activeRoom = 1;
        }

        this.users = response.data.users.map((user: any, index: number) => {
            return Object.assign(user, { color: colors[ index % colors.length ] });
        });

        Vue.set(this, 'appointments', response.data.appointments.map((appointment: any) => {
            return Object.assign(appointment, { user: this.users.find(i => i.id === appointment.user_id) });
        }));

        this.setResponseFromParent?.(response);

        this.filterByActiveRoom();
    }

    private updateAppointment(newAppointment: AppointmentEventData) {
        const appointment = this.appointments.find((appointment) => appointment.id = newAppointment.id);
        Object.assign(appointment, newAppointment);
    }

    private eventPaymentComplete(eventId: number) {
        const event = this.appointments.find((appointment: AppointmentEventData) => {
            return appointment.id === eventId;
        }) as AppointmentEventData;

        Vue.set(event, 'payment', true);
    }

    get calendarDate() {
        const newDate = new Date(this.date.toDate().getTime() + Math.abs(this.date.toDate().getTimezoneOffset() * 60000));

        return newDate.toISOString().substr(0, 10);
    }

    set calendarDate(date: string) {
        this.date = dayjs(date);
    }

    get weekNumber(): number {
        return dayjs().add(this.weekTravel, 'week').isoWeek();
    }

    get weekFormat(): Array<number> {
        return [1,2,3,4,5,6,0];
    }
}
