import AppointmentForm from '@/views/CalendarIndex/Forms/AppointmentForm.vue';
import AppointmentRaw from '@/views/AppointmentIndex/Forms/AppointmentForm.vue';
import Calendar from '@/views/CalendarIndex/Components/Calendar/Calendar.vue';
import ColorLegend from '@/views/CalendarIndex/Components/ColorLegend/ColorLegend.vue';
import Component from 'vue-class-component';
import LocationPicker from '@/components/LocationPicker/LocationPicker.vue';
import MenuButton from '@/components/MenuButton/MenuButton.vue';
import { Prop } from 'vue-property-decorator';
import LocationCalendarParent from '@/views/CalendarIndex/Components/LocationCalendarParent';
import CalendarPlanning from '@/views/CalendarIndex/Components/CalendarPlanning/CalendarPlanning.vue';
import dayjs from 'dayjs';

@Component({
    name: 'CalendarSwitchView',
    components: {
        AppointmentForm,
        AppointmentRaw,
        LocationPicker,
        Calendar,
        ColorLegend,
        MenuButton,
        CalendarPlanning,
    },
})

export default class CalendarSwitchView extends LocationCalendarParent {
    // Public variables
    @Prop({ default: () => [] }) public switchViewDays!: Array<any>;
    public weekdays = [] as Array<any>;

    public getCalendarType(): string {
        const calendarType = this.$route.meta?.type;

        return calendarType == 'location' ? 'location' : 'user';
    }

    public get overwriteStartTime(): Date {
        const x = [] as Array<any>;
        this.switchViewDays.forEach((day: any) => day.rooms.forEach((room: any) => x.push(room.schedule)));

        const result = x.reduce(function(acc, current) {
            const startDateTime = dayjs(current.actual_time_from).utc();

            if ( !acc ) return startDateTime;
            return startDateTime.isBefore(acc) ? startDateTime : acc;
        }, null).toDate();

        result.setMinutes(0);
        result.setSeconds(0);
        return result;

    }

    public get overwriteEndTime(): Date {
        const x = [] as Array<any>;
        this.switchViewDays.forEach((day: any) => day.rooms.forEach((room: any) => x.push(room.schedule)));

        const result = x.reduce(function(acc, current) {
            const endDateTime = dayjs(current.actual_time_till).utc();

            if ( !acc ) return endDateTime;
            return endDateTime.isAfter(acc) ? endDateTime : acc;
        }, null).toDate();

        result.setMinutes(0);
        result.setSeconds(0);
        return result;
    }

    public isDateToday(date: any): boolean {
        this.$dayjs.extend(require('dayjs/plugin/isToday'));

        return this.$dayjs(date).isToday();
    }

    public changeCalendarView(calendarView: 'day' | 'week' | 'switch'): void {
        this.$emit('setCalendarView', calendarView);

        this.$emit('fetch');
    }

    public onChangeSwitch(direction: 'forward' | 'backward'): void {
        this.$emit('change', direction);
    }

    public onRaw(event: any): void {
        this.$emit('raw', event);
    }

    public onRequest(date: any): void {
        this.$emit('request', date);
    }
}
