import Component from 'vue-class-component';
import Modal from '@/components/Modal/Modal.vue';
import PopupForm from '@/partials/PopupForm';
import AgreementSignTemplate from '@/components/AgreementSignTemplate/AgreementSignTemplate.vue';
import { namespace } from 'vuex-class';
import ClientFormData from '@/Interfaces/ClientFormData';
import Wysiwyg from '@/components/Wysiwyg/Wysiwyg.vue';
import ClientForm from '@/views/ClientIndex/Forms/ClientForm.vue';
import apiClient from '@/apiClient';
import dayjs from 'dayjs';
import Gender from '@/Interfaces/Gender';
import { getName as getTranslatedCountryName } from 'i18n-iso-countries';
import { Watch } from 'vue-property-decorator';
import SupportedCountry from '@/Interfaces/SupportedCountry';
import DateField from '@/components/DateField/DateField.vue';
import { default as DateFieldComponent } from '@/components/DateField/DateField.ts';
import SignatureField from '@/components/SignatureField/SignatureField.vue';
import { default as SignatureComponent } from '@/components/SignatureField/SignatureField.ts';
import Popup from '@/components/popup/popup';
import ClientAgreementFormData from '@/Interfaces/ClientAgreementFormData';
import Vue from 'vue';
import { default as AgreementSignTemplateComponent } from '@/components/AgreementSignTemplate/AgreementSignTemplate.ts';

@Component({
    name: "SignAgreementModal",
    components: {
        SignatureField,
        AgreementSignTemplate,
        Modal,
        Wysiwyg,
        ClientForm,
        DateField
    }
})
export default class SignAgreementModal extends PopupForm<ClientAgreementFormData> {
    @namespace('country').Getter getCountryById: any;
    @namespace('mru').State('selectedAgreement') mruSelectedAgreement!: any;
    @namespace('mru').State('client') mruClient!: ClientFormData;
    @namespace('mru').Mutation('set') setMru!: any;
    @namespace('me').State('name') practitionerName!: any;
    @namespace('me').State('signature') userSignature!: any;
    @namespace('me').Mutation('set') setMe!: any;

    protected endpoint: string = 'client-agreements';
    protected modelName: string = 'ClientAgreement';
    public formData: ClientAgreementFormData = {} as any;
    private genders = [] as Array<Gender>;
    private loadingClientDetails: boolean = false;
    private clientDetails = {};
    private supportedCountryDetails = {} as SupportedCountry;
    private nextStepEnabled: boolean = false;
    private setToStep = 0;
    private clientSigneeName: string = "";
    private practitionerSigneeName: string = "";
    public clientSignDate: dayjs.Dayjs|null = dayjs().utc().startOf('day');
    public practitionerSignDate: dayjs.Dayjs|null = dayjs().utc().startOf('day');
    private signatures: { client: number | null; user: number | null } = {
        client: null,
        user: null
    }

    $refs!: {
        popup: Popup;
        client_form: HTMLFormElement;
        form: HTMLFormElement;
        error: HTMLElement;
        signaturefield_client: SignatureComponent;
        signaturefield_user: SignatureComponent;
        signStepOneComponent: AgreementSignTemplateComponent;
        dateFieldClient: DateFieldComponent;
        dateFieldPractitioner: DateFieldComponent;
    };

    mounted() {
        this.fetchGenders();
        this.fetchSupportedCountryInformation();

        if (this.userSignature !== null) {
            this.signatures.user = this.userSignature;
        }

        this.clientSigneeName = this.mruClient.name;
        this.practitionerSigneeName = this.practitionerName;

        this.loadingClientDetails = true;
    }

    private setFormData(key: string, value: any): void {
        Vue.set(this.formData, key, value);
    }

    @Watch('mruSelectedAgreement', {immediate: false})
    private fetchSupportedCountryInformation(): void {
        if (this.mruSelectedAgreement !== null) {
            apiClient.get(`template-agreements/supported-country/${this.mruSelectedAgreement}`).then(data => {
                this.supportedCountryDetails = data.data;
            });
        }
    }

    private createdSignature(response: any) {
        if (response.type === 'client') {
            this.signatures.client = response.id
        }
        else if (response.type === 'user') {
            this.signatures.user = response.id;
            this.setMe({ signature: response.id });
        }
    }

    private get isClientDetailsFilledIn(): boolean|unknown {
        if (this.clientDetails === null) return false;
        return !this.matchNULLValue(this.clientDetails);
    }

    private get templateContent(): string | undefined {
        return this.formData.content;
    }

    @Watch('formData', {deep: true})
    public onFormDataChanged() {
        Vue.set(this, 'isDirty', Object.keys(this.formData).length > 0 && this.formData.content !== undefined);
    }

    private get clientStreetNumberAndSuffix(): string | undefined | number {
        if (this.mruClient.street_suffix !== null) {
            return this.mruClient?.street_number + ' ' + this.mruClient?.street_suffix;
        }
        return this.mruClient?.street_number;
    }

    private matchNULLValue(object: any) {
        for (const value of Object.values(object)) {
            if (value === null || (typeof value === "object" && this.matchNULLValue(value))) {
                return true;
            }
        }
        return false;
    }

    private fetchGenders() {
        apiClient.get('genders').then(response => {
            this.genders = response.data;

            this.refreshClientDetails();
        });
    }

    private get clientBirthday():  null | string {
        return this.mruClient?.birthdate ? dayjs(this.mruClient?.birthdate).format('DD-MM-YYYY').toString() : null;
    }

    private get clientCountry(): null | string {
        return getTranslatedCountryName(this.getCountryById(this.mruClient?.country_id).code, this.$i18n.locale);
    }

    private refreshClientDetails(): void {
        this.clientDetails = {
            'general': {
                'FirstName': this.mruClient?.first_name,
                'LastName': this.mruClient?.last_name,
                'Email': this.mruClient?.email,
                'PhoneNumber': this.mruClient?.phone_number,
                'Birthdate': this.clientBirthday
            },
            'address': {
                'Country': this.clientCountry,
                'Street': this.mruClient?.street,
                'StreetNumberAndSuffix': this.clientStreetNumberAndSuffix,
                'Zipcode': this.mruClient?.postcode,
                'Residence': this.mruClient?.place
            }
        }

        this.formData.client_id = this.mruClient?.id ?? undefined;
        this.loadingClientDetails = false;
    }

    private onSave(close: boolean): void {
        Vue.set(this, 'isDirty', false);

        this.formData.practitioner_signee_name = this.practitionerSigneeName;
        this.formData.practitioner_sign_date = this.practitionerSignDate;
        this.formData.client_signee_name = this.clientSigneeName;
        this.formData.client_sign_date = this.clientSignDate;
        this.formData.client_id = this.mruClient?.id ?? undefined;
        this.formData.client_signature_id = this.signatures.client;
        this.formData.user_signature_id = this.signatures.user;
        this.formData.template_agreement_id = this.$refs.signStepOneComponent.agreement_template_id;
        this.formData.treatment_category_id = this.$refs.signStepOneComponent.treatment_category_id;

        this.submit(close);
    }

    private onClose(): void {
        Vue.set(this, 'formData', {});
        Vue.set(this, 'nextStepEnabled', false);

        this.signatures.client = null;
        this.clientSigneeName = this.mruClient.name;
        this.practitionerSigneeName = this.practitionerName;

        this.$refs.dateFieldClient.input = new Date().toISOString().substr(0, 10);
        this.$refs.dateFieldPractitioner.input = new Date().toISOString().substr(0, 10);

        this.$refs.signStepOneComponent.clear();
        this.$refs.signaturefield_client.clear();

        this.setModalToStep(1);
    }

    private editAgreement(): void {
        this.setModalToStep(1);
    }

    private setModalToStep(step: number) {
        this.setToStep = step;

        setTimeout(() => {
            this.setToStep = 0;
        }, 500);

    }

    private onEdit(client: ClientFormData): void {
        this.loadingClientDetails = true;

        this.setMru({ key: 'client', value: client });

        this.refreshClientDetails();

        this.loadingClientDetails = false;
    }

    private get canSaveAgreement(): boolean | undefined | unknown {
        return this.isClientDetailsFilledIn && this.signatures.client != null
            && this.signatures.user != null;
    }

    private editClient(): void {
        document.body.classList.add('waiting');
        apiClient.get(`clients/${this.mruClient?.id}`).then((response) => {
            this.$refs.client_form.toggle(response.data);

            this.$refs.client_form.isDirty = false;
        }).finally(() => {
            document.body.classList.remove('waiting');
        });
    }
}
