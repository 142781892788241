import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop, Watch } from 'vue-property-decorator';
import apiClient from '@/apiClient';
import i18n from '@/i18n';
import FormRules from '@/partials/FormRules';

interface TaxRateInterface {
    id: number;
    rate: number;
}

@Component({
    name: 'VatSelect',
})

export default class VatSelect extends Vue {
    @Prop({ default: 0 }) supportedCountryId!: number;
    @Prop({}) setValue!: any;
    public formRules = FormRules;
    private taxRatesLoading: boolean = true;
    public vatOptions = [];
    public value: any = null;

    mounted() {
        this.getTaxRates();

        if (this.setValue !== undefined) {
            this.value = this.setValue;
        }
    }

    @Watch('value')
    onValueChange() {
        this.$emit('input', this.value);
    }

    private getVatTranslatableName(item: any): string {
        const index = item.lastIndexOf('.');

        let string = item;

        if (index != -1) {
            string = item.substring(0, index) + "_" + item.substring(index + 1)
        }

        return string;
    }

    private getTaxRates() {
        this.taxRatesLoading = true;

        apiClient.get('tax-rates/country/' + this.supportedCountryId).then((response) => {
            this.vatOptions = response.data.tax_rates.map((taxRate: TaxRateInterface) => {

                const textString: string = `Global.Fields.Vat.${taxRate.rate}`;

                return {
                    'id': taxRate.id,
                    'percentage': taxRate.rate,
                    'text': i18n.t(this.getVatTranslatableName(textString))
                }
            })
        }).finally(() => {
            this.taxRatesLoading = false;
        })
    }
}
