import Component from 'vue-class-component';
import Avatar from '@/components/Avatar/Avatar.vue';
import Schedule from '@/components/Schedule/Schedule.vue';
import { default as ScheduleRef } from '@/components/Schedule/Schedule';
import { Prop, Ref } from 'vue-property-decorator';
import User from '@/Interfaces/User';
import EmployeeScheduleForm from '@/views/ScheduleIndex/Employee/Forms/EmployeeScheduleForm.vue';
import { default as formComponent } from '@/views/ScheduleIndex/Employee/Forms/EmployeeScheduleForm';
import IndexView from '@/partials/IndexView';
import Location from '@/Interfaces/Location';
import Model from '@/Interfaces/Model';

declare interface FormData extends Model {
    id?: number;
    model_id: number;
    location_id: number;
    date_from: string;
    time_from: string;
    time_till: string;
    type?: string;
}

declare interface InputInterface {
    record: User;
    date: string;
}

@Component({
    name: 'EmployeeSchedule',
    components: {
        Avatar,
        Schedule,
        EmployeeScheduleForm,
    },
})
export default class EmployeeSchedule extends IndexView<FormData> {
    @Prop() locationModel!: Location;
    @Prop(Array) readonly weekdays!: Array<number>;
    @Prop(Number) readonly weekNumber!: number;

    @Ref('schedule') readonly schedule!: ScheduleRef;
    @Ref('form') readonly form!: formComponent;

    public scheduleTranslationKey = 'Employee';

    openForm({ record, date }: InputInterface) {
        this.form.toggle();


        this.form.formData = {
            model_id: record.id,
            location_id: this.locationModel?.id,
            date_from: date,
            weekdays: [],
            active: true,
            time_from: new Date(),
            time_till: new Date(),
            interval: 0,
        };


        const intermediate = this.form;

        intermediate.user = record as User;
        intermediate.location = this.locationModel;
        intermediate.initialDate = date || null;
        intermediate.multiple = !!record.schedules.find((i: any) => i.location_id !== this.locationModel.id);
    }

    openEditForm(payload: { record: any; data: any; type: string }) {
        this.form.toggle();

        const weekdays = payload.type === 'series' ? payload.data.recurrence.weekdays : [ payload.data.weekday ];

        this.form.formData = {
            active: payload.data.active,
            model_id: payload.record.id,
            location_id: this.locationModel?.id,
            date_from: payload.data.date,
            interval: payload.type === 'occurrence' ? 0 : payload.data.interval,
            weekdays,
            time_from: new Date(payload.data.time_from),
            time_till: new Date(payload.data.time_till),
            type: payload.type,
            id: payload.data.timetable_id,
            recurrence_id: payload.data.recurrence_id,
        };

        this.form.isSeries = payload.data.interval > 0;

        const intermediate = this.$refs.form as unknown as formComponent;
        intermediate.user = payload.record as User;
        intermediate.location = this.locationModel;
        intermediate.initialDate = payload.data.data || null;
    }

    refreshData() {
        this.schedule.fetchNewScheduleData();
    }
}
