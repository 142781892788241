import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Prop } from 'vue-property-decorator';

@Component
export default class Avatar extends Vue {
    @namespace('me').State name: any;

    @Prop({ default: 32 }) width!: boolean;
    @Prop({ default: 32 }) height!: boolean;
    @Prop({ default: null }) userName!: null | string;
    @Prop({ default: 'opaque' }) backgroundColor!: string;
    @Prop({ default: null }) color!: null | string;

    get nameAbbreviation() {
        let name = this.userName ? this.userName : this.name as string;
        const parts = name.split(' ');
        name = [ parts[ 0 ], parts.splice(-1)[ 0 ] ].join(' ');
        return name.split(' ').map(x => x[ 0 ]).join('').toUpperCase();
    }

    get style(): Record<string, string> {
        return {
            width: `${this.width}px`,
            height: `${this.height}px`,
            border: `1px solid ${this.color ? '#ffffff' : this.$vuetify.theme.themes.light.primary}`,
            color: `${this.color || this.$vuetify.theme.themes.light.primary}`,
            'background-color': this.backgroundColor,
        };
    }
}
