import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import StatusIcon from '@/components/StatusIcon/StatusIcon.vue';
import AppointmentEventData from '@/Interfaces/AppointmentEventData';
import { TranslateResult } from 'vue-i18n';
import dayjs from 'dayjs';
import TreatmentReportPopup from '@/components/TreatmentReport/Components/Popup/TreatmentReportPopup';
import Popup from '@/components/popup/popup';
import PaymentScreen from '@/components/PaymentScreen/PaymentScreen';
import { namespace } from 'vuex-class';
import EventStatusIcon from '@/components/EventStatusIcon/EventStatusIcon.vue';

@Component({ components: { StatusIcon, EventStatusIcon } })
export default class AppointmentEvent extends Vue {
    @Prop() appointment!: AppointmentEventData;
    @Prop() clickOutside!: boolean;
    @Prop() treatmentReportPopup!: TreatmentReportPopup;
    @Prop() paymentScreenPopup!: Popup;
    @Prop() paymentScreen!: PaymentScreen;

    @namespace('me').State permissions: any;

    public visible: boolean = false;

    public get createdAt(): any {
        return dayjs(this.appointment.created_at);
    }

    public get dashboardUrl(): string {
        return [ '/clients', this.appointment.client_id ].join('/');
    }

    public get colorBackground(): string {
        return this.appointment.user?.color.background || '#2b2f39';
    }

    public get colorText(): string {
        return this.appointment.user?.color.text || '#ffffff';
    }

    public get date(): string {
        return this.from.format('dd DD-MM-YYYY');
    }

    public get duration(): TranslateResult {
        const duration = dayjs.duration(this.till.diff(this.from));

        const hours = duration.format('H');
        const minutes = duration.format('mm');

        if ( hours !== '0' && minutes !== '0' ) {
            return this.$i18n.t('Global.Fields.HoursAndMinutes', { hours, minutes });
        } else if ( hours !== '0' && minutes === '0' ) {
            return this.$i18n.t('Global.Fields.Hours', { hours });
        } else {
            return this.$i18n.t('Global.Fields.Minutes', { minutes });
        }
    }

    public get from(): any {
        return dayjs(this.appointment.datetime_from).locale(this.$i18n.locale);
    }

    public get paymentUrl(): string {
        return [ 'payment', this.appointment.client_id ].join('/'); // TODO: Replace with actual factual payment URL.
    }

    public get timebox(): string {
        return [ this.from.format('H:mm'), this.till.format('H:mm') ].join(' - ');
    }

    public get till(): any {
        return dayjs(this.appointment.datetime_till);
    }

    public getTypeNameTranslation(type: string): TranslateResult{
        return this.$t(type === "Laser" ? "Api.DeviceTypes.laser" : "Api.DeviceTypes.attachment");
    }

    public onCancel(): void {
        this.$emit('cancel', this.appointment);
    }

    public onClick(): void {
        this.visible = true;
    }

    public onEdit(): void {
        this.$emit('edit', this.appointment);
    }

    public onRepeat(): void {
        this.$emit('repeat', this.appointment);
    }

    public onNoShow(): void {
        this.$emit('no-show', this.appointment);
    }

    private openTreatmentReport() {
        this.treatmentReportPopup.toggle({
            id: this.appointment.id,
        });
    }

    private openPaymentPopup() {
        if ( this.appointment.payment ) {
            this.$router.push(`/clients/${this.appointment.client_id}/2`);
        } else {
            this.$emit('openPayment', this.appointment.id);
        }
    }

    get dataMappingToStatus(): Record<string, any>{
        return {
            is_paid: this.appointment.payment,
            treatment_report_status: this.appointment.report,
            no_show_at: this.appointment.no_show,
            deleted_at: null,
            has_contract: this.appointment.contract,
            client_id: this.appointment.client_id,
        }
    }
}
