import Component from 'vue-class-component';
import Autocomplete from '@/components/Autocompletes/Autocomplete';
import SupportedCountry from '@/Interfaces/SupportedCountry';
import { getName as getTranslatedCountryName } from 'i18n-iso-countries';
import Vue from 'vue';
import { Watch } from 'vue-property-decorator';

@Component({
    name: 'SupportedCountriesAutocomplete',
})
export default class SupportedCountries extends Autocomplete<SupportedCountry> {
    public loading = false;
    public endpoint = 'list/supported-countries';
    public getTranslatedCountryName = getTranslatedCountryName;
    public preSelect: number | null = this.value || null;

    private getCountryName(input: SupportedCountry): string {
        const currentLocale = this.$i18n.locale;

        return this.getTranslatedCountryName(input.country.code, currentLocale);
    }

    @Watch('selected')
    onSelectedChange() {
        this.$store.commit('mru/set', { key: 'primarySupportedCountryId', value: this.selected });
        this.$emit('input', this.selected);
    }

    mounted() {
        this.selected = this.preSelect || (this.$store.state.mru.primarySupportedCountryId || null);

        if ( this.$store.state.mru.supportedCountries.length > 0 ) {
            Vue.set(this, 'data', this.$store.state.mru.supportedCountries);

            this.afterFetch(this.data);
        } else {
            this.fetch();
        }
    }

    protected afterFetch(data: any) {
        this.data = data;
        this.$emit('after-fetch', this.data);

        if ( !this.selected ) this.selected = data[ 0 ].id;

        this.$store.commit('mru/set', { key: 'supportedCountries', value: data });
    }

}
