import Component from 'vue-class-component';
import TreatmentForm from '@/views/TreatmentIndex/Forms/TreatmentForm.vue';
import Vue from 'vue';
import apiClient from '@/apiClient';
import VuetifyAutoCompleteGroupItem from '@/Interfaces/VuetifyAutoCompleteGroupItem';
import VuetifyAutoCompleteGroup from '@/Interfaces/VuetifyAutoCompleteGroup';
import { Prop, Watch } from 'vue-property-decorator';

declare interface VuetifyAutoCompleteTreatmentGroupItem extends VuetifyAutoCompleteGroupItem {
    duration: number;
}

@Component({
    components: { TreatmentForm },
})
export default class TreatmentPicker extends Vue {
    private treatments: Record<string, any> = [];
    @Prop({ default: false }) dependsOnCountry!: boolean;
    @Prop({ default: null }) forCountry!: number | null;
    @Prop({default: true}) useCategories!: boolean;

    public loading: boolean = false;

    public mounted(): void {
        if ( !this.dependsOnCountry ) {
            this.fetch();
        }

        if ( this.dependsOnCountry && this.forCountry ) {
            this.fetch();
        }
    }

    @Watch('forCountry')
    onForCountryChange() {
        this.fetch();
    }

    public get options(): VuetifyAutoCompleteGroup {
        const response: VuetifyAutoCompleteGroup = [];

        if (this.treatments.length === 0) return [];

        if (this.useCategories) {
            Object.entries(this.treatments).forEach(([ key, value ]) => {
                response.push({ divider: true });
                response.push({ header: key });
                response.push({ divider: true });

                value.forEach((treatment: any) => {
                    response.push(Object.assign({ group: key }, treatment));
                });
            });
        }
        else {
            Object.entries(this.treatments).forEach(([, value ]) => {
                response.push(value);
            });
        }

        return response.sort((a: any, b: any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
    }

    private fetch(): void {
        let endpoint = this.dependsOnCountry ? `/event/treatments/country/${this.forCountry}` : '/event/treatments';
        if (this.useCategories) {
            endpoint = this.dependsOnCountry ? `/event/treatments/country/${this.forCountry}/categories` : '/event/treatments/categories';
        }

        apiClient.get(endpoint)
            .then(response => {
                this.$emit('treatmentsLoaded', response.data);
                this.treatments = response.data;
            })
            .finally(() => this.loading = false);

        this.loading = true;
    }
}
