import Component from 'vue-class-component';
import ClientForm from '@/views/ClientIndex/Forms/ClientForm.vue';
import ClientFormData from '@/Interfaces/ClientFormData';
import presenters from '@/components/OverviewTable/presenters';
import apiClient from '@/apiClient';
import Vue from 'vue';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import { findEachWordInOneOf } from '@/utils/utils';
import { namespace } from 'vuex-class';

@Component({
    components: {
        ClientForm,
    },
})
export default class ClientPicker extends Vue {
    @Prop({ default: '' }) buttonClass!: string;
    @Prop({ default: true }) showAddButton!: boolean;
    @Prop({ default: 'list/clients' }) endpoint !: string;
    @Prop({ default: '' }) defaultQuery!: string;
    @Prop({default: false}) readonly mruFill!: boolean;
    @namespace('mru').State('client') mruClient!: any;

    @Ref() public autocomplete: any;

    public clients: Array<ClientFormData> = [];
    public findEachWordInOneOf = findEachWordInOneOf;
    public loading: boolean = false;
    public query: string = this.defaultQuery;

    private debounceTimer!: NodeJS.Timeout;

    public mounted(): void {
        this.fetch();
        if (this.mruClient && this.mruFill) {
            this.clients.unshift(this.mruClient);
            this.$emit('input', this.mruClient);
        }

    }

    @Watch('query')
    private onQueryChange(): void {
        this.fetch();
    }

    public getSubtitle(client: any): string {
        return [ 'birthdate', 'phone_number', 'email' ]
            .map((attribute) => presenters.get(attribute, client[ attribute ], null))
            .filter(v => v)
            .join(' • ');
    }

    public onCreate(client: ClientFormData): void {
        this.$emit('input', client);
    }

    private fetch(): void {
        clearTimeout(this.debounceTimer);

        this.debounceTimer = setTimeout(() => {
            apiClient.get(`${this.endpoint}?filters[search]=${this.query || ''}&include=${this.autocomplete.internalValue || null}`)
                .then(response => {
                    this.clients = response.data.body;
                    this.clients.push(this.mruClient);
                    this.$emit('loaded', this.clients);
                })
                .finally(() => this.loading = false);

            this.loading = true;
        }, 500);
    }
}
