import ClientFormData from '@/Interfaces/ClientFormData';
import Component from 'vue-class-component';
import {namespace} from 'vuex-class';
import {Prop, Watch} from 'vue-property-decorator';
import PaymentItems from '@/components/PaymentScreen/components/PaymentItems';
import PaymentItemInterface from '@/components/PaymentScreen/interfaces/PaymentItemInterface';
import {default as PaymentMethodInterface} from '@/Interfaces/PaymentMethod';
import PriceField from '@/components/PriceField/PriceField.vue';
import presenters from '@/components/OverviewTable/presenters';
import {StrRandom} from '@/utils/utils';
import Vue from 'vue';
import MenuButton from '@/components/MenuButton/MenuButton.vue';
import PaymentOverpayModal from '@/components/PaymentScreen/components/PaymentOverpayModal/PaymentOverpayModal.vue';
import Popup from '@/components/popup/popup';

@Component({
    name: 'PaymentMethod',
    components: {
        PriceField,
        PaymentOverpayModal,
        MenuButton,
    },
})
export default class PaymentMethod extends PaymentItems {
    @namespace('paymentmethods').State data!: Array<PaymentMethodInterface>;
    @namespace('paymentmethods').Getter getPaymentMethodBySlug!: CallableFunction;
    @namespace('paymentmethods').Getter getPaymentMethodsForItems!: CallableFunction;

    @Prop() private value!: Array<PaymentItemInterface>;
    @Prop({default: false}) loading!: boolean;
    @Prop({default: false}) showCancelButton!: boolean;
    @Prop({default: () => []}) validationErrors!: Array<string>;
    @Prop({default: false}) paymentWithTermDisabled!: boolean;
    @Prop() locationId!: number;
    @Prop() private practitionerId!: number;
    @Prop() private client!: ClientFormData;

    private paymentMethods = [] as Array<{ method: number; amount: number; vue_id: string; active: boolean }>;

    private payMethod!: 'with-notification' | 'no-notification' | undefined;

    public getPaymentMethod() {
        return this.payMethod;
    }

    public get currentPaymentMethods() {
        return this.paymentMethods;
    }

    $refs !: {
        modal: Popup;
    }

    @Watch('value')
    onValueChange() {
        Vue.set(this, 'paymentMethods', this.value);
        if (this.paymentMethods.length === 0) this.addPaymentMethod();
    }
    mounted() {
        this.addPaymentMethod();
        this.syncTotalAmountToFirstMethod();
    }

    @Prop() protected items!: Array<PaymentItemInterface>;

    @Watch('paymentMethods')
    onPaymentMethodsChange() {
        this.$emit('input', this.paymentMethods);
    }

    @Watch('items', {deep: true})
    onItemsChange() {
        this.syncTotalAmountToFirstMethod();
    }

    private clickPayButton(payMethod: 'no-notification' | 'with-notification') {
        this.payMethod = payMethod;

        return !this.totalHigherThenRequired ? this.pay(payMethod) : this.$refs.modal.toggle();
    }

    private syncTotalAmountToFirstMethod(): void {
        if (this.paymentMethods.length === 1 && this.paymentMethods[0].method) {
            let value = this.totalAmount;
            if (this.paymentMethods[0].method === this.getPaymentMethodBySlug('prepaid').id) value = Math.min(this.totalAmount, this.client.credit_balance as number);
            else if (this.paymentMethods[0].amount != 0) value = this.paymentMethods[0].amount;

            this.paymentMethods[0].amount = value;
        }
    }

    selectedPaymentMethod(paymentMethod: { method: number; amount: number; vue_id: string; active: boolean }): void {
        paymentMethod.amount = paymentMethod.method != this.getPaymentMethodBySlug('prepaid').id ? this.amountLeftValue : 0;

        paymentMethod.active = true;
    }

    get availablePaymentMethods(): Array<PaymentMethodInterface> {
        const items = Array.from(new Set(this.items?.map(item => item.item).filter(x => !!x))).filter(item => item);

        return this.getPaymentMethodsForItems(items);
    }

    filterSelectedPaymentMethods(selectedPaymentMethod: PaymentMethodInterface): boolean {
        return !!this.paymentMethods.find(paymentMethod => paymentMethod.method === selectedPaymentMethod.id && paymentMethod.active);

    }

    get availablePaymentMethodsFiltered(): Array<PaymentMethodInterface> {
        return this.availablePaymentMethods.filter((item: any) => {
            return !(item.slug === 'credit' && this.paymentWithTermDisabled);

        });
    }

    public get paymentMethodSelected(): boolean {
        return !this.paymentMethods.some((method) => method.method === 0);
    }

    public get totalCompletelyFilled(): boolean {
        return this.totalPaymentFilledIn >= this.totalAmount;
    }

    public get exactPaymentFilledIn(): boolean {
        return this.totalPaymentFilledIn === this.totalAmount;
    }

    public get totalHigherThenRequired(): boolean {
        return this.totalPaymentFilledIn > this.totalAmount;
    }

    get amountLeft(): string {
        return presenters.get('currency', this.totalAmount - this.totalPaymentFilledIn);
    }

    get overpaidAmount(): string {
        return presenters.get('currency', this.totalPaymentFilledIn - this.totalAmount);
    }

    get paidValue(): number {
        return this.totalPaymentFilledIn - this.totalAmount;
    }

    get amountLeftValue(): number {
        return this.totalAmount - this.totalPaymentFilledIn;
    }

    get totalPaymentFilledIn(): number {
        return this.paymentMethods.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.amount;
        }, 0);
    }

    translatePaymentMethod(item: PaymentMethodInterface): string {
        return item.name;
    }

    addPaymentMethod() {
        this.paymentMethods.push({
            method: 0,
            amount: 0,
            vue_id: StrRandom(),
            active: false,
        });
    }

    removePaymentMethod(paymentMethod: PaymentMethodInterface) {
        const index = this.paymentMethods.findIndex((item) => item.vue_id === paymentMethod.vue_id);
        this.paymentMethods.splice(index, 1);

        this.syncTotalAmountToFirstMethod();
    }

    private pay(type: string) {
        this.$emit('pay', type);
    }

    private cancel() {
        this.$emit('cancel');
    }

    private paymentMethodIdToSlug(id: number): string {
        return this.data.find((item) => item.id === id)?.slug || "";
    }

    get paymentMethodsIdsToSlugs(): Array<string> {
        return this.paymentMethods.map((item) => this.paymentMethodIdToSlug(item.method));
    }

    get paymentMethodCanExceedMaximum(): boolean {
        const blacklisted = ['credit', 'prepaid'];
        let returnValue = true;

        blacklisted.forEach((slug) => {
            if (this.paymentMethodsIdsToSlugs.includes(slug)) {
                returnValue = false;
            }
        });

        return returnValue;
    }
}
