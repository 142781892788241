import i18n from '@/i18n';
import { Commit } from 'vuex';
import Notification from '@/Interfaces/Notification';

const state = {
    notifications: [],
};

const mutations = {
    addNotification(state: { notifications: Array<Notification> }, notification: Notification) {
        state.notifications.push(notification);
    },

    removeNotification(state: { notifications: Array<Notification> }, idToRemove: string) {
        state.notifications = state.notifications.filter((notification) => {
            return notification.id != idToRemove;
        });
    },
};

const actions = {
    showNotification: (context: { commit: Commit }, translation: string | Record<any, any>) => {
        let text = '';
        if ( typeof translation === 'object' ) {
            text = i18n.t(`Global.Notifications.${translation[ 0 ]}`, translation[ 1 ]).toString();
        } else {
            text = i18n.t(`Global.Notifications.${translation}`).toString();
        }

        const notification = {
            id: Math.random().toString(36),
            text,
        };

        context.commit('addNotification', notification);
        setTimeout(() => {
            context.commit('removeNotification', notification);
        }, 4000);
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
