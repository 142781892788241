import Component from 'vue-class-component';
import OverviewTable from '@/components/OverviewTable/OverviewTable.vue';
import { default as OverviewTableComponent } from '@/components/OverviewTable/OverviewTable';
import { namespace } from 'vuex-class';
import IndexView from '@/partials/IndexView';
import { Ref, Watch } from 'vue-property-decorator';
import StatusIcon from '@/components/StatusIcon/StatusIcon.vue';
import Popup from '@/components/popup/popup.vue';
import { default as PopupComponent } from '@/components/popup/popup';
import TreatmentReportPopup from '@/components/TreatmentReport/Components/Popup/TreatmentReportPopup.vue';
import { default as TreatmentReportPopupComponent } from '@/components/TreatmentReport/Components/Popup/TreatmentReportPopup';
import Model from '@/Interfaces/Model';
import { AxiosResponse } from 'axios';
import MenuButton from '@/components/MenuButton/MenuButton.vue';
import PaymentScreen from '@/components/PaymentScreen/PaymentScreen.vue';
import AppointmentEventData from '@/Interfaces/AppointmentEventData';
import EventStatusIcon from '@/components/EventStatusIcon/EventStatusIcon.vue';

@Component({
    name: 'ClientAppointmentIndex',
    components: {
        OverviewTable,
        StatusIcon,
        Popup,
        TreatmentReportPopup,
        MenuButton,
        PaymentScreen,
        EventStatusIcon,
    },
})
export default class ClientAppointmentIndex extends IndexView<AppointmentEventData> {
    @namespace('mru').State('client') mruClient!: any;
    @namespace('me').State permissions: any;

    @Ref() treatmentReportPopup!: TreatmentReportPopupComponent;
    @Ref() paymentPopup!: PopupComponent;
    @Ref() overviewTable!: OverviewTableComponent;

    get endpoint() {
        return `clients/${this.mruClient.id}/appointments?sort=datetime_from&direction=desc`;
    }

    @Watch('mruClient')
    onClientChange() {
        this.$refs.overviewTable.refresh();
    }

    /**
     * Custom table sort method to properly sort date / times
     *
     * @param items
     * @param index
     * @param isDesc
     */
    customTableSort(items: any, index: Array<string>, isDesc: Array<boolean>) {
        return items.sort((a: any, b: any) => {
                switch ( index[ 0 ] ) {
                    case 'datetime_from':
                        return isDesc[ 0 ] ? (this.findDate(a.id) - this.findDate(b.id)) : (this.findDate(b.id) - this.findDate(a.id));
                    case 'time_range':
                        if ( isDesc[ 0 ] ) {
                            return this.timeRangeToInt(a.time_range) < this.timeRangeToInt(b.time_range) ? -1 : 1;
                        } else {
                            return this.timeRangeToInt(b.time_range) < this.timeRangeToInt(a.time_range) ? -1 : 1;
                        }
                    default:
                        if ( isDesc[ 0 ] ) {
                            return a[ index[ 0 ] ] < b[ index[ 0 ] ] ? -1 : 1;
                        } else {
                            return b[ index[ 0 ] ] < a[ index[ 0 ] ] ? -1 : 1;
                        }
                }
            },
        );
    }

    /**
     * Finds date for record
     *
     * @param id
     * @private
     */
    private findDate(id: number): number {
        const record = this.$refs.overviewTable.filteredData.find((record) => record.id === id) as any;

        return +(new Date(record.datetime_from));
    }

    private timeRangeToInt(range: string): number {
        return parseInt(range.substr(0, 5).replace(':', ''));
    }

    openTreatmentReport(item: Model) {
        this.treatmentReportPopup.toggle({
            id: item.id,
        });
    }

    savedTreatmentReport(response: AxiosResponse<any>) {
        const item = this.overviewTable.unfilteredData.find((item: Model) => item.id === response.data.appointment.id);

        Object.assign(item, {
            treatment_report_status: true,
        });
        this.$emit('reload');
    }

    getApplicableStrikeTrough(item: any): string {
        return item.no_show_at || item.deleted_at ? 'cancelled' : '';
    }

    private pay(item: Model) {
        if ( item.is_paid ) {
            this.$emit('setTab', 2);
        } else {
            this.paymentPopup.toggle(item.id);
        }
    }

    private paymentMade(id: number) {
        const item = this.overviewTable.unfilteredData.find((item: Model) => item.id === id);

        Object.assign(item, {
            is_paid: true,
        });
        this.closePaymentPopup();
    }

    private closePaymentPopup() {
        this.paymentPopup.close();
        this.$emit('reload');
    }

    private repeatAppointment(item: Model) {
        this.$router.push(`/calendar/repeat/${item.id}`);
    }

    private editAppointment({ id }: AppointmentEventData) {
        this.$router.push(`/calendar/edit/${id}`);
    }
}
